// Copyright (C) 2022 by Posit Software, PBC.

import { apiV1Path } from '@/utils/paths';
import ContentTypes from '@/views/content/contentList/contentType';
import axios from 'axios';
import { App } from './dto/app';
import AppRoles from './dto/appRole';
import { keysToCamel, keysToSnake } from './transform';

export const Filters = {
  minVisibilityAppRole(role) {
    return `filter=min_role:${encodeURIComponent(role)}`;
  },
  deployedContent() {
    return `filter=deployed:1`;
  },
  undeployedContent() {
    return `filter=deployed:0`;
  },
  ownedByUser(userGuid) {
    return `filter=account_guid:${encodeURIComponent(userGuid)}`;
  },
  contentType(type) {
    return `filter=content_type:${encodeURIComponent(type)}`;
  },
  tags(tags) {
    return tags.map(tag => `filter=${tag}`).join('&');
  },
};

function hasMinVisibilityAppRole(minVisibilityAppRole) {
  return [AppRoles.Owner, AppRoles.Editor, AppRoles.Viewer].includes(minVisibilityAppRole);
}

function hasContentType(contentType) {
  return [
    ContentTypes.Application,
    ContentTypes.Document,
    ContentTypes.Plot,
    ContentTypes.Pin,
    ContentTypes.Api,
    ContentTypes.TensorFlow,
  ].includes(contentType);
}

export const buildFilters = ({
  minVisibilityAppRole = AppRoles.None,
  deployedContent = false,
  undeployedContent = false,
  ownedByUser = '',
  contentType = ContentTypes.All,
  tags = [],
} = {}) => {
  const filters = [];
  if (hasMinVisibilityAppRole(minVisibilityAppRole)) {
    filters.push(Filters.minVisibilityAppRole(AppRoles.stringOf(minVisibilityAppRole)));
  }
  if (deployedContent) {
    filters.push(Filters.deployedContent());
  }
  if (undeployedContent) {
    filters.push(Filters.undeployedContent());
  }
  if (ownedByUser) {
    filters.push(Filters.ownedByUser(ownedByUser));
  }
  if (hasContentType(contentType)) {
    filters.push(Filters.contentType(contentType));
  }
  if (tags.length) {
    filters.push(Filters.tags(tags));
  }
  return filters;
};

export const getPublishedContentForUser = async({ userName, page, perPage }) => {
  const query = `is:published locked:false owner:${userName}`;
  return await searchContent({ query, page, perPage });
};

export function searchContent({
  query = 'locked:false',
  sortBy = 'last_deployed_time',
  order = undefined,
  page = undefined,
  perPage = undefined,
} = {}) {
  const params = {
    q: query,
    include: 'owner',
  };

  // We automatically hide any locked content for the dashboard experience.
  // If a locked filter is not in place we default to include locked:false.
  // Meaning, users have to explicitly set is:locked or locked:true to see locked content.
  const queryHasLockedFilter = query.includes(':locked') || query.includes('locked:');
  if (!queryHasLockedFilter) {
    params.q += ' locked:false';
  }

  if (sortBy) {
    params.sort = sortBy;
  }

  if (order) {
    params.order = order;
  }

  if (page) {
    params.pageNumber = page;
  }

  if (perPage) {
    params.pageSize = perPage;
  }

  return axios
    .get(apiV1Path(`/search/content`), { params: keysToSnake(params) })
    .then(({ data }) => {
      return {
        ...data,
        results: data.results.map(item => App.fromV1(keysToCamel(item))),
      };
    });
}

/**
 * Updates a content item settings.
 *
 * @param {string} contentGuid - GUID of the content
 * @param {object} updatePayload - values that need to be updated.
 * @returns {Promise<App>}
 */
export const updateContent = (contentGuid, updatePayload) => {
  return axios
    .patch(
      apiV1Path(`content/${encodeURIComponent(contentGuid)}`),
      keysToSnake(updatePayload)
    )
    .then(({ data }) => new App(keysToCamel(data)));
};

export const deleteContent = async guid =>
  axios.delete(apiV1Path(`content/${encodeURIComponent(guid)}`));

export const setApplicationRepository = (
  guid,
  { repository, branch, directory, polling = false }
) => {
  const repo = {
    repository: repository.trim(),
    branch: branch.trim(),
    directory: directory.trim(),
    polling,
  };

  return axios.put(apiV1Path(`content/${encodeURIComponent(guid)}/repository`), repo)
    .then(({ data }) => keysToCamel(data));
};
