<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import AppModes from '@/api/dto/appMode';
import LegacyParametersPanel from '@/components/LegacyParametersPanel';
import OAuthLoginModal from '@/components/OAuthLoginModal';
import ParametersPanel from '@/components/ParametersPanel';
import SubscriptionsModal from '@/components/SubscriptionsModal';
import { variantRedirectFilter } from '@/router/helpers';
import { SET_LOGS_PANEL_VISIBILITY } from '@/store/modules/contentView';
import LogsOverlay from '@/views/content/LogsOverlay';
import EmbedApp from '@/views/content/appFrame/EmbedApp';
import ContentSettingsPane from '@/views/content/settings/ContentSettingsPane';
import { isEmpty } from 'lodash';
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const route = useRoute();

onBeforeRouteUpdate((to, from, next) => {
  variantRedirectFilter(to, from, next);
});

const app = computed(() => store.state.contentView.app);
const showingLogs = computed(() => store.state.contentView.showLogs);
const isUnauthorized = computed(() => store.state.contentView.isUnauthorized);
const isSettingsOpen = computed(() => store.state.contentView.showSettingsPanel);
const currentUser = computed(() => store.state.currentUser.user);
const isAuthenticated = computed(() => store.state.currentUser.isAuthenticated);
const appHistoryOpen = computed(() => store.state.bundles.showHistoryPane);
const renderingHistoryOpen = computed(
  () => store.state.contentView.renderingHistory.showHistoryPane
);
const newParameterizationEnabled = computed(
  () => store.state.server.settings.newParameterizationEnabled
);
const oauthIntegrationsEnabled = computed(
  () => store.state.server.settings.oauthIntegrationsEnabled
);
const variants = computed(() => store.state.parameterization.variants);
const legacyParamsIsDirty = computed(() => store.state.legacyParams.form.dirty);

const isHistoryVisible = computed(() => appHistoryOpen.value || renderingHistoryOpen.value);
const userCanViewLogs = computed(
  () =>
    app.value &&
    isAuthenticated.value &&
    currentUser.value.canViewAppSettings(app.value)
);
const includeParamsPanel = computed(
  () =>
    app.value &&
    app.value.parameterized &&
    app.value.appMode === AppModes.StaticRmd &&
    currentUser.value.canViewApp(app.value)
);
const isUnauthorizedViewer = computed(() => !app.value && isUnauthorized.value);
const jobKey = computed(() => route.query.logKey || '');
const showSubscriptionsModal = computed(
  () =>
    app.value &&
    app.value.isRenderable() &&
    !isEmpty(variants.value) &&
    route.name === 'apps.subscriptions'
);
const showOAuthModal = computed(
  () =>
    oauthIntegrationsEnabled.value &&
    !isSettingsOpen.value &&
    !isUnauthorizedViewer.value &&
    !showingLogs.value
);

onMounted(() => {
  if (includeParamsPanel.value) {
    // prompt before leaving page if we have unsaved params changes
    window.addEventListener('beforeunload', paramsBrowserLeaveCheck);
  }

  if (jobKey.value) {
    openLogs();
  }
});

onBeforeUnmount(() => {
  if (includeParamsPanel.value) {
    window.removeEventListener(
      'beforeunload',
      paramsBrowserLeaveCheck
    );
  }
});

const paramsBrowserLeaveCheck = ev => {
  // Some browsers want the text to be returned via e.returnValue, and others
  // just want the text returned, so we do both.
  // Some browsers ignore the text and use their own text. Chrome seems to do this.
  const text = 'You have unsaved changes.';
  if (legacyParamsIsDirty.value) {
    ev.returnValue = text;
    return text;
  }
};

const hideLogs = () => {
  store.commit(SET_LOGS_PANEL_VISIBILITY, false);
};

const openLogs = () => {
  store.commit(SET_LOGS_PANEL_VISIBILITY, true);
};
</script>

<template>
  <div
    class="pageContainer"
    :class="{ showingHistoryBar: isHistoryVisible }"
  >
    <LogsOverlay
      v-if="userCanViewLogs"
      :visible="showingLogs"
      :app-id="app.id"
      :job-key="jobKey"
      @hide="hideLogs"
      @show="openLogs"
    />

    <LegacyParametersPanel
      v-if="includeParamsPanel && !newParameterizationEnabled && !app?.locked"
    />
    <ParametersPanel
      v-if="includeParamsPanel && newParameterizationEnabled && !app?.locked"
    />
    <EmbedApp />
    <ContentSettingsPane v-if="!isUnauthorizedViewer" />
    <SubscriptionsModal v-if="showSubscriptionsModal" />
    <OAuthLoginModal v-if="showOAuthModal" />
  </div>
</template>
