<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup>
import { downloadJobLogPath, getJobLog } from '@/api/jobs';
import LogViewer from '@/components/LogViewer';
import Spinner from '@/components/Spinner.vue';
import RSButton from '@/elements/RSButton.vue';
import RSModal from '@/elements/RSModal.vue';
import { SET_BUNDLE_LOG_POSITION } from '@/store/modules/bundles';
import LogOutput from '@/views/content/LogsOverlay/LogOutput';
import isEmpty from 'lodash/isEmpty';
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  jobs: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close']);

const store = useStore();

const app = computed(() => store.state.contentView.app);
const isFetchingBundles = computed(() => store.state.bundles.isFetchingBundles);
const isFetchingBundleJobs = computed(() => store.state.bundles.isFetchingBundleJobs);
const currentJobKey = computed(() => store.state.bundles.selected.currentJobKey);
const deployLogs = computed(() => store.state.bundles.selected.deployLogs);
const isDeploying = computed(() => store.state.bundles.isDeploying);

const jobIndex = computed(() => props.jobs.findIndex(j => j.key === currentJobKey.value));
const currentJob = computed(() => props.jobs[jobIndex.value]);
const previousVisible = computed(() => !isDeploying.value && props.jobs.length > 1);
const previousEnabled = computed(() => jobIndex.value < props.jobs.length - 1);
const nextVisible = computed(() => !isDeploying.value && props.jobs.length > 1);
const nextEnabled = computed(() => jobIndex.value > 0);
const downloadVisible = computed(
  () => !isDeploying.value && !finishedDeploying.value && !isEmpty(props.jobs)
);
const downloadEnabled = computed(() => !isEmpty(props.jobs));
const downloadUrl = computed(() =>
  app.value.guid && currentJobKey.value
    ? downloadJobLogPath(app.value.guid, currentJobKey.value)
    : '');
const isFetchingData = computed(() => isFetchingBundleJobs.value || isFetchingBundles.value);
const finishedDeploying = computed(() => !isDeploying.value && deployLogs.value.length);

const logTitle = computed(() => {
  if (isFetchingData.value) {
    return 'Loading...';
  }
  if (!currentJobKey.value) {
    return 'No logs found.';
  }
  if (isEmpty(props.jobs) || jobIndex.value === -1) {
    return 'Log not found.';
  }
  if (isDeploying.value) {
    return 'Activating';
  }
  if (finishedDeploying.value) {
    return 'Activation complete.';
  }
  return `Activation log for version #${currentJob.value.bundleId}: ${currentJob.value.key}`;
});

const setBundleLogPosition = position => {
  store.commit(SET_BUNDLE_LOG_POSITION, position);
};

const nextJob = () => {
  const { key } = props.jobs[jobIndex.value - 1];
  setBundleLogPosition(key);
  getJobLog(app.value.guid, key);
};

const prevJob = () => {
  const { key } = props.jobs[jobIndex.value + 1];
  setBundleLogPosition(key);
  getJobLog(app.value.guid, key);
};

const onDownload = () => {
  window.location.href = downloadUrl.value;
};
</script>

<template>
  <RSModal
    :active="true"
    width="wide"
    :closeable="!isDeploying"
    :subject="logTitle"
    data-automation="bundle-logs-modal"
    @close="emit('close')"
  >
    <template #content>
      <div class="log-container">
        <LogViewer
          v-if="isDeploying || finishedDeploying"
          :entries="deployLogs"
          :embedded="false"
          class="output"
        />
        <LogOutput
          v-else-if="jobs.length > 0"
          :app="app"
          :job="currentJob"
          class="output"
        />
      </div>
    </template>
    <template #controls>
      <div class="actions">
        <div class="left-buttons">
          <RSButton 
            v-if="previousVisible"
            :disabled="!previousEnabled"
            data-automation="previous-log"
            label="← Previous Activation"
            type="secondary"
            @click="prevJob"
          />

          <RSButton
            v-if="nextVisible"
            :disabled="!nextEnabled"
            data-automation="next-log"
            label="Next Activation →"
            type="secondary"
            @click="nextJob"
          />

          <RSButton
            v-if="downloadVisible"
            :disabled="!downloadEnabled"
            data-automation="log-download"
            label="Download Log"
            type="secondary"
            @click="onDownload"
          />
        </div>

        <div class="right-buttons">
          <div
            v-if="isDeploying"
            class="spinner-container"
          >
            <Spinner />
          </div>
          <RSButton
            :disabled="isDeploying"
            label="Close"
            @click="emit('close')"
          />
        </div>
      </div>
    </template>
  </RSModal>
</template>

<style lang="scss" scoped>
.output {
  height: 60vh;
  text-align: left;
}

.log-container {
  max-width: 80vw;
  min-width: 80vw;
}

.actions {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  .left-buttons button {
    margin-right: 1rem;
  }

  .right-buttons {
    .spinner-container {
      height: 2.5rem;
      width: 2.5rem;
      margin-right: 1rem;
    }
    display: flex;
    align-items: center;
  }
}
</style>
