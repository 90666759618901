// Copyright (C) 2023 by Posit Software, PBC.
import { store } from '@/store';

import DocumentationView from '@/views/documentation/DocumentationView.vue';
import ExecutionEnvironments from '@/views/executionEnvironments/ExecutionEnvironments.vue';
import IntegrationsView from '@/views/integrations/IntegrationsView.vue';
import WhatsNewView from '@/views/whats-new/WhatsNewView.vue';
import AppHeader from '@/components/AppHeader/index.vue';
import UserRoles from '@/api/dto/userRole';

const hideViewerDocs = () => {
  if (store.state.server.settings.hideViewerDocumentation &&
      store.state.currentUser.user.userRole === UserRoles.of('viewer')) {
    return false;
  }
  return true;
};

export default [
  {
    path: '/help',
    name: 'help',
    redirect: () => ({ name: 'help.docs' })
  },
  {
    path: '/more',
    name: 'help.more',
    redirect: () => ({ name: 'help.docs' })
  },
  {
    path: '/help/docs',
    name: 'help.docs',
    meta: {
      requireAuth: true,
      enabled: hideViewerDocs,
      title: 'Documentation',
    },
    components: {
      default: DocumentationView,
      header: AppHeader,
    },
  },
  {
    path: '/environments',
    name: 'environments',
    meta: {
      requireAuth: true,
      title: 'Environments',
    },
    components: {
      default: ExecutionEnvironments,
      header: AppHeader,
    },
  },
  {
    path: '/integrations',
    name: 'integrations',
    meta: {
      requireRole: ['publisher', 'administrator'],
      enabled: () => store.state.server.settings.oauthIntegrationsEnabled,
      title: 'Integrations',
    },
    components: {
      default: IntegrationsView,
      header: AppHeader,
    },
  },
  {
    path: '/whatsnew',
    name: 'whatsnew',
    meta: {
      requireAuth: true,
      enabled: () => store.state.server.settings.whatsNewEnabled,
      title: `What's New`,
    },
    components: {
      default: WhatsNewView,
      header: AppHeader,
    },
  },
];
