<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <div
      class="flex"
      data-automation="scheduled-title"
    >
      <h1 class="sectionTitle">
        Scheduled Content
      </h1>
    </div>

    <EmbeddedStatusMessage
      v-if="!api.loaded"
      :show-close="false"
      message="Getting schedules..."
      data-automation="scheduled-content__message"
      type="activity"
    />

    <div
      v-if="api.loaded"
      class="rsc-schedule__filters"
      data-automation="scheduled-content__filters"
    >
      {{ `All times are displayed in local time (${offset})` }}
    </div>

    <CalendarWeekView
      v-if="api.loaded"
      :week-view="api.weekView"
      data-automation="scheduled-content__calendar__container"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';

import { getSchedules } from '@/api/scheduledContent';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { localOffset } from '@/utils/timezone';

import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { mapMutations } from 'vuex';
import CalendarWeekView from './CalendarWeekView';
import { ScheduledContent } from './scheduledContent';
import { WeekView } from './weekView';

export default {
  name: 'ScheduledContent',
  components: {
    EmbeddedStatusMessage,
    CalendarWeekView,
  },
  data() {
    return {

      // subset of schedules based on user selected filters
      filteredSchedules: [],
      offset: localOffset(),

      api: {
        loaded: false,

        // scheduled content list for 1 year - [ScheduledContent]
        yearSchedules: null,

        // scheduled content list for 1 week - WeekView
        weekView: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    init() {
      const now = dayjs();
      const week = dayjs(now).add(6, 'days');

      // get 1 week of scheduled content with details (calendar view)
      getSchedules({
        detailed: true,
        // call toISOString with `true` to force inclusion of local time offset, if any
        start: now.toISOString(true),
        end: week.toISOString(true),
      }).then((weekResponse) => {
        this.api.weekView = new WeekView(
          now,
          weekResponse.schedules.map(s => new ScheduledContent(s, week))
        );
        this.api.loaded = true;
      }).catch(this.setErrorMessageFromAPI);
    },
  },
};
</script>

<style lang="scss" scoped>
  .rsc-schedule__filters {
    display: flex;
    margin-bottom: 0.5rem;
    justify-content: flex-end;
  }
</style>
