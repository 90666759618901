<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<template>
  <RSModal
    :active="true"
    subject="Rename Report"
    data-automation="params-rename-modal"
    @close="closeModal"
  >
    <template #content>
      <div>
        <RSInputText
          v-model.trim="newName"
          label="Enter a name for this report"
          :maxlength="MAX_LENGTH"
          name="rename-variant-input"
        />
      </div>
    </template>

    <template #controls>
      <RSButton
        label="Save"
        :disabled="disabled"
        @click="submit"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from '@/elements/RSButton.vue';
import RSInputText from '@/elements/RSInputText.vue';
import RSModal from '@/elements/RSModal.vue';
import { LEGACY_PARAMS_SET_RENAMING_VARIANT } from '@/store/modules/legacyParams';
import { PARAMETERIZATION_RENAME_VARIANT } from '@/store/modules/parameterization';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'LegacyRenameVariantModal',
  components: {
    RSButton,
    RSInputText,
    RSModal,
  },
  data() {
    return {
      newName: '',
      MAX_LENGTH: 50,
    };
  },
  computed: {
    ...mapState({
      currentVariantName: state => state.parameterization.currentVariant.name,
    }),
    disabled() {
      return !this.newName.length;
    },
  },
  created() {
    // Copy current variant name for input value
    this.newName = this.currentVariantName;
  },
  methods: {
    ...mapMutations({
      setModalState: LEGACY_PARAMS_SET_RENAMING_VARIANT,
    }),
    ...mapActions({
      renameVariant: PARAMETERIZATION_RENAME_VARIANT,
    }),
    submit() {
      if (this.disabled) {
        return;
      }

      return this.renameVariant(this.newName)
        .then(() => {
          this.closeModal();
        });
    },
    closeModal() {
      this.setModalState(false);
    },
  },
};
</script>
