<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import SlidingToggle from '@/components/SlidingToggle.vue';
import TabSelector from '@/components/TabSelector.vue';
import RSButton from '@/elements/RSButton.vue';
import { SERVER_SETTINGS_SET_VALUE } from '@/store/modules/server';
import actionReset from 'Images/elements/actionReset.svg';
import actionStop from 'Images/elements/actionStop.svg';
import { computed, onBeforeMount, reactive, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const localState = reactive({
  activeTabId: 'modified',
  allSettings: {},
  customSettings: {},
  showCustomSettings: false,
});

const tabs = [
  { id: 'modified', label: 'Modified' },
  { id: 'all', label: 'All' },
];

const currentUser = computed(() => store.state.currentUser.user);
const serverSettings = computed(() => store.state.server.settings);
const hasCustomSettings = computed(
  () => Object.keys(localState.customSettings).length > 0
);

const loadCustomSettings = () => {
  const storageSettings = localStorage.getItem('serverSettings') || '{}';
  localState.customSettings = JSON.parse(storageSettings);
  localState.allSettings = Object.keys(serverSettings.value).reduce(
    (acc, key) => {
      if (
        key.startsWith('$') ||
        !(typeof serverSettings.value[key] === 'boolean')
      ) {
        return acc;
      }

      acc[key] = {
        key,
        checked: serverSettings.value[key],
      };
      return acc;
    },
    {}
  );
};

watch(
  serverSettings,
  () => {
    loadCustomSettings();
  },
  { deep: true, immediate: true }
);

watch(localState, () => {
  const hideSettings = () => {
    localState.showCustomSettings = false;
  };
  if (localState.showCustomSettings) {
    window.addEventListener('click', hideSettings);
    return;
  }

  window.removeEventListener('click', hideSettings);
});

onBeforeMount(() => {
  loadCustomSettings();
});

const disableKeeper = () => {
  store.commit(SERVER_SETTINGS_SET_VALUE, {
    key: 'serverSettingsToggler',
    checked: false,
  });
};

const setActiveTab = tabId => {
  localState.activeTabId = tabId;
};

const getToggleLabel = key =>
  localState.allSettings[key].checked ? `${key} is on` : `${key} is off`;
const getResetLabel = key => `Reset ${key}`;

const onToggleSetting = (key, value) => {
  localState.customSettings = {
    ...localState.customSettings,
    [key]: {
      ...localState.customSettings[key],
      checked: value,
    },
  };

  store.commit(SERVER_SETTINGS_SET_VALUE, {
    key,
    checked: localState.customSettings[key].checked,
  });
};

const onReset = key => {
  store.commit(SERVER_SETTINGS_SET_VALUE, {
    key,
    checked: localState.customSettings[key].original,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: _, ...rest } = localState.customSettings;
  localState.customSettings = rest;

  localStorage.setItem(
    'serverSettings',
    JSON.stringify(localState.customSettings)
  );
};
</script>

<template>
  <div
    v-if="serverSettings.serverSettingsToggler && currentUser.isAdmin()"
    class="server-settings-keeper"
    data-automation="server-settings-keeper"
  >
    <RSButton
      :use-label-width="true"
      class="keeper-toggle"
      label="✨"
      title="Open Server Settings"
      type="link"
      data-automation="keeper-toggle"
      @click.stop="
        localState.showCustomSettings = !localState.showCustomSettings
      "
    />
    <div
      v-if="localState.showCustomSettings"
      class="settings-popup"
      @click.stop
    >
      <div class="title-bar">
        <h2 class="title">
          Server Settings
        </h2>

        <RSButton
          :icon="actionStop"
          :icon-only="true"
          :use-label-width="true"
          aria-label="Disable Server Settings Keeper"
          data-automation="disable-keeper"
          label=""
          title="Disable Server Settings Keeper"
          type="link"
          @click="disableKeeper"
        />
      </div>

      <div class="tab-container">
        <TabSelector
          name="server-settings"
          class="tab-selector"
          :tabs="tabs"
          :active-tab-id="localState.activeTabId"
          @update:active-tab-id="setActiveTab"
        />
      </div>

      <div class="table-container">
        <table
          v-if="localState.activeTabId === 'modified'"
          class="settings-table"
        >
          <tbody v-if="hasCustomSettings">
            <tr
              v-for="key in Object.keys(localState.customSettings).sort()"
              :key="key"
              class="settings-table__row"
            >
              <td class="settings-table__key">
                {{ key }}
              </td>
              <td class="settings-table__value">
                <SlidingToggle
                  v-model="localState.customSettings[key].checked"
                  :data-automation="`toggle-${key}`"
                  :hide-label="true"
                  :label="getToggleLabel(key)"
                  @update:model-value="value => onToggleSetting(key, value)"
                />
                <RSButton
                  class="settings-table__reset-button"
                  :aria-label="getResetLabel(key)"
                  :data-automation="`reset-${key}`"
                  :icon="actionReset"
                  :icon-only="true"
                  :title="`Reset ${key}`"
                  label=""
                  type="link"
                  :use-label-width="true"
                  @click="onReset(key)"
                />
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td class="settings-table__empty">
                You have not modified any settings.
              </td>
            </tr>
          </tbody>
        </table>
        <table
          v-else
          class="settings-table"
        >
          <tr
            v-for="key in Object.keys(localState.allSettings).sort()"
            :key="key"
            class="settings-table__row"
          >
            <td class="settings-table__key">
              {{ key }}
            </td>
            <td class="settings-table__value">
              <SlidingToggle
                v-model="localState.allSettings[key].checked"
                :data-automation="`toggle-${key}`"
                :hide-label="true"
                :label="getToggleLabel(key)"
                @update:model-value="value => onToggleSetting(key, value)"
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.server-settings-keeper {
  display: inline-block;
  position: relative;
  z-index: 100;

  .settings-popup {
    @include normal-drop-shadow();
    background-color: $color-white;
    border-radius: 3px;
    border: 1px solid $color-dialog-border;
    position: absolute;
    right: -10px;
    top: 3rem;
    padding: 0.5rem;

    .title-bar {
      display: flex;
      justify-content: space-between;

      .title {
        font-weight: 600;
        font-size: 1rem;
        margin: 0.5rem 0 0.8rem;
      }
    }

    .tab-container {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
      width: 100%;
    }

    .table-container {
      max-height: 65vh;
      min-width: 390px;
      overflow-y: auto;
      border: 1px solid $color-medium-grey;
    }

    .settings-table {
      width: 100%;

      &__row {
        border-bottom: 1px solid $color-medium-grey;

        &:last-child {
          border-bottom: none;
        }
      }

      &__key,
      &__empty {
        background-color: lighten($color-yellow, 32%);
        border-right: 1px solid $color-medium-grey;
        padding: 0 1rem;
        vertical-align: middle;
        width: 100%;

        &.settings-table__empty {
          border-right: none;
          padding: 1rem;
          text-align: center;
        }
      }

      &__value {
        display: flex;
        align-items: center;
        background-color: $color-light-grey;
        padding: 0.25rem 0.4rem;
      }

      &__reset-button {
        font-size: 1.2rem;
        margin-left: 0.5rem;
        padding: 0.3rem;
      }
    }
  }
}
</style>
