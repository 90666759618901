<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup lang='ts'>
import { DEPLOY_WIZARD_OPEN } from '@/store/modules/deployWizard';
import { JUMP_START_OPEN } from '@/store/modules/jumpstart';
import { docsPath } from '@/utils/paths';
import { computed, nextTick, onBeforeMount, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';

interface Props {
  gitEnabled: boolean;
  gitAvailable: boolean;
  jumpStartEnabled: boolean;
}

const props = defineProps<Props>();
const IMPORT_FROM_GIT = 'git';
const JUMP_START = 'jumpStart';
const PUBLISHING_GUIDE = 'publishingGuide';

const store = useStore();

const currentItem = ref(0);
const menuItemRefs = ref([]);
const isOpen = ref(false);
const publishButton = ref(null);

const gitEnabledButNotAvailable = computed(() => props.gitEnabled && !props.gitAvailable);
const gitButtonTitle = computed(() => gitEnabledButNotAvailable.value ? 'Git is not available on your installation' : null);
const menuItems = computed(() => [
  {
    type: IMPORT_FROM_GIT,
    cssName: 'new-from-git',
    dataAutomation: 'new-from-git',
    onClick: startDeploymentWizard,
    title: gitButtonTitle.value,
    hidden: !props.gitEnabled,
    disabled: gitEnabledButNotAvailable.value,
    label: 'Import from Git',
  },
  {
    type: JUMP_START,
    cssName: 'jump-start',
    dataAutomation: 'publish-dropdown__jump-start',
    onClick: startJumpStartDialog,
    hidden: !props.jumpStartEnabled,
    label: 'Jump Start Examples',
  },
  {
    type: PUBLISHING_GUIDE,
    cssName: 'publishing-guide',
    dataAutomation: 'publish-dropdown__user-guide',
    onClick: openPublishingGuide,
    label: 'How to Publish (User Guide)',
  },
].filter(({ hidden }) => !hidden));

onBeforeMount(() => {
  window.addEventListener('click', () => hideMenu());
});
onUnmounted(() => {
  window.removeEventListener('click', () => hideMenu());
});

const focusButton = () => {
  setTimeout(() => publishButton.value?.focus(), 100);
};

const toggleMenu = async() => {
  isOpen.value = !isOpen.value;
  if (isOpen.value) {
    currentItem.value = 0;
    await nextTick();
    menuItemRefs.value[currentItem.value].focus();
  }
};
    
const hideMenu = ({ keepFocus }: { keepFocus?: boolean} = {}) => {
  isOpen.value = false;
  if (keepFocus) {
    focusButton();
  }
};

const onArrowUp = () => {
  const item = currentItem.value - 1;
  currentItem.value =
    item < 0 ? menuItems.value.length - 1 : item;
  menuItemRefs.value[currentItem.value].focus();
};

const onArrowDown = () => {
  const item = currentItem.value + 1;
  currentItem.value =
    item > menuItems.value.length - 1 ? 0 : item;
  menuItemRefs.value[currentItem.value].focus();
};

const openPublishingGuide = () => {
  open(docsPath('user/publishing-overview/'), '_blank');
  focusButton();
};

const startDeploymentWizard = () => {
  store.commit(DEPLOY_WIZARD_OPEN);
};

const startJumpStartDialog = () => {
  store.commit(JUMP_START_OPEN);
};
</script>

<template>
  <div class="publish-dropdown">
    <button
      ref="publishButton"
      type="button"
      class="publish-dropdown__button"
      data-automation="publish-button"
      aria-label="Publish"
      aria-haspopup="true"
      :aria-expanded="isOpen"
      @click.stop="toggleMenu"
    >
      <img
        src="/images/publish-icon.svg"
        alt=""
      >
      <span class="publish-dropdown__button-label">Publish</span>
      <img
        class="publish-dropdown__button-open-icon"
        src="/images/iconExpanded.svg"
        alt="Open menu"
      >
    </button>

    <!-- eslint-disable-next-line vuejs-accessibility/interactive-supports-focus -->
    <ul
      role="menu"
      :class="[
        'publish-dropdown__menu',
        { 'publish-dropdown__menu--open': isOpen }
      ]"
      data-automation="publish-dropdown__menu--open"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.tab="hideMenu"
      @keydown.esc="hideMenu({ keepFocus: true })"
    >
      <li
        v-for="item in menuItems"
        :key="item.type"
        role="none"
      >
        <button
          ref="menuItemRefs"
          :aria-label="item.label"
          :class="`publish-dropdown__menu-button publish-dropdown__menu-button--${item.cssName}`"
          :data-automation="item.dataAutomation"
          :disabled="item.disabled"
          :title="item.title"
          data="item.type"
          type="button"
          role="menuitem"
          tabindex="-1"
          @click="item.onClick"
        >
          {{ item.label }}
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
  @import 'Styles/shared/_colors';
  @import 'Styles/shared/_variables';
  @import 'Styles/shared/_mixins';

  .publish-dropdown {
    display: inline-block;
    position: relative;

    .publish-dropdown__menu {
      white-space: nowrap;
    }

    &__button {
      display: flex;
      align-items: center;
      gap: .6rem;
      justify-content: center;
      height: 30px;
      color: $color-white;
      padding: 0 1rem;
      border-radius: 4px;
      background-color: $color-primary;

      &-up-icon {
        height: 12px;
      }

      &-open-icon {
        height: 12px;
        filter: invert(1);
      }

      @media screen and (max-width : 650px) {
        &-label {
          display: none;
        }
      }
    }

    &__menu {
      @include strong-drop-shadow;
      background-color: $color-white;
      display: none;
      font-size: 13px;
      line-height: 40px;
      position: absolute;
      right: 0px;
      text-align: left;
      z-index: 1000;

      li {
        margin-bottom: 0;
      }

      &.publish-dropdown__menu--open {
        display: block;
      }
    }

    &__menu-button {
      display: block;
      margin-left: 0;
      height: 40px;
      width: 100%;
      padding: 0px 15px 0px 40px;
      text-align: left;
      background-color: transparent;
      background-size: 2em 2em;
      background-position: 0.6em center;
      background-repeat: no-repeat;

      &:hover {
        background-color: $color-posit-teal;
        color: $color-white;
      }

      &:focus {
        outline: none;
      }

      &:focus-visible {
        color: $color-white;
        background-color: $color-posit-teal;
        outline: none;
      }

      &--new-from-git {
        background-image: url('/images/Git-Icon-BlackA42.svg');
      }

      &--jump-start {
        background-image: url('/images/elements/actionSamples.svg');
      }

      &--publishing-guide {
        background-image: url('/images/elements/actionInfo.svg');
      }
    }

    &__menu-separator {
      line-height: 1px;
      height: 1px;
      margin: 4px 0 5px 0;
      border-bottom: 1px dotted $color-light-grey-4;
    }
  }
</style>
