<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <dl
    class="local-about-table"
    data-automation="documentation-about-local__table"
  >
    <div>
      <dt>
        Product:
      </dt>
      <dd>
        Posit Connect
      </dd>
    </div>
    <div v-if="version">
      <dt>
        Version:
      </dt>
      <dd>
        {{ version }}
      </dd>
    </div>
    <div v-if="build">
      <dt>
        Build:
      </dt>
      <dd>
        {{ build }}
      </dd>
    </div>
    <div v-if="execType">
      <dt>
        Execution Type:
      </dt>
      <dd>
        {{ execType }}
      </dd>
    </div>
    <div v-if="tier">
      <dt>
        License Tier:
      </dt>
      <dd>
        {{ tier }}
      </dd>
    </div>
    <div v-if="entitlement">
      <dt>
        Entitlement:
      </dt>
      <dd>
        {{ entitlement }}
      </dd>
    </div>
    <div v-if="endOfSupport">
      <dt>
        End of Support:
      </dt>
      <dd>
        {{ endOfSupport }}
      </dd>
    </div>
    <template v-if="showVersionData">
      <div>
        <dt>
          R Versions:
        </dt>
        <dd data-automation="r-versions">
          {{ rInstalls }}
        </dd>
      </div>
      <div>
        <dt>
          Python Versions:
        </dt>
        <dd data-automation="python-versions">
          {{ pythonInstalls }}
        </dd>
      </div>
      <div>
        <dt>
          Quarto Versions:
        </dt>
        <dd data-automation="quarto-versions">
          {{ quartoInstalls }}
        </dd>
      </div>
      <div>
        <dt>
          TensorFlow Versions:
        </dt>
        <dd data-automation="tensorflow-versions">
          {{ tensorflowInstalls }}
        </dd>
      </div>
    </template>
  </dl>
</template>

<script>
import {
  ExecutionTypeNative,
  getPythonInstallations,
  getQuartoInstallations,
  getRInstallations,
  getServerSettings,
  getTensorFlowInstallations,
} from '@/api/serverSettings';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { endOfSupport, normalizedVersion } from '@/utils/version';
import startCase from 'lodash/startCase';
import { mapMutations } from 'vuex';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export default {
  name: 'About',
  data() {
    return {
      about: '',
      build: '',
      version: '',
      endOfSupport: '',
      rInstalls: [],
      pythonInstalls: [],
      quartoInstalls: [],
      tensorflowInstalls: [],
      initialized: false,
      showVersionData: false,
      tier: '',
      entitlement: '',
      execType: '',
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    async initialize() {
      try {
        const serverSettings = await getServerSettings();
        const { about, build, version, executionType, license } = serverSettings;
        this.about = about;
        this.build = build;
        this.version = version;
        this.tier = startCase(license.tier);
        if (version !== '') {
          const normalized = normalizedVersion(version);
          const eos = endOfSupport(normalized);
          const formatted = eos.format('LL');
          this.endOfSupport = formatted;
        }
        if(license.anonymousServers) {
          this.entitlement = 'Public Access';
          if(license.unrestrictedServers) {
            this.entitlement = 'Unrestricted';
          }
        }
        if(executionType === ExecutionTypeNative) {
          this.showVersionData = true;
          this.execType = 'Local';
        } else {
          this.execType = 'Off-host (Kubernetes)';
        }

        if (this.showVersionData) {
          await this.getInstallations();
        }
        this.initialized = true;
      } catch (e) {
        this.setErrorMessageFromAPI(e);
      }
    },
    async getInstallations() {
      try {
        const rInstalls = await getRInstallations();
        this.rInstalls = this.buildVersionString(
          rInstalls.map(install => install.version)
        );

        const pythonInstalls = await getPythonInstallations();
        this.pythonInstalls = this.buildVersionString(
          pythonInstalls.map(install => install.version)
        );

        const quartoInstalls = await getQuartoInstallations();
        this.quartoInstalls = this.buildVersionString(
          quartoInstalls.map(install => install.version)
        );

        const tensorflowInstalls = await getTensorFlowInstallations();
        this.tensorflowInstalls = this.buildVersionString(
          tensorflowInstalls.map(install => install.version)
        );
      } catch (e) {
        this.setErrorMessageFromAPI(e);
      }
    },
    buildVersionString(versions) {
      if (versions === undefined) {
        // Likely to be unreachable with real data.
        return '(unknown)';
      }

      if (versions.length === 0) {
        return '(none)';
      }

      return versions.join(', ');
    },
  },
};
</script>

<style scoped lang="scss">
.local-about-table {
  margin-bottom: 4rem;
  div {
    line-height: 1.1rem;
    display: flex;
    margin-bottom: 0.9rem;
    dt, dd {
      display: inline-block;
    }
    dt {
      min-width: 11rem;
      padding-right: 2rem;
      font-weight: 700;
    }
  }
}
</style>
