<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <AppContainer data-automation="not-found">
    <h1
      class="sectionTitle"
      data-automation="not-found__title"
    >
      Not found
    </h1>

    <p data-automation="not-found__message">
      There's nothing here! Try again.
    </p>
    <RouterLink :to="{ name: returnLink }">
      <RSButton
        class="go_back"
        type="primary"
        label="Back"
        data-automation="not-found__back-button"
      >
        Back
      </RSButton>
    </RouterLink>
  </AppContainer>
</template>

<script>
import AppContainer from '@/components/AppContainer';
import RSButton from '@/elements/RSButton.vue';
import { mapState } from 'vuex';
import { RouterLink } from 'vue-router';

export default {
  name: 'NotFoundView',
  components: {
    AppContainer,
    RSButton,
    RouterLink
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.currentUser.isAuthenticated,
    }),
    returnLink() {
      if (this.isAuthenticated) {
        return 'contentList';
      }
      return 'welcome';
    }
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.go_back {
  margin-top: 2em;
}
</style>
