<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div data-automation="metrics-feature-usage">
    <RSInformationToggle>
      <template #title>
        <div class="sectionTitle small">
          Usage Scorecard
        </div>
      </template>
      <template #rightSideControls>
        <button
          v-show="hasData"
          class="smallTextButton"
          @click="copyReport"
        >
          Copy
        </button>
      </template>
      <template #help>
        <p>
          The usage scorecard indicates what top line Posit Connect features
          you have used.
        </p>
        <p>
          To help improve Connect, you can send Posit this usage information
          from time to time by copying the scorecard and emailing us.
        </p>
      </template>
    </RSInformationToggle>
    <pre
      class="rsc-metrics__feature-usage-output"
      tabindex="0"
    ><code>{{ hasData ? usageMetricsReport : 'Unable to load usage scorecard data.' }}</code></pre>

    <RSModal
      v-if="showNotifyCopyDialog"
      subject="Usage Scorecard Copied"
      close-button-label="Close Modal"
      :active="showNotifyCopyDialog"
      @close="closeNotifyCopyDialog"
    >
      <template #content>
        <div>
          <p>
            The usage scorecard data has been copied. Please paste it into an
            email and send it to
            <a
              href="mailto:usage@rstudioconnect.com"
              target="_blank"
            >usage@rstudioconnect.com</a>.
          </p>
          <p>
            Thank you for making Posit Connect better!
          </p>
          <p>
            Learn more about our privacy policy:
            <a
              href="https://posit.co/about/privacy-policy/"
              target="_blank"
            >https://posit.co/about/privacy-policy/</a>
          </p>
        </div>
      </template>
      <template #controls>
        <RSButton
          label="OK"
          @click="closeNotifyCopyDialog"
        />
      </template>
    </RSModal>
  </div>
</template>

<script>
import { getFeatureUsage } from '@/api/featureUsage';
import { keysToSnake } from '@/api/transform';
import RSButton from '@/elements/RSButton.vue';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import RSModal from '@/elements/RSModal.vue';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { copyToClipboard } from '@/utils/clipboard';
import dayjs from 'dayjs';
import { mapMutations } from 'vuex';

export default {
  name: 'FeatureUsage',
  components: {
    RSButton,
    RSModal,
    RSInformationToggle,
  },
  props: {
    version: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showNotifyCopyDialog: false,
      usageMetrics: {},
    };
  },
  computed: {
    // Converts {foo: 'bar', baz: 'xyzzy'} into 'foo: bar\nbaz: xyzzy'
    usageMetricsReport() {
      return Object.entries(this.usageMetrics)
        .map(([k, v]) => {
          return `${k}: ${v}`;
        })
        .join('\n');
    },
    hasData() {
      return Object.entries(this.usageMetrics).length !== 0;
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    init() {
      getFeatureUsage()
        .then(data => {
          const features = data.reduce((map, obj) => {
            map[obj.name] = obj.used;
            return map;
          }, {});

          const metadata = keysToSnake({
            connectVersion: this.version,
            dateTime: dayjs().format('YYYY/MM/DD HH:mm:ss.SSS'),
          });

          this.usageMetrics = { ...metadata, ...features };
        })
        .catch(this.setErrorMessageFromAPI);
    },
    closeNotifyCopyDialog() {
      this.showNotifyCopyDialog = false;
    },
    async copyReport() {
      await copyToClipboard({ value: this.usageMetricsReport });
      this.showNotifyCopyDialog = true;
    },
  },
};
</script>
