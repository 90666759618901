<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup>
import {
  getMailSettings,
  testMailSettings,
} from '@/api/serverSettings';
import RSButton from '@/elements/RSButton.vue';
import { docsPath } from '@/utils/paths';
import { computed, onBeforeMount, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { CLEAR_STATUS_MESSAGE, SET_ERROR_MESSAGE_FROM_API, SHOW_INFO_MESSAGE } from '@/store/modules/messages';

const store = useStore();

const loaded = computed(() => Boolean(form.value.type));
const userEmail = computed(() => store.state.currentUser.user.email);
const isAdmin = computed(() => store.state.currentUser.user.isAdmin());
const emailSetupUrl = computed(() => docsPath('admin/email/'));
const isConfigured = computed(() => form.value.type !== 'none');
const isSMTP = computed(() => form.value.type === 'smtp');
const sendType = computed(() => {
  if (isSMTP.value) {
    return 'SMTP';
  } else if (!isSMTP.value && isConfigured.value) {
    return 'Sendmail';
  }
  return 'None';
});
const startTls = computed(() => {
  return form.value.startTls === 'whenAvailable' || form.value.startTls === '' ?
    'When available' : form.value.startTls;
});

const form = ref({});

onBeforeMount(async() => {
  if(isAdmin.value) {
    try {
      const settings = await getMailSettings();
      form.value = { ...(settings || {}), password: '' };
      if (form.value.port < 1) {
        form.value.port = null;
      }
    }
    catch (e) {
      store.commit(SET_ERROR_MESSAGE_FROM_API, e);
    }
  }
});

onUnmounted(() => {
  store.commit(CLEAR_STATUS_MESSAGE);
});

const sendTestEmail = async() => {
  store.commit(CLEAR_STATUS_MESSAGE);
  store.dispatch(SHOW_INFO_MESSAGE, { message: 'Sending test email.', autohide: false });
  try {
    await testMailSettings();
    store.commit(CLEAR_STATUS_MESSAGE);
    store.dispatch(SHOW_INFO_MESSAGE, { message: `Test email sent to ${userEmail.value}` });
  } catch (e) {
    store.commit(CLEAR_STATUS_MESSAGE);
    store.commit(SET_ERROR_MESSAGE_FROM_API, e);
  }
};
</script>

<template>
  <div class="header">
    <h2 class="sectionTitle">
      Email Settings
    </h2>
    <RSButton
      v-if="isConfigured"
      :use-label-width="true"
      label="Send Test Email"
      data-automation="test-email"
      class="test-email-button"
      @click="sendTestEmail"
    />
  </div>
  <div class="info">
    <p>
      These settings are controlled by your configuration file.
    </p>
    <p>
      Please visit the
      <a
        :href="emailSetupUrl"
        target="_blank"
      >
        Email Setup
      </a> section of the Admin Guide for details.
    </p>
  </div>
  <dl
    v-if="loaded"
    class="mail-info"  
  >
    <div>
      <dt>
        Email Delivery:
      </dt>
      <dd data-automation="send-type">
        {{ sendType }}
        <span v-if="sendType === 'None'">
          - mail sending is disabled until email setup is complete
        </span>
      </dd>
    </div>
    <div v-if="isSMTP">
      <dt>
        Server Address:
      </dt>
      <dd data-automation="smtp-host">
        {{ form.host }}
      </dd>
    </div>
    <div v-if="isSMTP">
      <dt>
        Port:
      </dt>
      <dd data-automation="smtp-port">
        {{ form.port }}
      </dd>
    </div>
    <div v-if="isSMTP">
      <dt>
        Use TLS:
      </dt>
      <dd data-automation="smtp-starttls">
        {{ startTls }}
      </dd>
    </div>
    <div v-if="isSMTP && form.auth">
      <dt>
        Username:
      </dt>
      <dd data-automation="smtp-user">
        {{ form.user }}
      </dd>
    </div>
    <div v-if="isSMTP && form.auth">
      <dt>
        Password:
      </dt>
      <dd 
        data-automation="smtp-password"
        class="aside"
      >
        not shown for security
      </dd>
    </div>
  </dl> 
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
dl {
  div {
    line-height: 2rem;
    dt, dd {
      display: inline-block;
    }
    dt {
      min-width: 11rem;
      padding-right: 2rem;
      font-weight: 700;
    }
    .aside {
      font-style: italic;
      color: $color-dark-grey-2;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  .sectionTitle {
    align-self: center;
    margin: 0;
  }
  .test-email-button {
    padding: 0.4rem 1.5rem;
  }
}
.info {
  margin-bottom: 1rem;
}

.mail-info {
  margin-bottom: 4rem;
}
</style>
