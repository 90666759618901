<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <nav
    class="content-path path"
    aria-label="Breadcrumb"
  >
    <div
      v-if="isAuthenticated"
      id="breadcrumbs"
      class="content-path__breadcrumb"
    >
      <div
        class="rsc-breadcrumb"
        data-automation="rsc-breadcrumb"
      >
        <!-- TODO: should focus by default -->
        <RouterLink
          :to="{ name: 'contentList' }"
          data-automation="content-header-breadcrumb"
        >
          Content
        </RouterLink>
      </div>
    </div>

    <div
      id="currentLocation"
      class="content-path__title"
      data-automation="app-header-title"
      aria-current="page"
    >
      {{ appTitle }}
    </div>

    <ParameterizedVariantSelect
      v-if="hasVariants"
      :disabled="false"
      @change="handleVariantChange"
    />
  </nav>
</template>

<script>
import ParameterizedVariantSelect from '@/components/ParameterizedVariantSelect.vue';
import { RouterLink } from 'vue-router';
import { mapState } from 'vuex';

export default {
  name: 'ContentPath',
  components: {
    ParameterizedVariantSelect,
    RouterLink,
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      variants: state => state.parameterization.variants,
      isAuthenticated: state => state.currentUser.isAuthenticated,
    }),
    appTitle() {
      return this.app?.displayName || 'Untitled';
    },
    hasVariants() {
      return this.variants.length > 1 && !this.app?.locked;
    },
  },
  methods: {
    handleVariantChange: function(variantId) {
      this.$router.push({ params: { id: variantId } });
    },
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';
@import 'Styles/shared/_colors';
.path {
  height: 32px;
  line-height: 30px;
  min-width: 0;

  @include flex-space-after();
  align-items: baseline;

  text-align:left;
  margin-left: 10px;

  font-size: 18px;
  vertical-align: top;

  #breadcrumbs {
    display: inline-block;
    color: $color-dark-grey;
    text-transform: capitalize;
    white-space: nowrap;

    .rsc-breadcrumb {
      display: inline-block;

      &:after {
        content: '/';
        padding: 0 5px;
      }

      a {
        color: $color-primary;
        text-decoration: none;

        @include transition-property(font-size);
        @include normal-transition-duration();

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  #currentLocation {
    display: inline-block;
    border: 1px solid #fff;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -3px;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
