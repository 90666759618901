<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <p
      v-if="loading"
      data-automation="challenge-loading"
    >
      Loading captcha...
    </p>

    <div
      v-else
      class="challenge-container"
      data-automation="challenge-container"
    >
      <img
        v-if="challengeImg"
        id="captcha-image"
        data-automation="challenge-image"
        :src="`data:image/png;base64,${challengeImg}`"
        alt="Captcha Image"
      >

      <!-- eslint-disable vuejs-accessibility/media-has-caption -->
      <audio
        v-if="challengeAudio"
        data-automation="challenge-audio"
        controls
      >
        Captcha audio is unsupported on this browser.
        <source
          type="audio/wav"
          :src="`data:audio/wav;base64,${challengeAudio}`"
        >
      </audio>
      <div class="challenge-input">
        <RSInputText
          v-model="challengeValue"
          name="challenge-response"
          data-automation="challenge-response"
          autocomplete="off"
          label="Captcha Verification *"
          @change="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getCaptcha } from '@/api/authentication';
import RSInputText from '@/elements/RSInputText.vue';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { mapMutations } from 'vuex';

export default {
  name: 'LoginCaptcha',
  components: { RSInputText },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    shouldReload: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'ready',
    'input',
    'update:modelValue',
  ],
  data() {
    return {
      loading: true,
      challenge: {},
      challengeValue: this.modelValue,
      challengeId: null,
      challengeImg: null,
      challengeAudio: null,
    };
  },
  watch: {
    shouldReload(reload) {
      if (reload) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    init() {
      this.loading = true;
      return getCaptcha()
        .then(({ challengeId, challengeData = [] }) => {
          this.challengeId = challengeId;
          challengeData.forEach(({ mimeType, payload }) => {
            switch (mimeType) {
              case 'image/png':
                this.challengeImg = payload;
                break;
              case 'audio/wav':
                this.challengeAudio = payload;
                break;
              default:
            }
          });
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.loading = false;
          this.challengeValue = '';
          this.$emit('update:modelValue', '');
          this.$emit('ready', this.challengeId);
        });
    },
    onChange(newVal) {
      this.challengeValue = newVal;
      this.$emit('input', newVal);
      this.$emit('update:modelValue', newVal);
    },
  },
};
</script>

<style scoped>
.challenge-input {
  margin-top: 1.5rem;
}

#captcha-image {
  width: 100%;
}
</style>
