<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div data-automation="app-settings__info">
    <ConfirmationPanel
      :enabled="confirmationEnabled"
      :visible="confirmationVisible"
      @save="onSave"
      @discard="onDiscard"
    />
    <EmbeddedStatusMessage
      v-if="loading"
      message="Loading content information settings..."
      :show-close="false"
      type="activity"
      data-automation="loading"
    />
    <div
      v-if="loadingError"
      class="formSection"
      data-automation="loading-error"
    >
      <p>
        {{ `Error saving application settings: ${loadingError}` }}
      </p>
    </div>
    <div v-if="!loading">
      <ItemTitle
        :read-only="readOnly"
        :title="workingTitle"
        @input="updateTitle"
      />
      <div>
        <UserBadge
          :username="ownerUsername"
          :first-name="ownerFirstName"
          :last-name="ownerLastName"
          :clickable="true"
          @clicked="onUserClicked"
        />
      </div>
      <UsagePanel v-if="canViewUsage" />
      <ItemDescription
        :read-only="readOnly"
        :description="workingDescription"
        @input="updateDescription"
      />

      <ContentThumbnail
        :thumbnail="workingThumbnailURL"
        :read-only="thumbnailReadOnly"
        @input="updateThumbnail"
      />

      <GitInfo
        v-if="isGitDeployment && app"
        :app-guid="app.guid"
        :repository-enabled="workingRepositoryEnabled"
        :user-can-make-changes="!readOnly"
        @input="updateRepositoryEnabled"
      />
      <ContentInfo />
    </div>
  </div>
</template>

<script>
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import UserBadge from '@/components/UserBadge';
import {
  INFO_SETTINGS_DISCARD,
  INFO_SETTINGS_INIT,
  SAVE_INFO_SETTINGS,
} from '@/store/modules/infoSettings';
import ConfirmationPanel from '@/views/content/settings/ConfirmationPanel.vue';
import ContentInfo from '@/views/content/settings/ContentInfo';
import GitInfo from '@/views/content/settings/GitInfo';
import ItemDescription from '@/views/content/settings/ItemDescription';
import ItemTitle from '@/views/content/settings/ItemTitle';
import UsagePanel from '@/views/content/settings/UsagePanel';
import { mapActions, mapMutations, mapState } from 'vuex';
import ContentThumbnail from './ContentThumbnail.vue';

export default {
  name: 'InfoSettings',
  components: {
    ConfirmationPanel,
    ContentInfo,
    ContentThumbnail,
    EmbeddedStatusMessage,
    GitInfo,
    ItemDescription,
    ItemTitle,
    UsagePanel,
    UserBadge,
  },
  emits: ['discard'],
  data() {
    return {
      showLockConfirmationModal: false,
      dirtyDescription: false,
      dirtyThumbnail: false,
      dirtyRepositoryEnabled: false,
      dirtyTitle: false,
      thumbnailCleared: false,
      workingDescription: '',
      workingThumbnail: null,
      workingRepositoryEnabled: false,
      workingThumbnailURL: '',
      workingTitle: '',
    };
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      loadingError: state => state.infoSettings.error,
      loading: state => state.infoSettings.app.name === null,
      title: state => state.infoSettings.app.title || '',
      description: state => state.infoSettings.app.description || '',
      readOnly: state => !state.infoSettings.canEdit,
      thumbnailReadOnly: state => !state.infoSettings.canEditThumbnail,
      canViewUsage: state => state.infoSettings.canViewUsage,
      thumbnailURL: state => state.infoSettings.thumbnailURL,
      ownerUsername: state => state.infoSettings.app.ownerUsername,
      ownerFirstName: state => state.infoSettings.app.ownerFirstName,
      ownerLastName: state => state.infoSettings.app.ownerLastName,
      ownerGuid: state => state.infoSettings.app.ownerGuid,
      repositoryEnabled: state => state.infoSettings.app.git?.enabled || false,
      isGitDeployment: state => state.infoSettings.isGitDeployment,
    }),
    dirty() {
      return (
        this.dirtyTitle ||
        this.dirtyDescription ||
        this.dirtyThumbnail ||
        this.dirtyRepositoryEnabled
      );
    },
    confirmationVisible() {
      return this.dirty;
    },
    confirmationEnabled() {
      const validDescription =
        !this.dirtyDescription || this.workingDescription.length <= 4096;
      const validTitle =
        !this.dirtyTitle ||
        (this.workingTitle.length >= 3 && this.workingTitle.length <= 1024);
      return validDescription && validTitle;
    },
  },
  watch: {
    title(newValue) {
      this.workingTitle = newValue;
    },
    description(newValue) {
      this.workingDescription = newValue;
    },
    thumbnailURL(newValue) {
      this.workingThumbnailURL = newValue;
    },
    repositoryEnabled(newValue) {
      this.workingRepositoryEnabled = newValue;
    },
  },
  mounted() {
    // These are set when they first get initialized by vuex- but they need to be set on mount too
    // just in case they're already there and don't change such that `watch()` is never triggered
    this.workingTitle = this.title;
    this.workingDescription = this.description;
    // We don't set the thumbnail URL because it has its own super special lifecycle.
    this.workingRepositoryEnabled = this.repositoryEnabled;
    if (this.app) {
      this.initSettings(this.app.guid);
    }
  },
  beforeUnmount() {
    this.discardInfoSettings();
    window.URL.revokeObjectURL(this.thumbnailURL);
  },
  methods: {
    ...mapActions({
      initSettings: INFO_SETTINGS_INIT,
      saveInfoSettings: SAVE_INFO_SETTINGS
    }),
    ...mapMutations({
      discardInfoSettings: INFO_SETTINGS_DISCARD,
    }),
    onUserClicked() {
      this.$router.push({
        name: 'people.users.profile',
        params: { guid: this.ownerGuid },
      });
    },
    updateTitle(input) {
      this.dirtyTitle = true;
      this.workingTitle = input;
    },
    updateDescription(input) {
      this.dirtyDescription = true;
      this.workingDescription = input;
    },
    updateThumbnail(input) {
      const { file } = input;
      this.workingThumbnail = file;
      // Do not revoke the state thumbnail image: let vuex do that
      if (this.workingThumbnailURL !== this.thumbnailURL) {
        window.URL.revokeObjectURL(this.workingThumbnailURL);
      }
      if (file !== null) {
        this.imageCleared = false;
        this.workingThumbnailURL = window.URL.createObjectURL(file);
      } else {
        this.thumbnailCleared = true;
        this.workingThumbnailURL = '';
      }
      this.dirtyThumbnail = this.workingThumbnailURL !== this.thumbnailURL;
    },
    updateRepositoryEnabled(input) {
      this.dirtyRepositoryEnabled = true;
      this.workingRepositoryEnabled = input;
    },
    onDiscard() {
      this.dirtyTitle = false;
      this.dirtyDescription = false;
      this.dirtyThumbnail = false;
      this.dirtyRepositoryEnabled = false;
      this.thumbnailCleared = false;
      this.workingTitle = this.title;
      this.workingDescription = this.description;
      this.workingThumbnailURL = this.thumbnailURL;
      this.workingFile = null;
      this.workingRepositoryEnabled = this.repositoryEnabled;
      this.$emit('discard');
    },
    onSave() {
      this.onConfirmSave();
    },
    async onConfirmSave() {
      await this.saveInfoSettings({
        title: this.workingTitle,
        description: this.workingDescription,
        thumbnail: this.workingThumbnail,
        repositoryEnabled: this.workingRepositoryEnabled,
        thumbnailCleared : this.thumbnailCleared,
      });
      // There is no routing change when changing the name of a content, so
      // Vue Router wouldn't be able to change it.
      document.title = `${this.workingTitle} - Posit Connect`;
      this.dirtyTitle = false;
      this.dirtyDescription = false;
      this.dirtyThumbnail = false;
      this.dirtyRepositoryEnabled = false;
      this.thumbnailCleared = false;
    },
  },
};
</script>
