<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    :active="true"
    subject="Become viewer"
    @close="$emit('close')"
  >
    <template #content>
      <div>
        <p
          v-if="isAdmin"
        >
          Are you sure you want to make yourself a viewer?
          You will be removed as a collaborator.
        </p>
        <p
          v-else
        >
          Are you sure you want to make yourself a viewer?
          You won't be able to change the settings afterwards.
        </p>
      </div>
    </template>

    <template #controls>
      <RSButton
        label="OK"
        data-automation="become-viewer-warning-dialog-submit"
        @click="$emit('confirm')"
      />
    </template>
  </RSModal>
</template>

<script>
import RSButton from '@/elements/RSButton.vue';
import RSModal from '@/elements/RSModal.vue';

export default {
  name: 'BecomeViewerWarning',
  components: { RSButton, RSModal },
  props: {
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'confirm']
};
</script>
