<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup>
import { getHistoricData } from '@/api/metrics';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import { CLEAR_STATUS_MESSAGE, SET_ERROR_MESSAGE_FROM_API, SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import { localOffset } from '@/utils/timezone';
import { computed, onBeforeUnmount, reactive } from 'vue';
import { useStore } from 'vuex';
import ChartHeader from '@/views/system/metrics/ChartHeader';
import MetricsChart from '@/views/system/metrics/MetricsChart';
import TimeframeSelector from '@/views/system/metrics/TimeframeSelector';
import { getHistoricParams, getOptions, transformData } from '@/views/system/metrics/chartHelper';
import { MetricsSocket, socketEventTransformer } from '@/utils/webSocket';

const store = useStore();

const localState = reactive({
  loading: true,
  offset: localOffset(),
  timeframe: { unit: 1, range: 'days' },
  charts: {
    namedUsers: { data: { datasets: [] }, options: {} },
    shinyConnections: { data: { datasets: [] }, options: {} },
  },
  gauges: {
    namedUsers: { max: 0, value: 0 },
    shinyConnections: { max: 0, value: 0 },
  },
  socket: null,
});

const serverSettings = computed(() => store.state.server.settings);
const isMetricsEnabled = computed(() => serverSettings.value.metricsRrdEnabled);
const chartList = computed(() => 
  [
    {
      type: 'namedUsers',
      data: localState.charts.namedUsers.data,
      options: localState.charts.namedUsers.options,
      max: localState.gauges.namedUsers.max,
      value: localState.gauges.namedUsers.value,
    },
    {
      type: 'shinyConnections',
      data: localState.charts.shinyConnections.data,
      options: localState.charts.shinyConnections.options,
      max: localState.gauges.shinyConnections.max,
      value: localState.gauges.shinyConnections.value,
    },
  ]);

const populateCharts = () => {
  chartList.value.forEach(({ type }) => {
    const params = getHistoricParams(type, localState.timeframe);
    getHistoricData(params)
      .then(data => {
        localState.charts[type].options = getOptions(type, localState.timeframe);
        localState.charts[type].data = transformData(type, data);
      })
      .catch(e => store.commit(SET_ERROR_MESSAGE_FROM_API, e));
  });
};

const socketHandler = (evt) => {
  const charts = socketEventTransformer(evt, localState.timeframe, localState.charts);
  charts.forEach(({ type, data, gauge }) => {
    localState.gauges[type] = gauge;
    localState.charts[type].data = data;
  });
};

const timeframeChanged = (newTimeframe) => {
  localState.timeframe = newTimeframe;
  populateCharts();
};

if (isMetricsEnabled.value) {
  populateCharts();
  localState.socket = new MetricsSocket(
    [{ source: { type: 'license', id: 'users' } }],
    socketHandler,
    {
      onError: message => store.dispatch(SHOW_ERROR_MESSAGE, { message }),
      clearErrors: () => store.commit(CLEAR_STATUS_MESSAGE),
    },
  );
};
localState.loading = false;

onBeforeUnmount(() => {
  if (localState.socket) {
    localState.socket.close();
  }
});
</script>

<!-- Renders the user metrics view -->
<template>
  <div data-automation="metrics">
    <div class="flex">
      <h1 class="sectionTitle">
        Metrics
      </h1>
    </div>

    <EmbeddedStatusMessage
      v-if="localState.loading"
      data-automation="loading"
      :show-close="false"
      message="Please wait, loading metrics..."
      type="activity"
    />
    <div v-else>
      <div
        v-if="!isMetricsEnabled"
        data-automation="metrics-disabled"
        class="rsc-metrics__section"
      >
        <div class="sectionTitle small">
          Metrics are disabled
        </div>
      </div>
      <div v-else>
        <div class="rsc-metrics__filter-header">
          <TimeframeSelector
            class="rsc-metrics__input"
            @time-change="timeframeChanged"
          />
          <div class="rsc-metrics__info">
            <div>{{ `All times are displayed in local time (${localState.offset})` }}</div>
            <div class="rsc-metrics__info__server-name">
              {{ `Server: ${serverSettings.hostname}` }}
            </div>
          </div>
        </div>

        <div
          class="rsc-metrics__graphs"
          data-automation="metrics-charts"
        >
          <div
            v-for="(chart, index) in chartList"
            :key="index"
            :data-automation="`metrics-${chart.type}`"
            class="rsc-metrics__graph"
          >
            <ChartHeader
              :type="chart.type"
              :max="chart.max"
              :value="chart.value"
            />
            <MetricsChart
              :chart-data="chart.data"
              :chart-options="chart.options"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.rsc-metrics {
  &__input {
    margin-bottom: 0.9rem;
  }

  &__graphs {
    border-top: 1px dotted $color-medium-grey;
  }

  &__graph {
    margin-bottom: 3rem;
  }

  &__filter-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @include for-large-screens() {
      display: block;
    }
  }

  &__info {
    text-align: right;
    line-height: 1.25rem;
    
    &__server-name {
      color: $color-dark-grey;
    }

    @include for-large-screens() {
      text-align: left;
      margin-bottom: 0.9rem;
    }
  }
}
</style>
