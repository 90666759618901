<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    class="schedule-monthly"
    data-automation="schedule-monthly"
  >
    <IntervalScheduleInput
      v-model.number="schedInterval"
      :term="intervalTerm"
      :disabled="disabled"
      @change="emitChange"
    />

    <fieldset
      role="radiogroup"
      class="schedule-monthly__day-options"
    >
      <RSInputRadio 
        v-model="scheduleType"
        name="schedule-monthly-day"
        data-automation="schedule-monthly__by-n-day"
        :value="DAYOFMONTH"
        :disabled="disabled"
        class="interval-check"
      >
        <template #label>
          on the
          <input
            v-model="monthdayValue"
            type="number"
            class="schedule-monthly__nday-input"
            min="1"
            name="monthday"
            data-automation="schedule-monthly__nday-input"
            :aria-label="`on the ${monthdayValue} day of the month.`"
            :disabled="disabled"
            @input="emitChange"
          >
          {{ daySuffix }}
          day of the month
        </template>
      </RSInputRadio>
      <RSInputRadio 
        v-model="scheduleType"
        name="schedule-monthly-nth-day"
        data-automation="schedule-monthly__by-nth-weekday"
        :value="DAYWEEKOFMONTH"
        :disabled="disabled"
        class="interval-check"
      >
        <template #label>
          on the
          <RSInputSelect
            v-model="nthweekValue"
            label="Week position"
            :options="nthweekOptions"
            data-automation="schedule-monthly__nth-day-input"
            name="schedule-monthly-nthday"
            :disabled="disabled"
            :show-label="false"
            @change="emitChange"
          />
          <RSInputSelect
            v-model="weekdayValue"
            label="Week day"
            :options="weekdayOptions"
            data-automation="schedule-monthly__weekday-input"
            name="schedule-monthly-weekday"
            :disabled="disabled"
            :show-label="false"
            @change="emitChange"
          />
          of the month.
        </template>
      </RSInputRadio>
    </fieldset>
  </div>
</template>

<script>
import { DaysOfWeek, OrdinalMonthWeeks, ScheduleTypes } from '@/api/dto/schedule';
import IntervalScheduleInput from '@/components/Schedule/IntervalScheduleInput';
import RSInputRadio from '@/elements/RSInputRadio.vue';
import RSInputSelect from '@/elements/RSInputSelect.vue';
import upperFirst from 'lodash/upperFirst';

export default {
  name: 'MonthlySchedule',
  components: {
    RSInputRadio,
    RSInputSelect,
    IntervalScheduleInput,
  },
  props: {
    type: {
      type: String,
      default: ScheduleTypes.DayOfMonth,
    },
    interval: {
      type: Number,
      default: 1,
    },
    monthday: {
      type: Number,
      default: 1,
    },
    nthweek: {
      type: Number,
      default: 1,
    },
    weekday: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    const weekday = day => upperFirst(day);
    return {
      scheduleType: this.type,
      schedInterval: this.interval,
      monthdayValue: this.monthday,
      nthweekValue: OrdinalMonthWeeks.stringOf(this.nthweek),
      weekdayValue: DaysOfWeek.stringOf(this.weekday),
      nthweekOptions: OrdinalMonthWeeks.options().map(d => ({ label: d, value: d })),
      weekdayOptions: DaysOfWeek.options().map(d => ({ label: weekday(d), value: d })),
    };
  },
  computed: {
    intervalTerm() {
      return this.schedInterval > 1 ? 'months' : 'month';
    },
    daySuffix() {
      const day = this.monthdayValue;
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const relevantDigits = day < 30 ? day % 20 : day % 30;
      return relevantDigits <= 3 ? suffixes[relevantDigits] : suffixes[0];
    },
  },
  watch: {
    scheduleType(){
      this.emitChange();
    },
  },
  created() {
    this.DAYOFMONTH = ScheduleTypes.DayOfMonth;
    this.DAYWEEKOFMONTH = ScheduleTypes.DayweekOfMonth;
  },
  methods: {
    async emitChange() {
      // Wait for model values to update before firing the event
      await this.$nextTick();
      this.$emit('change', {
        type: this.scheduleType,
        interval: Number(this.schedInterval),
        nday: Number(this.monthdayValue),
        nthweek: this.nthweekValue,
        weekday: this.weekdayValue,
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.interval-check {
  margin-bottom: 0 !important;
  :deep(.rs-radio__label) {
    line-height: 0;
    input[type="radio"] {
      vertical-align: middle;
    }
    
  }
} 

.schedule-monthly__day-options {
  padding-left: 1rem;
  :deep(.rs-radio__label .rs-field) {
    display: inline-block;
    select {
      font-size: 0.8rem;
    }
  }
}

.schedule-monthly__nday-input {
  padding-right: 0.2rem;
  width: 3.8rem;
}
</style>
