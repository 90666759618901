<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script>
// directoriesTaskToOptions converts an array of directory names to an array of objects suitable to be used as Options
// in a Select component.  The root directory is re-labeled from '.' to something more descriptive to make it clear.
export const directoriesTaskToOptions = task => {
  return task.data.map(dirname => ({
    value: dirname,
    label: dirname === '.' ? '[root directory]' : dirname,
  }));
};

const RefreshContentListEvent = 'refresh-content-list';
</script>

<script setup>
import { createApplication, deployApplicationResult } from '@/api/app';
import { setApplicationRepository } from '@/api/content';
import { JobLogLine } from '@/api/dto/job';
import { safeAPIErrorMessage } from '@/api/error';
import { getBranchesResult, getSubdirectoriesResult } from '@/api/git';
import EmbeddedStatusMessage, {
  ActivityMessage,
  ErrorMessage,
} from '@/components/EmbeddedStatusMessage.vue';
import LogViewer from '@/components/LogViewer.vue';
import RSButton from '@/elements/RSButton.vue';
import RSModalForm from '@/elements/RSModalForm.vue';
import { DEPLOY_WIZARD_CLOSE } from '@/store/modules/deployWizard';
import { docsPath } from '@/utils/paths';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import BranchScanFailure from './BranchScanFailure';
import DirectoryScanFailure from './DirectoryScanFailure';
import SelectBranch from './SelectBranch';
import SelectDirectory from './SelectDirectory';
import SelectRepository from './SelectRepository';

/**
 * DeploymentWizard is the entire content deployment wizard dialog.
 *
 * +----------------+                        +---------------+
 * | enter repo URL | -> ( get branches ) -> | select branch | -> ( get dirs ) ->
 * +----------------+                        +---------------+
 *
 * +-------------+
 * | select dir  | -> ( deploy ) -> ( go to content )
 * +-------------+
 *
 * If get branches or get dirs fail, we send the user to an intermediary step that
 * informs them of the nature of the failure and lets them go back or enter manually.
 */

const props = defineProps({
  gitAvailable: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits([RefreshContentListEvent]);
/**
 * Some enums for the dialog state
 */
const ENTER_REPOSITORY_STATE = 'ENTER_REPOSITORY_STATE';
const SELECT_BRANCH_STATE = 'SELECT_BRANCH_STATE';
const SELECT_DIRECTORY_STATE = 'SELECT_DIRECTORY_STATE';
const DEPLOYING_CONTENT_STATE = 'DEPLOYING_CONTENT_STATE';
const READY_TO_OPEN_CONTENT_STATE = 'READY_TO_OPEN_CONTENT_STATE';
const BRANCH_SCANNING_FAILED_STATE = 'BRANCH_SCANNING_FAILED_STATE';
const DIRECTORY_SCANNING_FAILED_STATE = 'DIRECTORY_SCANNING_FAILED_STATE';
const DEPLOYING_CONTENT_FAILED_STATE = 'DEPLOYING_CONTENT_FAILED_STATE';
const GIT_MISCONFIGURED_STATE = 'GIT_MISCONFIGURED_STATE';

const router = useRouter();
const store = useStore();

const concatenateLogEntriesFactory = () => polledTask => {
  localState.logEntries = localState.logEntries.concat(
    polledTask.output.map(line => new JobLogLine({ line: line, isError: false }))
  );
};

const learnMoreLink = docsPath('user/git-backed/');
const userGuideLocation = docsPath('user/git-backed/');

const initialState = gitAvailable => ({
  appGuid: '',
  branchName: '',
  branchScanningError: '',
  branches: [],
  contentTitle: '',
  directories: [],
  directory: '/',
  directoryScanningError: '',
  disableInputs: false,
  logEntries: [],
  repositoryUrl: '',
  repositoryUrlValid: false,
  state: gitAvailable ? ENTER_REPOSITORY_STATE : GIT_MISCONFIGURED_STATE,
  statusMessage: '',
  statusMessageType: null,
});

const localState = reactive(initialState(props.gitAvailable));
const v$ = useVuelidate(
  {
    contentTitle: { required },
  },
  localState,
);

const active = computed(() => store.state.deployWizard.active);
const systemDisplayName = computed(() => store.state.server.settings.systemDisplayName);
const enterRepositoryState = computed(() => localState.state === ENTER_REPOSITORY_STATE);
const selectBranchState = computed(() => localState.state === SELECT_BRANCH_STATE);
const selectDirectoryState = computed(() => localState.state === SELECT_DIRECTORY_STATE);
const branchScanningFailedState = computed(() => localState.state === BRANCH_SCANNING_FAILED_STATE);
const directoryScanningFailedState = computed(
  () => localState.state === DIRECTORY_SCANNING_FAILED_STATE
);
const gitMisconfiguredState = computed(() => localState.state === GIT_MISCONFIGURED_STATE);
const showLogsView = computed(() => {
  const showLogsViewStates = [
    DEPLOYING_CONTENT_STATE,
    READY_TO_OPEN_CONTENT_STATE,
    DEPLOYING_CONTENT_FAILED_STATE,
  ];
  return showLogsViewStates.find(state => state === localState.state) !== undefined;
});
const showBackButton = computed(() => {
  const hideBackButtonStates = [
    ENTER_REPOSITORY_STATE,
    DEPLOYING_CONTENT_STATE,
    READY_TO_OPEN_CONTENT_STATE,
    GIT_MISCONFIGURED_STATE,
  ];
  return hideBackButtonStates.find(state => state === localState.state) === undefined;
});
const showNextButton = computed(() => {
  const hideNextButtonStates = [
    READY_TO_OPEN_CONTENT_STATE,
    DEPLOYING_CONTENT_STATE,
    BRANCH_SCANNING_FAILED_STATE,
    DIRECTORY_SCANNING_FAILED_STATE,
    DEPLOYING_CONTENT_FAILED_STATE,
    GIT_MISCONFIGURED_STATE,
  ];
  return hideNextButtonStates.find(state => state === localState.state) === undefined;
});
const showOpenContentButton = computed(() => {
  const showOpenContentButtonStates = [
    DEPLOYING_CONTENT_STATE, // But disabled
    READY_TO_OPEN_CONTENT_STATE,
  ];
  return showOpenContentButtonStates.find(state => state === localState.state) !== undefined;
});

const showOkButton = computed(() => localState.state === GIT_MISCONFIGURED_STATE);
const nextButtonLabel = computed(() => {
  if (localState.state === SELECT_DIRECTORY_STATE) {
    return 'Deploy Content';
  }
  return 'Next';
});

const disableOpenContentButton = computed(() => localState.state !== READY_TO_OPEN_CONTENT_STATE);
const disableNextButton = computed(() => {
  if (localState.state === ENTER_REPOSITORY_STATE) {
    return !localState.repositoryUrlValid;
  } else if (localState.state === SELECT_DIRECTORY_STATE) {
    return v$.value.contentTitle.$invalid;
  }
  return false;
});

const hasDirectoryScanningError = computed(() => !!localState.directoryScanningError);
const branchNames = computed(() =>
  localState.branches.map(branchData => ({
    value: branchData.branch,
    label: branchData.branch,
  })));

const contentTitleRequiredError = computed(
  () => {
    return !v$.value.contentTitle.required && v$.value.contentTitle.$dirty;
  }
);

const contentTitleMessage = computed(() =>
  contentTitleRequiredError.value ? 'A title is required.' : null);

const closeModal = () => store.commit(DEPLOY_WIZARD_CLOSE);

const onClose = () => {
  if (localState.state !== DEPLOYING_CONTENT_STATE) {
    resetState();
  }
  closeModal();
};

const clearStatusMessage = () => {
  localState.statusMessage = '';
  localState.statusMessageType = '';
};

const setActivityMessage = message => {
  localState.statusMessage = message;
  localState.statusMessageType = ActivityMessage;
};

const setErrorMessage = message => {
  localState.statusMessage = message;
  localState.statusMessageType = ErrorMessage;
};

const selectDefaultBranch = () => {
  // priority = ['master', 'main', 'develop']; otherwise, use the first element of this.branches
  if (localState.branches.find(({ branch }) => branch === 'master')) {
    return 'master';
  }
  if (localState.branches.find(({ branch }) => branch === 'main')) {
    return 'main';
  }
  if (localState.branches.find(({ branch }) => branch === 'develop')) {
    return 'develop';
  }
  return localState.branches[0].branch;
};

const onOpen = () => {
  closeModal();
  // this is the first time something has been deployed, so going to access is what we want
  router.push({ name: 'apps.access', params: { idOrGuid: localState.appGuid } });
};

const onBack = () => {
  const backStates = {
    ENTER_REPOSITORY_STATE,
    BRANCH_SCANNING_FAILED_STATE: ENTER_REPOSITORY_STATE,
    SELECT_BRANCH_STATE: ENTER_REPOSITORY_STATE,
    DIRECTORY_SCANNING_FAILED_STATE: SELECT_BRANCH_STATE,
    SELECT_DIRECTORY_STATE: SELECT_BRANCH_STATE,
    DEPLOYING_CONTENT_STATE: SELECT_DIRECTORY_STATE,
    DEPLOYING_CONTENT_FAILED_STATE: SELECT_DIRECTORY_STATE,
  };

  localState.state = backStates[localState.state];
};

const onNext = () => {
  switch (localState.state) {
    case ENTER_REPOSITORY_STATE: {
      setActivityMessage('Fetching branches from the repository...');

      // disable inputs while contacting server
      localState.disableInputs = true;

      return getBranchesResult(localState.repositoryUrl, function onPoll() {
        // TODO: Follow log lines
      })
        .then(successfulTask => {
          localState.branches = successfulTask.data;
          localState.branchName = selectDefaultBranch();
          localState.state = SELECT_BRANCH_STATE;
        })
        .catch(err => {
          localState.state = BRANCH_SCANNING_FAILED_STATE;
          localState.branchScanningError = err;
        })
        .finally(() => {
          clearStatusMessage();
          localState.disableInputs = false;
        });
    }
    case SELECT_BRANCH_STATE: {
      setActivityMessage('Fetching list of deployable directories...');

      // disable inputs while contacting server
      localState.disableInputs = true;

      return getSubdirectoriesResult(
        localState.repositoryUrl,
        localState.branchName,
        function onPoll() {
          // TODO: Placeholder until we have output to report here.
          // TODO: ... and a place to report the output.
        }
      )
        .then(successfulTask => {
          if (!successfulTask.data.length) {
            throw new Error('No deployable content was found.');
          }
          localState.directory = successfulTask.data[0];
          localState.directories = directoriesTaskToOptions(successfulTask);
          localState.state = SELECT_DIRECTORY_STATE;
          clearStatusMessage();
        })
        .catch(err => {
          localState.directoryScanningError = true;
          localState.state = DIRECTORY_SCANNING_FAILED_STATE;
          setErrorMessage(err.message);
        })
        .finally(() => {
          localState.disableInputs = false;
        });
    }
    case SELECT_DIRECTORY_STATE: {
      let appGuid;
      localState.state = DEPLOYING_CONTENT_STATE;
      setActivityMessage('Deploying your content.<br/>You may close this dialog at any time...');
      // FIXME: Slugify content name and append timestamp, for time being
      // FIXME: This prevents conflicts, pending https://github.com/rstudio/connect/issues/12984
      const cName = localState.contentTitle
        .replace(/[^a-zA-Z0-9\-]/g, '-')
        .concat('-')
        .concat(Date.now().toString());

      // disable inputs while contacting server
      localState.disableInputs = true;

      return createApplication({ name: cName, title: localState.contentTitle })
        .then(result => result.data)
        .then(application => {
          appGuid = application.guid;
          return setApplicationRepository(appGuid, {
            repository: localState.repositoryUrl,
            branch: localState.branchName,
            directory: localState.directory,
          });
        })
        .then(() => deployApplicationResult(appGuid, concatenateLogEntriesFactory()))
        .then(result => {
          if (result.error) {
            // A deployment task error occurred.
            // We have an appGuid, though, and can open the
            // failed content to give them an opportunity to fix it.
            localState.appGuid = appGuid;
            localState.state = READY_TO_OPEN_CONTENT_STATE;
            setErrorMessage(result.error);
          } else {
            // Successful deployment
            localState.appGuid = appGuid;
            localState.state = READY_TO_OPEN_CONTENT_STATE;
            clearStatusMessage();
          }
        })
        .catch(err => {
          // An API error occurred.
          if (appGuid !== undefined) {
            // Their deployment failed, somehow.
            // We have an appGuid, though, and can open the
            // failed content to give them an opportunity to fix it.
            localState.appGuid = appGuid;
            localState.state = READY_TO_OPEN_CONTENT_STATE;
            setErrorMessage(safeAPIErrorMessage(err));
          } else {
            // Their deployment didn't even create content yet.
            // We cannot transition to "ready to deploy".
            localState.state = DEPLOYING_CONTENT_FAILED_STATE;
            setErrorMessage(safeAPIErrorMessage(err));
          }
        })
        .finally(() => {
          localState.disableInputs = false;
          return emit(RefreshContentListEvent);
        });
    }
    case DEPLOYING_CONTENT_STATE: {
      break;
    }
  }
};
const onUpdateContentTitle = newTitle => {
  localState.contentTitle = newTitle;
  v$.value.contentTitle.$touch();
};
const resetState = () => {
  // restore original state and reset validators
  Object.assign(localState, initialState(props.gitAvailable));
  v$.value.$reset();
};
const onRepositoryURLValidityChange = valid => {
  localState.repositoryUrlValid = valid;
};
</script>

<template>
  <RSModalForm
    v-if="active"
    :active="true"
    subject="New Content from Git Repository"
    data-automation="deployment-wizard__modal"
    @close="onClose"
    @submit="onNext"
  >
    <template #content>
      <div>
        <EmbeddedStatusMessage
          v-if="localState.statusMessage"
          :message="localState.statusMessage"
          :show-close="false"
          :type="localState.statusMessageType"
          class="rs-field"
          @close="clearStatusMessage"
        />

        <SelectRepository
          v-if="enterRepositoryState"
          :disable-inputs="localState.disableInputs"
          :learn-more-link="learnMoreLink"
          :repository-url="localState.repositoryUrl"
          @update:repository-url="url => localState.repositoryUrl = url"
          @valid="onRepositoryURLValidityChange"
        />

        <BranchScanFailure
          v-if="branchScanningFailedState"
          :branch-scanning-error="localState.branchScanningError"
        />

        <SelectBranch
          v-if="selectBranchState"
          v-model="localState.branchName"
          :branch-names="branchNames"
          label="Select a branch:"
        />

        <DirectoryScanFailure
          v-if="directoryScanningFailedState"
          :has-directory-scanning-error="hasDirectoryScanningError"
          :user-guide-location="userGuideLocation"
        />

        <SelectDirectory
          v-if="selectDirectoryState"
          :content-title="localState.contentTitle"
          :content-title-message="contentTitleMessage"
          :directory="localState.directory"
          :directories="localState.directories"
          :disable-inputs="localState.disableInputs"
          @update:content-title="onUpdateContentTitle"
          @update:directory="newDirectory => localState.directory = newDirectory"
        />

        <div
          v-if="gitMisconfiguredState"
          class="rs-field"
        >
          Git support is not configured on your {{ systemDisplayName }} server. Please contact your
          system administrator for assistance.
        </div>

        <div
          v-if="showLogsView"
          class="rs-field"
        >
          <LogViewer
            :entries="localState.logEntries"
            data-automation="git-deploy-message"
          />
        </div>
      </div>
    </template>

    <template #controls>
      <div class="rsc-row">
        <RSButton
          v-if="showBackButton"
          label="Back"
          type="secondary"
          @click.prevent="onBack"
        />
        <RSButton
          v-if="showNextButton"
          :label="nextButtonLabel"
          :disabled="disableNextButton"
          data-automation="git-next-button"
          @click.prevent="onNext"
        />
      </div>
      <RSButton
        v-if="showOpenContentButton"
        label="Open Content"
        :disabled="disableOpenContentButton"
        data-automation="git-open-content"
        @click.prevent="onOpen"
      />
      <RSButton
        v-if="showOkButton"
        v-bind="{
          label: 'OK',
          buttonClass: 'default'
        }"
        label="OK"
        data-automation="git-dialog-submit"
        @click.prevent="onClose"
      />
    </template>
  </RSModalForm>
</template>

<style lang="scss" scoped>
.rsc-row {
  display: flex;

  :not(:last-child) {
    margin-right: 1rem;
  }
}

.rs-field {
  &:not(:last-child) {
    margin-bottom: 0.9rem;
  }
}
</style>
