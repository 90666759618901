<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<script setup>
import { docsPath, fullDashboardPath, joinPaths } from '@/utils/paths';
import { ref } from 'vue';

const userUrl = docsPath('user/');
const adminUrl = docsPath('admin/');
const releaseNotes = docsPath('news/');
const apiReference = docsPath('api/');
const cookbook = docsPath('cookbook/');
const apiDocsSwaggerYAML = docsPath('api/swagger.yaml');
const apiDocsSwaggerJSON = docsPath('api/swagger.json');
const whatsNew = joinPaths([fullDashboardPath, '#whatsnew']);

const userGuide = ref(null);

const focusElement = () => {
  userGuide.value.focus();
};

defineExpose({ focusElement });
</script>

<!-- Contains different href targets to visit specific product documentation -->
<template>
  <div class="layoutMenuOnPage">
    <div class="menu">
      <div class="menuItems">
        <div class="menuItemContainer">
          <h2>
            <a
              ref="userGuide"
              :href="userUrl"
              target="_blank"
              class="menuItem userGuide"
              data-automation="documentation-userguide"
            >User Guide</a>
          </h2>
          <p>
            This guide will help a user configure and deploy content to Posit Connect.
          </p>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="adminUrl"
              target="_blank"
              class="menuItem adminGuide"
              data-automation="documentation-adminguide"
            >Admin Guide</a>
          </h2>
          <p>
            This guide will help an administrator install and configure Posit Connect on a managed
            server. You will learn how to install the product on different operating systems,
            configure authentication, and monitor system resources.
          </p>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="apiReference"
              target="_blank"
              class="menuItem apiDocs"
              data-automation="documentation-apidocs"
            >Server API</a>
          </h2>
          <p>
            The Server API Reference describes the API endpoints that allow you
            to interact with Posit Connect from scripts, code, and external tools.
            A Swagger-compliant specification of the API is available in
            <a
              :href="apiDocsSwaggerYAML"
              target="_blank"
            >
              YAML
            </a>
            and
            <a
              :href="apiDocsSwaggerJSON"
              target="_blank"
            >
              JSON
            </a>.
          </p>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="cookbook"
              target="_blank"
              class="menuItem apiDocs"
              data-automation="documentation-cookbook"
            >Connect Cookbook</a>
          </h2>
          <p>
            The Cookbook has code examples to help you get started using the Python and R SDKs.
          </p>
        </div>
        <div class="menuItemContainer">
          <h2>
            <a
              :href="releaseNotes"
              target="_blank"
              class="menuItem releaseNotes"
              data-automation="documentation-releasenotes"
            >Release Notes</a>
          </h2>
          <p>
            Get all the information on the latest, and previous, releases of Posit Connect.
          </p>
        </div>

        <div class="menuItemContainer">
          <h2>
            <a
              :href="whatsNew"
              class="menuItem whatsNew"
              data-automation="documentation-whatsnew"
            >What's New</a>
          </h2>
          <p>
            Learn about newly released capabilities, features you might have missed, and other useful resources.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.layoutMenuOnPage {
  .menuItem {
    &.userGuide {
      background-image: url(/images/elements/navUserGuide.svg);
    }
    &.adminGuide {
      background-image: url(/images/elements/navAdminGuide.svg);
    }
    &.releaseNotes {
      background-image: url(/images/elements/navReleaseNotes.svg);
    }
    &.apiDocs {
      background-image: url(/images/elements/navApiDocs.svg);
    }
    &.whatsNew {
      background-image: url(/images/elements/navWhatsNew.svg);
    }
  }
}
</style>
