<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import FocusLock from 'vue-focus-lock';
import { peopleTabs, systemTabs } from '@/utils/subNav';
import { SHOW_NAV_PANEL } from '@/store/modules/navigation';
import NavPanelMenu from './NavPanelMenu.vue';

const store = useStore();
const currentUser = computed(() => store.state.currentUser.user);
const serverSettings = computed(() => store.state.server.settings);
const isAdmin = computed(() => currentUser.value.isAdmin());
const isPublisher = computed(() => currentUser.value.isPublisher());

const getSystemMenuItems = systemTabs('left');

const whatsNewMenuItem = {
  label: `What's New`,
  id: 'whatsnew',
  class: 'whatsNew',
  testId: 'left-nav-whats-new',
};

const isAuthenticated = computed(() => store.state.currentUser.isAuthenticated);
const showNotification = computed(() => store.state.whatsNew.showNotification);
const viewerHideDocs = computed(() => !currentUser.value.canPublish() &&
  serverSettings.value.hideViewerDocumentation);
const shouldShowNav = computed(() =>
  Boolean(
    isAuthenticated.value && Object.keys(serverSettings.value).length
  ));
const navNodeList = computed(() => document.querySelectorAll('.panelContents a'));

const handleKeydown = (e) => {
  // JSdom in vue-test-utils is unable to find the e.target at times which causes tests to fail.
  if (!e) { return; }
  let index = Array.prototype.indexOf.call(navNodeList.value, e.target);
  if (e.key === 'ArrowUp') {
    index--;
    if (index >= 0) {
      navNodeList.value[index].focus();
    }
  } else if (e.key === 'ArrowDown') {
    index++;
    if (index < navNodeList.value.length) {
      navNodeList.value[index].focus();
    }
  }
};
const closePanel = async() => {
  store.commit(SHOW_NAV_PANEL, false);
  setTimeout(() => {
    const menuToggler = document.querySelector('#menuToggler');
    menuToggler?.focus();
  }, 200);
};
const getPeopleMenuItems = peopleTabs('left');

const getDocumentationMenuItems = () => {
  const menuItems = [];

  if (serverSettings.value.whatsNewEnabled) {
    menuItems.push(whatsNewMenuItem);
  };

  return menuItems;
};

onMounted(() => document.addEventListener('keydown', handleKeydown(event)));
onUnmounted(() => document.removeEventListener('keydown', handleKeydown(event)));
</script>

<template>
  <div
    id="navPanel"
    class="sidePanel"
  >
    <div class="panelContents">
      <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
      <nav
        v-if="shouldShowNav"
        aria-label="Site Navigation"
        class="navMenu"
        tabindex="-1"
        @click="closePanel"
        @keydown="handleKeydown($event)"
        @keydown.esc="closePanel"
      >
        <FocusLock group="sidePanel">
          <NavPanelMenu
            title="Content"
            data-automation="left-nav-content"
            name="contentList"
          />

          <NavPanelMenu
            title="People"
            data-automation="left-nav-people"
            name="people"
            :children="getPeopleMenuItems"
          />

          <div v-if="isPublisher || isAdmin">
            <NavPanelMenu
              title="System"
              data-automation="left-nav-system"
              name="system"
              :children="getSystemMenuItems"
            />
          </div>
          <div
            v-if="!viewerHideDocs"
            :class="{ 'show-notification': showNotification }"
          >
            <NavPanelMenu
              title="Documentation"
              data-automation="left-nav-doc"
              name="help"
              :children="getDocumentationMenuItems()"
            />
          </div>
        </FocusLock>
      </nav>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

#navPanel {
  left: 0px;
  width: $nav-panel-width;
  background-color: $color-light-grey;

  .show-notification {
    position: relative;
  }

  .navMenu {
    color: $color-dark-grey-3;
    font-size: 13px;
    overflow-x: hidden;
  }

  .panelContents {
    min-height: 100%;
    margin-bottom: -$nav-panel-footer-height;
  }

  .panelContents:after {
    content: "";
    display: block;
  }

  .panelFooter, .panelContents:after {
    height: $nav-panel-footer-height;
  }

  .finePrint {
    color: $color-dark-grey-3;
    line-height: 20px;
    font-size: 11px;
    padding: 0 $side-panel-padding;
  }
}
</style>
