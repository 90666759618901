<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    class="scheduledReportsPlot"
    data-automation="scheduled-content__calendar-plot"
  >
    <div
      v-if="noItems"
      class="noContentMessage"
    >
      No scheduled content found for the next 7 days
    </div>
    <div
      v-for="(dayLabel, dayIndex) in weekView.dayLabels"
      v-else
      :key="'d'+dayIndex"
      class="dayPlot"
    >
      <div class="reportRow dateTimeHeader">
        <div class="rowHeader">
          {{ dayLabel }}
        </div>
        <div class="rowContent">
          <!-- 25 labels that represent hours 12AM-12AM (extra 12AM at end) -->
          <div
            v-for="(hourLabel, hourIndex) in weekView.hourLabels"
            :key="'h'+hourIndex"
            class="rowHour"
          >
            {{ hourLabel }}
          </div>
        </div>
      </div>

      <div class="reportRows">
        <div
          v-for="(content, contentIndex) in weekView.scheduledContentByDay[dayIndex]"
          :key="'c'+contentIndex"
          :class="{expanded: weekView.isExpanded(dayIndex, content.id)}"
          class="reportRow"
        >
          <div class="rowHeader">
            <div
              tabindex="0"
              role="button"
              class="reportTitle rsc-reportTitle"
              @click="weekView.toggleExpanded(dayIndex, content.id)"
              @keypress.enter="weekView.toggleExpanded(dayIndex, content.id)"
              @keypress.space.prevent="weekView.toggleExpanded(dayIndex, content.id)"
            >
              <div class="reportName">
                {{ content.title }}
              </div>
            </div>
            <div
              v-if="weekView.isExpanded(dayIndex, content.id)"
              class="reportDetails"
            >
              <div class="detailLabel">
                Frequency
              </div>
              <div>{{ content.frequencyDescription }}</div>
              <div class="detailLabel">
                Next scheduled run
              </div>
              <div>{{ content.nextRunDescription }}</div>
              <div class="detailLabel">
                Document type
              </div>
              <div>{{ content.documentType }}</div>
              <div class="detailLabel">
                Sends email
              </div>
              <div>{{ content.sendsEmailDescription }}</div>
              <div class="detailLabel">
                Author
              </div>
              <div>
                <RouterLink
                  :to="{ name: 'people.users.profile', params: { guid: content.authorGuid } }"
                >
                  {{ content.authorName }}
                </RouterLink>
              </div>
              <div>
                <RouterLink
                  :to="{ name: 'apps.output.variant', params: { guid: content.item.appId, id: content.item.variantId } }"
                >
                  View Content Schedule
                </RouterLink>
              </div>
            </div>
          </div>
          <div class="rowContent">
            <div
              v-for="(run, runIndex) in content.runsFor(weekView.days[dayIndex])"
              :key="'r'+runIndex"
              :style="weekView.rowStyle(run, weekView.days[dayIndex])"
              :class="weekView.rowClass(run)"
            />
            <!-- columns corresponding to hour header labels above -->
            <div
              v-for="(hourLabel, hourIndex) in weekView.hourLabels"
              :key="'h2'+hourIndex"
              class="rowHour"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: 'CalendarWeekView',
  components: {
    RouterLink
  },
  props: {
    weekView: {
      type: Object,
      required: true,
    }
  },
  computed: {
    noItems() {
      return this.weekView.items.length === 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.scheduledReportsPlot {
  .dayPlot {
    min-width: 940px;
    overflow: hidden;

    .reportRow {
      line-height: 32px;
      border-bottom: 1px solid $color-light-grey-4;
      @include flex-space-after();

      &.dateTimeHeader {
        background-color: $color-light-grey;

        .rowHeader {
          padding-left:5px;
        }
      }

      .rowHeader {
        width: 220px;
        flex-shrink: 0;
        overflow: hidden;
        white-space: nowrap;
        border-right: 1px solid $color-light-grey-4;
      }

      .rowContent {
        font-size: 10px;
        color: $color-dark-grey-3;

        width: 100%;
        position: relative;
        @include flex-space-after();

        .rowHour {
          width: 4%;
          border-right: 1px solid $color-light-grey-4;
          text-align: center;

          &:last-child {
            border-right: none;
            background-color: $color-light-grey;
          }
        }

        .scheduledRun {
          position: absolute;
          top: 11px;
          height: 10px;
          min-width: 5px;
          opacity: .70;

          border-radius: 5px;

          &.projected {
            background-color: $color-medium-dark-grey;
          }

          &.done {
            background-color: $color-posit-green;
          }

          &.error {
            background-color: $color-error;
          }

          &.running {
            background-color: $color-yellow-3;
          }

          &.multipleRuns {
            background-size: 5px 10px;
            background-image: url(/images/elements/scheduledMultipleRuns.svg);
          }
        }
      }
    }

    .reportRows {

      .reportRow {

        .rowHeader {
            font-size: 12px;

          .reportTitle {
            cursor: pointer;
            font-size: 13px;
            padding-left: 18px;

            background-image: url(/images/elements/expandCollapseDark.svg);
            background-repeat: no-repeat;
            background-position: left 6px;
            background-size: 20px 40px;

            .reportName {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &.expanded {
          .rowHeader {
            .reportTitle {
              background-position: left -14px;
            }
          }
        }
      }
    }
  }
}

.reportDetails {
  margin-left: 18px;
  line-height: 1.8em;
  color: $color-dark-grey-2;
  white-space: normal;

  div {
    margin-bottom: .5em;
  }

  .detailLabel {
    margin-bottom: 0px;
    line-height: 15px;
    font-size: 0.95em;
    color: $color-medium-dark-grey;
  }
}

.rsc-reportTitle:focus {
  @include control-focus();
  outline-offset: -3px;
}
</style>
