// Copyright (C) 2022 by Posit Software, PBC.

import AccessTypes from './accessType';
import AppModes from './appMode';
import AppRoles from './appRole';
import { Group } from './group';
import { JobTag } from './job';
import PublicContentStatuses from './publicContentStatus';
import { User } from './user';

// ContentType enumerates the kind of applications we support
export const ContentType = {
  Unknown: 'unknown',
  Application: 'application',
  Document: 'document',
  Site: 'site',
  Plot: 'plot',
  Pin: 'pin',
  Api: 'api',
  TensorFlowApi: 'tensorFlowApi',
  Proxied: 'proxied',
};

export const ContentTypeLabel = {
  [ContentType.Unknown]: 'item',
  [ContentType.Application]: 'application',
  [ContentType.Document]: 'document',
  [ContentType.Site]: 'site',
  [ContentType.Plot]: 'plot',
  [ContentType.Pin]: 'pin',
  [ContentType.Api]: 'API',
  [ContentType.TensorFlowApi]: 'TensorFlow Model API',
  [ContentType.Proxied]: 'proxied',
};

const staticContentType = (appMode, category) => {
  // book used in the past
  if (['book', 'site'].includes(category)) {
    return ContentType.Site;
  }
  if (appMode !== AppModes.Static) {
    return ContentType.Document;
  }
  switch (category) {
    case 'plot':
      return ContentType.Plot;
    case 'pin':
      return ContentType.Pin;
    default:
      return ContentType.Document;
  }
};

// App reflects the backend struct `store.FullAppRecord`.
export class App {
  // eslint-disable-next-line complexity
  constructor({
    accessType,
    amdGpuLimit = null,
    appMode,
    appRole,
    bookmarked = false,
    bundleId = null,
    clusterName = null,
    connectionTimeout = null,
    contentCategory,
    contentUrl,
    cpuLimit = null,
    cpuRequest = null,
    createdTime,
    dashboardUrl,
    defaultImageName = null,
    defaultPyEnvironmentManagement = null,
    defaultREnvironmentManagement = null,
    description,
    extension = false,
    groups = [],
    guid,
    id,
    idleTimeout = null,
    imageName = null,
    initTimeout = null,
    lastDeployedTime,
    loadFactor = null,
    locked = false,
    lockedMessage = '',
    maxConnsPerProcess = null,
    maxProcesses = null,
    memoryLimit = null,
    memoryRequest = null,
    minProcesses = null,
    name: appName,
    nvidiaGpuLimit = null,
    owner = null,
    ownerGuid,
    parameterized,
    publicContentStatus,
    pyEnvironmentManagement = null,
    pyVersion = null,
    quartoVersion = null,
    rEnvironmentManagement = null,
    rVersion = null,
    readTimeout = null,
    runAs = null,
    runAsCurrentUser,
    serviceAccountName = null,
    tags = null,
    title = null,
    users = [],
    vanityUrl = null
  } = {}) {
    this.accessType = AccessTypes.of(accessType);
    this.amdGpuLimit = amdGpuLimit;
    this.appMode = AppModes.of(appMode);
    this.appRole = AppRoles.of(appRole);
    this.bookmarked = bookmarked;
    this.bundleId = bundleId ? String(bundleId) : null;
    this.clusterName = clusterName;
    this.connectionTimeout = connectionTimeout;
    this.contentCategory = contentCategory;
    this.contentUrl = contentUrl;
    this.cpuLimit = cpuLimit;
    this.cpuRequest = cpuRequest;
    this.createdTime = new Date(createdTime);
    this.dashboardUrl = dashboardUrl;
    this.defaultImageName = defaultImageName;
    this.defaultPyEnvironmentManagement = defaultPyEnvironmentManagement;
    this.defaultREnvironmentManagement = defaultREnvironmentManagement;
    this.description = description;
    this.displayName = title || appName;
    this.extension = extension,
    this.groups = (groups || []).map(group => new Group(group));
    this.guid = guid;
    this.id = id;
    this.idleTimeout = idleTimeout;
    this.imageName = imageName;
    this.initTimeout = initTimeout;
    this.lastDeployedTime = new Date(lastDeployedTime);
    this.loadFactor = loadFactor;
    this.locked = locked;
    this.lockedMessage = lockedMessage;
    this.maxConnsPerProcess = maxConnsPerProcess;
    this.maxProcesses = maxProcesses;
    this.memoryLimit = memoryLimit;
    this.memoryRequest = memoryRequest;
    this.minProcesses = minProcesses;
    this.name = appName;
    this.nvidiaGpuLimit = nvidiaGpuLimit;
    this.owner = owner;
    this.ownerGuid = ownerGuid;
    this.parameterized = parameterized;
    this.publicContentStatus = PublicContentStatuses.of(
      publicContentStatus
    );
    this.pyEnvironmentManagement = pyEnvironmentManagement;
    this.pyVersion = pyVersion;
    this.quartoVersion = quartoVersion;
    this.rEnvironmentManagement = rEnvironmentManagement;
    this.rVersion = rVersion;
    this.readTimeout = readTimeout;
    this.runAs = runAs;
    this.runAsCurrentUser = runAsCurrentUser;
    this.serviceAccountName = serviceAccountName;
    this.tags = tags;
    this.title = title;
    this.users = (users || []).map(user => new User(user));
    this.vanityUrl = vanityUrl;
  }

  toJSON() {
    return {
      accessType: this.accessType,
      amdGpuLimit: this.amdGpuLimit,
      appMode: this.appMode,
      appRole: AppRoles.stringOf(this.appRole),
      bookmarked: false,
      bundleId: this.bundleId,
      clusterName: this.clusterName,
      connectionTimeout: this.connectionTimeout,
      contentCategory: this.contentCategory,
      contentUrl: this.contentUrl,
      cpuLimit: this.cpuLimit,
      cpuRequest: this.cpuRequest,
      createdTime: this.createdTime,
      dashboardUrl: this.dashboardUrl,
      defaultImageName: this.defaultImageName,
      defaultPyEnvironmentManagement: this.defaultPyEnvironmentManagement,
      defaultREnvironmentManagement: this.defaultREnvironmentManagement,
      description: this.description,
      extension: this.extension,
      groups: this.groups,
      guid: this.guid,
      id: this.id,
      idleTimeout: this.idleTimeout,
      imageName: this.imageName,
      initTimeout: this.initTimeout,
      lastDeployedTime: this.lastDeployedTime,
      loadFactor: this.loadFactor,
      locked: this.locked,
      lockedMessage: this.lockedMessage,
      maxConnsPerProcess: this.maxConnsPerProcess,
      maxProcesses: this.maxProcesses,
      memoryLimit: this.memoryLimit,
      memoryRequest: this.memoryRequest,
      minProcesses: this.minProcesses,
      name: this.name,
      nvidiaGpuLimit: this.nvidiaGpuLimit,
      owner: this.owner,
      ownerGuid: this.ownerGuid,
      parameterized: this.parameterized,
      publicContentStatus: this.publicContentStatus,
      pyEnvironmentManagement: this.pyEnvironmentManagement,
      pyVersion: this.pyVersion,
      quartoVersion: this.quartoVersion,
      rEnvironmentManagement: this.rEnvironmentManagement,
      rVersion: this.rVersion,
      readTimeout: this.readTimeout,
      runAs: this.runAs,
      runAsCurrentUser: this.runAsCurrentUser,
      serviceAccountName: this.serviceAccountName,
      tags: this.tags,
      title: this.title,
      users: this.users,
      vanityUrl: this.vanityUrl,
    };
  }

  toString() {
    return `App(${this.guid})`;
  }

  hasRuntimeSettings() {
    return [
      AppModes.JupyterVoila,
      AppModes.PlumberApi,
      AppModes.PythonApi,
      AppModes.PythonBokeh,
      AppModes.PythonDash,
      AppModes.PythonFastAPI,
      AppModes.PythonGradio,
      AppModes.PythonShiny,
      AppModes.PythonStreamlit,
      AppModes.Shiny,
      AppModes.ShinyQuarto,
      AppModes.ShinyRmd,
      AppModes.StaticJupyter,
      AppModes.StaticQuarto,
      AppModes.StaticRmd,
      AppModes.TensorFlowApi,
    ].includes(AppModes.of(this.appMode));
  }

  hasWorker() {
    return [
      AppModes.JupyterVoila,
      AppModes.PlumberApi,
      AppModes.PythonApi,
      AppModes.PythonBokeh,
      AppModes.PythonDash,
      AppModes.PythonFastAPI,
      AppModes.PythonGradio,
      AppModes.PythonShiny,
      AppModes.PythonStreamlit,
      AppModes.Shiny,
      AppModes.ShinyQuarto,
      AppModes.ShinyRmd,
      AppModes.TensorFlowApi,
    ].includes(AppModes.of(this.appMode));
  }

  isExecutable() {
    return [
      AppModes.JupyterVoila,
      AppModes.PlumberApi,
      AppModes.PythonApi,
      AppModes.PythonBokeh,
      AppModes.PythonDash,
      AppModes.PythonFastAPI,
      AppModes.PythonGradio,
      AppModes.PythonShiny,
      AppModes.PythonStreamlit,
      AppModes.Shiny,
      AppModes.ShinyQuarto,
      AppModes.ShinyRmd,
      AppModes.StaticJupyter,
      AppModes.StaticQuarto,
      AppModes.StaticRmd,
      AppModes.TensorFlowApi,
    ].includes(AppModes.of(this.appMode));
  }

  isRunnableAsCurrentUser() {
    return [
      AppModes.JupyterVoila,
      AppModes.PythonBokeh,
      AppModes.PythonDash,
      AppModes.PythonGradio,
      AppModes.PythonShiny,
      AppModes.PythonStreamlit,
      AppModes.Shiny,
      AppModes.ShinyQuarto,
      AppModes.ShinyRmd,
    ].includes(AppModes.of(this.appMode));
  }

  isStatic() {
    return AppModes.of(this.appMode) === AppModes.Static;
  }

  isProxied() {
    return AppModes.of(this.appMode) === AppModes.Proxied;
  }

  isRenderable() {
    return [
      AppModes.StaticJupyter,
      AppModes.StaticQuarto,
      AppModes.StaticRmd,
    ].includes(AppModes.of(this.appMode));
  }

  isDeployed() {
    return this.bundleId !== null;
  }

  isSite() {
    // NOTE: ported from Angular (we briefly allowed content category of book)
    return this.contentCategory === 'site' || this.contentCategory === 'book';
  }

  contentDisplayName() {
    if (this.displayName) {
      if (staticContentType(AppModes.of(this.appMode), this.contentCategory) === ContentType.Pin) {
        return `${this.displayName} (${this.owner?.username}/${this.name})`;
      }
      return this.displayName;
    }
    return null;
  }

  jobTag() {
    switch (AppModes.of(this.appMode)) {
      case AppModes.JupyterVoila:
      case AppModes.PythonApi:
      case AppModes.PythonBokeh:
      case AppModes.PythonDash:
      case AppModes.PythonFastAPI:
      case AppModes.PythonGradio:
      case AppModes.PythonShiny:
      case AppModes.PythonStreamlit:
      case AppModes.StaticJupyter:
        return JobTag.PythonRestoreTag;
      default:
        return JobTag.PackratRestoreTag;
    }
  }

  contentType() {
    switch (AppModes.of(this.appMode)) {
      case AppModes.Unknown:
        return ContentType.Unknown;

      case AppModes.PythonBokeh:
      case AppModes.PythonDash:
      case AppModes.PythonGradio:
      case AppModes.PythonShiny:
      case AppModes.PythonStreamlit:
      case AppModes.Shiny:
        return ContentType.Application;

      case AppModes.JupyterVoila:
      case AppModes.ShinyQuarto:
      case AppModes.ShinyRmd:
        return ContentType.Document;

      case AppModes.StaticJupyter:
      case AppModes.StaticQuarto:
      case AppModes.StaticRmd:
        return staticContentType(this.appMode, this.contentCategory);

      case AppModes.Static:
        return staticContentType(this.appMode, this.contentCategory);

      case AppModes.Proxied:
        return ContentType.Proxied;

      case AppModes.PlumberApi:
      case AppModes.PythonApi:
      case AppModes.PythonFastAPI:
        return ContentType.Api;

      case AppModes.TensorFlowApi:
        return ContentType.TensorFlowApi;

      default:
        return ContentType.Unknown;
    }
  }

  thumbnailType() {
    switch (AppModes.of(this.appMode)) {
      case AppModes.ShinyQuarto:
      case AppModes.ShinyRmd:
      case AppModes.StaticJupyter:
      case AppModes.StaticQuarto:
      case AppModes.StaticRmd:
        return 'doc';

      case AppModes.Static:
        switch (this.contentCategory) {
          case 'plot':
            return 'plot';
          case 'pin':
            return 'pin';
          default:
            return 'doc';
        }

      case AppModes.Proxied:
        return 'app';

      case AppModes.PlumberApi:
      case AppModes.PythonApi:
        return 'api';

      case AppModes.TensorFlowApi:
        return 'model';

      default:
        return 'app';
    }
  }

  /* eslint-disable complexity */
  iconClass() {
    const contentType = this.contentType();

    switch (AppModes.of(this.appMode)) {
      case AppModes.Unknown:
        return 'typeUnpublished';

      case AppModes.PythonBokeh:
      case AppModes.PythonDash:
      case AppModes.PythonGradio:
      case AppModes.PythonShiny:
      case AppModes.PythonStreamlit:
      case AppModes.Shiny:
        return 'typeApp';

      case AppModes.JupyterVoila:
      case AppModes.ShinyQuarto:
      case AppModes.ShinyRmd:
        return 'typeShinyDoc';

      case AppModes.StaticQuarto:
      case AppModes.StaticRmd: {
        if (contentType === ContentType.Site) {
          return this.parameterized ? 'typeSiteParams' : 'typeSite';
        }

        return this.parameterized ? 'typeDocParams' : 'typeDoc';
      }

      case AppModes.Static: {
        switch (contentType) {
          case ContentType.Site:
            return 'typeSite';
          case ContentType.Plot:
            return 'typePlot';
          case ContentType.Pin:
            return 'typePin';
          default:
            // static documents get the same icon as R Markdown Documents - not ideal
            return 'typeDoc';
        }
      }

      case AppModes.Proxied:
        return 'typeApp';

      case AppModes.PlumberApi:
      case AppModes.PythonFastAPI:
      case AppModes.PythonApi:
        return 'typeAPI';

      case AppModes.TensorFlowApi:
        return 'typeTensorFlow';

      case AppModes.StaticJupyter: {
        return 'typeJupyter';
      }

      default:
        return 'typeUnpublished';
    }
  }
  /* eslint-enable complexity */
}
