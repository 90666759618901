<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="rsc-metrics__graph-body">
    <div
      v-show="isLoading"
      class="rsc-metrics__graph-loading"
    >
      Please wait, loading data...
    </div>
    <div
      v-show="!isLoading"
      class="rsc-metrics__graph-chart"
    >
      <canvas
        ref="canvas"
        role="presentation"
      />
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import { markRaw } from 'vue';

export default {
  name: 'MetricsChart',
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chart: null,
  }),
  computed: {
    isLoading() {
      return this.chartData.datasets.length === 0;
    },
  },
  watch: {
    chartOptions(newOptions) {
      this.chart.options = newOptions;
      this.chart.update();
    },
    chartData(newData) {
      this.chart.data.datasets = newData.datasets;
      this.chart.update();
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeUnmount() {
    this.chart.destroy();
  },
  methods: {
    renderChart() {
      const chart = new Chart(this.$refs.canvas.getContext('2d'), {
        type: 'line',
        data: this.chartData,
        options: this.chartOptions,
      });
      this.chart = markRaw(chart);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';
@import 'Styles/shared/_colors';

.rsc-metrics__graph {
  margin-bottom: 3rem;

  &-body {
    border: 1px dotted $color-medium-grey;
  }

  &-chart {
    height: 35vh;
    min-height: 250px;
    position: relative;
  }

  &-loading {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
