<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { addTagToContent, getContent, getTagsForContent, removeTagFromContent } from '@/api/content';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import TagsCatalogSelector from '@/components/TagsCatalogSelector';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import ConfirmationPanel from './ConfirmationPanel';
import EmptyTagsMessage from './EmptyTagsMessage';

const store = useStore();

const tagsCatalog = ref(null);

const localState = reactive({
  app: null,
  frozenSelection: new Set(),
  isSavingTags: false,
  loading: true,
  noCategories: false,
  selected: new Set(),
  userCanEdit: false,
  userIsAdmin: false,
});

const currentUser = computed(() => store.state.currentUser.user);
const showLoading = computed(
  () => localState.loading || localState.isSavingTags
);
const readOnly = computed(
  () => !localState.userCanEdit || localState.isSavingTags
);
const enableConfirmation = computed(
  () => hasChanges.value && !localState.isSavingTags
);
const hasChanges = computed(() => {
  if (localState.frozenSelection.size !== localState.selected.size) {
    return true;
  }
  for (const tag of localState.frozenSelection) {
    if (!localState.selected.has(tag)) {
      return true;
    }
  }
  return false;
});

onBeforeMount(() => {
  init();
});

const setErrorMessageFromAPI = err => {
  store.commit(SET_ERROR_MESSAGE_FROM_API, err);
};

const init = async() => {
  try {
    // location.hash #/apps/{id}/tags/...
    const appId = location.hash.split('/')[2];
    const app = await getContent(appId, { include: ['tags'] });
    const assignedTags = await getTagsForContent(app.guid);

    const assignedTagsIds = assignedTags.map(tag => tag.id);
    localState.app = app;
    localState.userIsAdmin = currentUser.value?.isAdmin();
    localState.userCanEdit = currentUser.value?.canEditAppSettings(app);
    localState.selected = new Set(assignedTagsIds);
    localState.frozenSelection = new Set(assignedTagsIds);
  } catch (err) {
    setErrorMessageFromAPI(err);
  } finally {
    localState.loading = false;
  }
};

const selectionChanged = ({ target, checked, deselectedChildren }) => {
  if (checked) {
    localState.selected.add(target);
  } else {
    localState.selected.delete(target);
    deselectedChildren.forEach(tag => localState.selected.delete(tag));
  }
  // Re-assignment: Vue doesn't track deep changes on Sets.
  localState.selected = new Set(localState.selected);
};

const save = async() => {
  const savingTimeout = setTimeout(() => {
    localState.isSavingTags = true;
  }, 300);
  const apiRequests = [];
  const deletedRegistry = new Set(localState.frozenSelection);

  // Save added tags
  localState.selected.forEach(item => {
    if (!localState.frozenSelection.has(item)) {
      apiRequests.push(addTagToContent(localState.app.guid, item));
    }
    deletedRegistry.delete(item);
  });
  // Remove tags that are not selected anymore
  deletedRegistry.forEach(item => {
    apiRequests.push(removeTagFromContent(localState.app.guid, item));
  });

  // On requests submitted, sync frozen selection and reset state
  try {
    await Promise.all(apiRequests);
  } catch (err) {
    setErrorMessageFromAPI(err);
  }
  localState.frozenSelection = new Set(localState.selected);
  resetFromFrozenSelection();
  clearTimeout(savingTimeout);
  localState.isSavingTags = false;
};

const resetFromFrozenSelection = () => {
  localState.selected = new Set(localState.frozenSelection);
  tagsCatalog.value.updateCheckedRegistry(localState.selected);
};
</script>

<template>
  <div data-automation="app-settings__tags">
    <ConfirmationPanel
      :enabled="enableConfirmation"
      :visible="hasChanges"
      @save="save"
      @discard="resetFromFrozenSelection"
    />

    <EmbeddedStatusMessage
      v-if="showLoading"
      :show-close="false"
      :message="localState.isSavingTags ? 'Saving...' : 'Loading...'"
      type="activity"
    />

    <div v-if="!localState.loading">
      <EmptyTagsMessage
        v-if="localState.noCategories"
        :is-admin="userIsAdmin"
      />

      <div v-else>
        <p class="formSection infoSection">
          Tagging content makes it easier for others to find it later.
        </p>
        <TagsCatalogSelector
          ref="tagsCatalog"
          :selected="localState.selected"
          :read-only="readOnly"
          @empty="localState.noCategories = true"
          @change="selectionChanged"
        />
      </div>
    </div>
  </div>
</template>

