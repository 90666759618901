<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script lang="ts">
import { App } from '@/api/dto/app.js';

export interface ResultTab {
  id: string,
  name: string;
  items: typeof App[];
}
</script>

<script setup lang="ts">
import RSButton from '@/elements/RSButton.vue';
import RSInputSelect from '@/elements/RSInputSelect.vue';

interface Props {
  tabs: ResultTab[];
  activeTab: string;
} 

const props = defineProps<Props>();

const emit = defineEmits<(e: 'tabSelect', tabName: string) => void>();

const options = props.tabs.map(({ id, name }) => ({
  value: id,
  label: name,
}));

const onTabSelect = (tab) => {
  emit('tabSelect', tab);
};
</script>

<template>
  <div class="tab-container">
    <div
      class="tab-container-horizontal"
    >
      <div
        v-for="tab in tabs"
        :key="tab.name"
      >
        <RSButton
          :label="`${tab.name}   (${tab.items.length})`"
          :class="[ 'tab', { 'active': tab.id === activeTab } ]"
          type="link"
          @click="emit('tabSelect', tab.id)"
        />
      </div>
    </div>
    <div
      class="tab-container-drop-down"
    >
      <RSInputSelect
        :model-value="activeTab"
        label="Content List Tabs"
        name="content-list-tabs"
        :show-label="false"
        :options="options"
        @change="onTabSelect"
      />
    </div>
  </div>
</template>

<style lang='scss' scoped>
@import 'Styles/shared/colors.scss';

.tab-container {
  border-bottom: 1px solid $color-medium-grey;
  margin-top: 3rem;

  &-horizontal {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  &-drop-down {
    align-items: center;
    display: none;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
}

.tab.tab {
  align-items: center;
  border-bottom: 3px solid transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: $color-dark-grey;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  font-size: .92rem;
  margin-right: -0.5rem; 
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  text-align: center;
  white-space: nowrap;

  & :deep(span) {
    font-weight: 600;
  }

  &.active {
    border-bottom: 3px solid $color-dark-grey-3;

    * {
      color: $color-dark-grey-3;
    }
  }
}

.tab.rs-button.link {
  letter-spacing: -0.01em;
  line-height: 22px;
  transition: color 0.25s;

  &:hover {
    background-color: inherit;
    color: $color-dark-grey-3;
  }
}

  @media screen and (max-width : 880px) {
    .tab-container-horizontal {
      display: none;
    }
    .tab-container-drop-down {
      display: flex;
    }
  }
</style>

