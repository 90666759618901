// Copyright (C) 2022 by Posit Software, PBC.

import { getContentBackfill } from '@/api/app';
import AppRoles from '@/api/dto/appRole';
import { getGroup } from '@/api/groups';
import { getUser } from '@/api/users';
import { apiV1Path } from '@/utils/paths';
import ContentTypes from '@/views/content/contentList/contentType';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { App } from './dto/app';
import { getContentPermissions } from './permissions';
import { keysToCamel, keysToSnake } from './transform';

export const Filters = {
  minVisibilityAppRole(role) {
    return `filter=min_role:${encodeURIComponent(role)}`;
  },
  deployedContent() {
    return `filter=deployed:1`;
  },
  undeployedContent() {
    return `filter=deployed:0`;
  },
  ownedByUser(userGuid) {
    return `filter=account_guid:${encodeURIComponent(userGuid)}`;
  },
  contentType(type) {
    return `filter=content_type:${encodeURIComponent(type)}`;
  },
  tags(tags) {
    return tags.map(tag => `filter=${tag}`).join('&');
  },
};

function hasMinVisibilityAppRole(minVisibilityAppRole) {
  return [AppRoles.Owner, AppRoles.Editor, AppRoles.Viewer].includes(minVisibilityAppRole);
}

function hasContentType(contentType) {
  return [
    ContentTypes.Application,
    ContentTypes.Document,
    ContentTypes.Plot,
    ContentTypes.Pin,
    ContentTypes.Api,
    ContentTypes.TensorFlow,
  ].includes(contentType);
}

export const buildFilters = ({
  minVisibilityAppRole = AppRoles.None,
  deployedContent = false,
  undeployedContent = false,
  ownedByUser = '',
  contentType = ContentTypes.All,
  tags = [],
} = {}) => {
  const filters = [];
  if (hasMinVisibilityAppRole(minVisibilityAppRole)) {
    filters.push(Filters.minVisibilityAppRole(AppRoles.stringOf(minVisibilityAppRole)));
  }
  if (deployedContent) {
    filters.push(Filters.deployedContent());
  }
  if (undeployedContent) {
    filters.push(Filters.undeployedContent());
  }
  if (ownedByUser) {
    filters.push(Filters.ownedByUser(ownedByUser));
  }
  if (hasContentType(contentType)) {
    filters.push(Filters.contentType(contentType));
  }
  if (tags.length) {
    filters.push(Filters.tags(tags));
  }
  return filters;
};

export const getPublishedContentForUser = async({ userName, page, perPage }) => {
  const query = `is:published locked:false owner:${userName}`;
  return await searchContent({ query, page, perPage });
};

export function searchContent({
  query = 'locked:false',
  sortBy = undefined,
  order = undefined,
  page = undefined,
  perPage = undefined,
} = {}) {
  const params = {
    q: query,
    include: 'owner,bookmarked',
  };

  // We automatically hide any locked content for the dashboard experience.
  // If a locked filter is not in place we default to include locked:false.
  // Meaning, users have to explicitly set is:locked or locked:true to see locked content.
  // We prepend the filter to the query to avoid issues with unmatched quotes.
  const queryHasLockedFilter = query.includes(':locked') || query.includes('locked:');
  if (!queryHasLockedFilter) {
    params.q = `locked:false ${params.q}`;
  }

  if (sortBy) {
    params.sort = sortBy;
  }

  if (order) {
    params.order = order;
  }

  if (page) {
    params.pageNumber = page;
  }

  if (perPage) {
    params.pageSize = perPage;
  }

  return axios
    .get(apiV1Path(`/search/content`), { params: keysToSnake(params) })
    .then(({ data }) => {
      return {
        ...data,
        results: data.results.map(item => new App(keysToCamel(item))),
      };
    });
}

const resolvePermissions = async({ guid }) => {
  const contentPermissions = await getContentPermissions({ guid });
  const fetchDetails = {
    user: getUser,
    group: getGroup,
  };
  const permissionDetails = {
    users: [],
    groups: [],
  };

  const details = await Promise.all(contentPermissions.map(async permission =>
    await fetchDetails[permission.principalType](permission.principalGuid)));

  contentPermissions.forEach(({ principalType, role }, i) => {
    const principal = details[i];
    principal.appRole = AppRoles.of(role);

    permissionDetails[`${principalType}s`] =
          [...permissionDetails[`${principalType}s`], principal];
  });

  return permissionDetails;
};

/**
 * options that can be included in the GET content request
 */
export const ContentOptions = {
  groupsAndUsers: 'groups-users',
  owner: 'owner',
  tags: 'tags',
  vanityUrl: 'vanity_url',
};

/**
 * Get an app
 *
 * @param {string} guid - GUID of the app
 * @param {string[]} options.include - array of additional values to fetch,
 *                                     see: ContentOptions
 * 
 * @returns {Promise<App>}
 */
export const getContent = async(guid, { include, backfill = false } = {}) => {
  if (include && include.some(i => Object.values(ContentOptions).indexOf(i) === -1)) {
    return Promise.reject(new Error(`Invalid include value: ${include}`));
  }

  const includeGroupsAndUsers = include?.includes(ContentOptions.groupsAndUsers);
  const getContentIncludes = include?.filter((i) => i !== ContentOptions.groupsAndUsers);

  const config = !isEmpty(getContentIncludes) ?
    {
      params: {
        include: getContentIncludes.join(),
      }
    } : {};

  const { data } = await axios.get(apiV1Path(`content/${encodeURIComponent(guid)}`), config);

  // TODO: Remove this request when the V1 GET /content request contains all the necessary properties.
  const backfillProps = backfill ? await getContentBackfill(guid) : {};
  const app = new App(keysToCamel({ ...data, ...backfillProps }));

  if (includeGroupsAndUsers) {
    const { groups, users } = await resolvePermissions({ guid });
    app.groups = groups;
    app.users = users;
  }

  return app;
};

/**
 * Updates a content item settings.
 *
 * @param {string} contentGuid - GUID of the content
 * @param {object} updatePayload - values that need to be updated.
 * @returns {Promise<App>}
 */
export const updateContent = (contentGuid, updatePayload) => {
  return axios
    .patch(
      apiV1Path(`content/${encodeURIComponent(contentGuid)}`),
      keysToSnake(updatePayload)
    )
    .then(({ data }) => new App(keysToCamel(data)));
};

export const deleteContent = async guid =>
  axios.delete(apiV1Path(`content/${encodeURIComponent(guid)}`));

export const setApplicationRepository = (
  guid,
  { repository, branch, directory, polling = false }
) => {
  const repo = {
    repository: repository.trim(),
    branch: branch.trim(),
    directory: directory.trim(),
    polling,
  };

  return axios.put(apiV1Path(`content/${encodeURIComponent(guid)}/repository`), repo)
    .then(({ data }) => keysToCamel(data));
};

/**
 * Associate a tag with some content
 * @param {string|number} guid The guid of the content
 * @param {string|number} tagId The id of the tag to be set
 * @returns {Promise} A Promise that resolves when the tag is added to the content
 */
export const addTagToContent = (guid, tagId) => {
  const url = apiV1Path(`content/${guid}/tags`);
  const payload = keysToSnake({ tagId });
  return axios.post(url, payload);
};

/**
 * Get tags for a content item
 * @param {string|number} guid The guid of the content to retrieve the related tags
 * @returns {Promise} A Promise that resolves with the app's tags
 */
export const  getTagsForContent = (guid) => {
  const url = apiV1Path(`content/${guid}/tags`);
  return axios.get(url).then(response => {
    return keysToCamel(response.data);
  });
};

/**
 * Remove a tag from a content item.
 * @param {string|number} guid The guid of the content
 * @param {string|number} tagId The id of the tag to be removed from the app
 * @returns {Promise} A Promise that resolves when the tag is removed from the app
 */
export const  removeTagFromContent = (guid, tagId) =>{
  const url = apiV1Path(`content/${guid}/tags/${tagId}`);
  return axios.delete(url);
};

