// Copyright (C) 2024 by Posit Software, PBC.

import RSSParser from 'rss-parser/dist/rss-parser.min.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import UserRoles from '@/api/dto/userRole';

dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

const url = 'https://posit-dev.github.io/connect-news-feed/index.xml';
const parser = new RSSParser({
  customFields: {
    item: [
      'thumbnail',
      'themes',
      'products',
      'minimumConnectVersion',
      'connectUserRoles'
    ],
  }
});

export async function getConnectFeed() {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('HTTP Error');
    } else {
      const text = await response.text();
      return parseFeedString(text);
    }
  } catch (error) {
    throw new Error(`Fetch error: ${error.response}`);
  }
};

const parseFeedString = (data) => {
  return new Promise((resolve, reject) => {
    parser.parseString(data, (err, parsed) => {
      if (err) {
        reject(new Error(`Parsing error: ${err.response}`));
      } else {
        resolve(parsed.items);
      }
    });
  });
};

export const filterConnectFeed = (items, userRole) => {
  const localTime = dayjs.utc();
  const currentUserRole = UserRoles.stringOf(userRole);
  return items
    .filter((item) =>
      // Filters out items with a future date
      dayjs(item.pubDate).isSameOrBefore(localTime) &&
      // Filters by current user's role
      item.connectUserRoles.role
        .some((r) => r === currentUserRole));
};
