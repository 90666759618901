<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
const emit = defineEmits(['sourceVersions']);
</script>

<template>
  <button
    type="button"
    aria-label="Source Versions"
    title="Source Versions"
    class="action sourceVersions content-actions__source-versions-button"
    data-automation="menuitem-source-versions--dropdown"
    @click="emit('sourceVersions')"
  >
    <span class="actionTitle">Source Versions</span>
  </button>
</template>
