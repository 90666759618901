// Copyright (C) 2022 by Posit Software, PBC.

import AppRoles from './appRole';
import UserRoles from './userRole';

const EMAIL_REGEXP = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.[a-z]{2,}|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

// User reflects the backend struct `users.OutputDTO`.
export class User {
  constructor({
    activeTime,
    confirmed,
    createdTime,
    email,
    firstName,
    guid,
    lastName,
    locked,
    user,
    updatedTime,
    userRole,
    username,
    tempTicket,
    preferences,
    appRole,
  } = {}) {
    if (user) {
      this.email = user.email;
      this.firstName = user.firstName;
      this.guid = user.guid;
      this.lastName = user.lastName;
      this.locked = user.locked;
      this.username = user.username;
    } else {
      this.email = email;
      this.firstName = firstName;
      this.guid = guid;
      this.lastName = lastName;
      this.locked = locked;
      this.username = username;
    }

    this.activeTime = activeTime === null ? null : new Date(activeTime);
    this.confirmed = confirmed;
    this.createdTime = new Date(createdTime);
    this.updatedTime = updatedTime === null ? null : new Date(updatedTime);
    this.userRole = UserRoles.of(userRole);
    this.tempTicket = tempTicket;
    this.preferences = preferences;

    // appRole comes from `store.UserAppPrincipal`
    if (appRole) {
      this.appRole = AppRoles.of(appRole);
    }

    // calculated fields

    this.fullName = `${this.firstName} ${this.lastName}`.trim();

    this.displayInitials = (() => {
      let initials = '';
      if (this.firstName) {
        initials += String.fromCodePoint(
          this.firstName.codePointAt(0)
        ).toUpperCase();
        if (this.lastName) {
          initials += String.fromCodePoint(
            this.lastName.codePointAt(0)
          ).toUpperCase();
        }
      } else if (this.username) {
        initials += String.fromCodePoint(this.username.codePointAt(0)).toUpperCase();
      } else {
        initials += '?';
      }
      return initials;
    })();

    // any account statuses the user might have
    this.displayStatuses = this.locked ? 'locked' : null;

    // name of the user to display
    this.displayName =
      this.fullName || username || '[Undisclosed]';
  }

  // Ensures serialization via `JSON.stringify` returns the underlying data.
  toJSON() {
    const data = {
      activeTime: this.activeTime,
      confirmed: this.confirmed,
      createdTime: this.createdTime,
      email: this.email,
      firstName: this.firstName,
      guid: this.guid,
      lastName: this.lastName,
      locked: this.locked,
      updatedTime: this.updatedTime,
      userRole: UserRoles.stringOf(this.userRole),
      username: this.username,
      tempTicket: this.tempTicket,
      preferences: this.preferences,
    };
    if (this.appRole) {
      data.appRole = AppRoles.stringOf(this.appRole);
    }
    return data;
  }

  toString() {
    return `User(${this.username})`;
  }

  isViewer() {
    return this.userRole === UserRoles.Viewer;
  }

  isPublisher() {
    return this.userRole === UserRoles.Publisher;
  }

  isAdmin() {
    return this.userRole === UserRoles.Admin;
  }

  isAnonymous() {
    return this.userRole === UserRoles.Anonymous;
  }

  isValidUsername() {
    return this.username && this.username.trim().length > 0;
  }

  isValidEmail() {
    return EMAIL_REGEXP.test(this.email);
  }

  isValidProfile(authentication) {
    const incompleteProfiles = this.getInvalidUserProfileProperties(authentication);
    return incompleteProfiles.length === 0;
  }

  getInvalidUserProfileProperties(authentication) {
    const checks = {
      username: () => {
        return this.isValidUsername();
      },
      email: () => {
      // this only works for "me" or if the current user is an admin due
      // to email scrubbing.
        return this.isValidEmail();
      },
    };
    const incompleteProfiles = [];
    for (const _name in checks) {
      if (checks.hasOwnProperty(_name)) {
        if (!checks[_name]()) {
          if (
            authentication[`${_name }EditableBy`] === 'adminandself' ||
          (this.userRole._value === 'administrator' &&
            authentication[`${_name }EditableBy`] === 'admin')
          ) {
            incompleteProfiles.push(_name);
          }
        }
      }
    }
    return incompleteProfiles;
  }

  isIncomplete(authentication) {
    return !this.isValidProfile(authentication);
  }

  isUnconfirmed() {
    return this.confirmed !== true;
  }
}

// CurrentUser reflects the backend struct `users.UserWithPrivileges`
export class CurrentUser extends User {
  constructor({
    activeTime,
    confirmed,
    createdTime,
    email,
    firstName,
    guid,
    lastName,
    locked,
    updatedTime,
    userRole,
    username,
    tempTicket,
    preferences,
    privileges,
  } = {}) {
    super({
      activeTime,
      confirmed,
      createdTime,
      email,
      firstName,
      guid,
      lastName,
      locked,
      updatedTime,
      userRole,
      username,
      tempTicket,
      preferences,
    });
    this.privileges = privileges;
  }

  // Ensures serialization via `JSON.stringify` returns the underlying data.
  toJSON() {
    return { ...super.toJSON(), privileges: this.privileges };
  }

  toString() {
    return `CurrentUser(${this.username})`;
  }

  // Does this user own the app?
  isAppOwner(app) {
    return this.guid === app.ownerGuid;
  }

  // Can this user edit the app?
  isAppEditor(app) {
    if (this.isAppOwner(app) && this.userRole >= UserRoles.Publisher) {
      // owners can edit their apps unless their account has been downgraded
      return true;
    }

    if (AppRoles.isCollaborator(app.appRole)) {
      // the server indicates this user's role with respect to this app
      return true;
    }

    return false;
  }

  // Can this user view the app?
  canViewApp(app) {
    if (this.isAppEditor(app) || AppRoles.isViewer(app.appRole)) {
      return true;
    }
    return false;
  }

  canPublish() {
    return this.privileges.includes(Privileges.PublishApps);
  }

  // Can this user edit vanity URL for this app?
  canAddVanities() {
    if (this.privileges.includes(Privileges.CreateVanities)) {
      // this user has explicit permission to change vanity path in the access panel
      return true;
    }

    return false;
  }
  // Can this user edit app settings?
  canEditAppSettings(app) {
    if (this.privileges.includes(Privileges.ChangeApps)) {
      // this user has explicit permission to change apps
      return true;
    }

    return this.isAppEditor(app);
  }

  canDeleteApp(app) {
    if (this.privileges.includes(Privileges.RemoveApps)) {
      return true;
    }
    return this.isAppOwner(app) && this.userRole >= UserRoles.Publisher;
  }

  canViewAppSettings(app) {
    if (this.privileges.includes(Privileges.ViewAppSettings)) {
      return true;
    }
    return this.canEditAppSettings(app);
  }

  // Can this user edit permissions for this app? (e.g. add a viewer)
  canEditAppPermissions(app) {
    if (this.privileges.includes(Privileges.ChangeAppPermissions)) {
      // this user has explicit permission to change app permissions
      return true;
    }

    return this.isAppEditor(app);
  }

  // Can this user set or edit schedule for the app variant?
  canScheduleVariant(app) {
    if (this.privileges.includes(Privileges.ChangeVariantSchedule)) {
      // this user has explicit permission schedule for this app variant
      return true;
    }
    return this.isAppEditor(app);
  }

  // Can this user add users to a group?
  canAddGroupMember(group, serverSettings) {
    if (serverSettings.authentication.externalGroupMembers) {
      // group members are managed outside Connect
      return false;
    }

    if (group.ownerGuid && group.ownerGuid === this.guid) {
      // user owns group
      return true;
    }

    if (this.privileges.includes(Privileges.ChangeGroups)) {
      // this user has explicit permission to add member to the group
      return true;
    }

    return false;
  }

  // Can this user create groups
  canCreateGroup(serverSettings) {
    const { groupsEnabled, externalGroupSearch, externalGroupId } = serverSettings.authentication;
    if (!groupsEnabled || externalGroupSearch || externalGroupId) {
      return false;
    }
    return this.privileges.includes(Privileges.CreateGroups);
  }

  // Can this user import/search remote groups (external auth e.g LDAP)
  canAddRemoteGroup(serverSettings) {
    const { groupsEnabled, externalGroupSearch } = serverSettings.authentication;
    if (!groupsEnabled || !externalGroupSearch) {
      return false;
    }
    return this.privileges.includes(Privileges.CreateGroups);
  }

  // Can this user edit the group?
  canEditGroup(group, serverSettings) {
    const { groupsEnabled, externalGroupSearch } = serverSettings.authentication;
    if (!groupsEnabled || externalGroupSearch) {
      return false;
    }

    if (group.ownerGuid === this.guid) {
      // user owns the group
      return true;
    }

    // this user has explicit permission to remove the group
    return this.privileges.includes(Privileges.ChangeGroups);
  }

  // Can this user delete the group?
  canDeleteGroup(group) {
    if (group.ownerGuid === this.guid) {
      // user owns the group
      return true;
    }

    // this user has explicit permission to remove the group
    return this.privileges.includes(Privileges.RemoveGroups);
  }

  // Can this user delete a member from a group?
  canDeleteGroupMember(group, serverSettings, user) {
    if (serverSettings.authentication.externalGroupMembers) {
      // group members are managed outside Connect
      return false;
    }

    if (group.ownerGuid === this.guid) {
      // user owns the group
      return true;
    }

    if (this.privileges.includes(Privileges.ChangeGroups)) {
      // this user has explicit permission to modify groups
      return true;
    }

    if (this.guid === user.guid) {
      // can remove self from group
      return true;
    }

    return false;
  }

  // Can this user add a new user?
  canAddNewUser(serverSettings) {
    if (serverSettings.authentication.externalUserData) {
      return false;
    }
    // this user has explicit permission to add users?
    return this.privileges.includes(Privileges.AddUsers);
  }

  // Can this user import/search remote users (external auth e.g LDAP)
  canAddRemoteUser(serverSettings) {
    if (!serverSettings.authentication.externalUserSearch) {
      return false;
    }
    // this user has explicit permission to add users
    return this.privileges.includes(Privileges.AddUsers);
  }

  // Can this user lock or unlock another user?
  canLockOrUnlockUser(user) {
    if (this.guid === user.guid) {
      // You can never lock yourself
      return false;
    }

    if (this.privileges.includes(Privileges.LockUsers)) {
      // this user has explicit permission to lock or unlock users
      return true;
    }

    return false;
  }

  canResetPassword(serverSettings, user) {
    if (this.userRole !== UserRoles.Admin) {
      // not an admin
      return false;
    }

    if (serverSettings.authentication.externalUserData) {
      // controlled externally by authentication provider
      return false;
    }

    if (!user.confirmed) {
      // unconfirmed users should not be able to change password
      return false;
    }

    // can only reset password for other accounts
    // note: The implication of this is that an admin cannot reset their own
    // password when signed in. They must use the Change Password feature.
    return this.guid !== user.guid;
  }

  canConfirmAccount(serverSettings, user) {
    if (this.userRole !== UserRoles.Admin) {
      // not an admin
      return false;
    }

    if (serverSettings.authentication.externalUserData) {
      // controlled externally by authentication provider
      return false;
    }

    return !user.confirmed;
  }

  canChangePassword(serverSettings, user) {
    if (serverSettings.authentication.externalUserData) {
      // controlled externally by authentication provider
      return false;
    }

    // own account
    return this.guid === user.guid;
  }

  canEditUser(user) {
    if (this.guid === user.guid) {
      // can edit own profile (authentication provider determines which fields are editable)
      return true;
    }

    // can edit profile if privilege allows and current user role >= target user role
    return (
      this.privileges.includes(Privileges.ChangeUsers) &&
      this.userRole >= user.userRole
    );
  }
}

// Privileges reflects the backend `AuthPrivilege` type
export const Privileges = {
  AddUsers: 'add_users',
  ChangeAppPermissions: 'change_app_permissions',
  ChangeApps: 'change_apps',
  ChangeGroups: 'change_groups',
  ChangeUsernames: 'change_usernames',
  ChangeUsers: 'change_users',
  ChangeVariantSchedule: 'change_variant_schedule',
  ChangeVariants: 'change_variants',
  CreateGroups: 'create_groups',
  CreateVanities: 'add_vanities',
  DeployBundles: 'deploy_bundles',
  EditRunAs: 'edit_run_as',
  EditRuntime: 'edit_runtime',
  EmailReport: 'email_report',
  ListBundles: 'list_bundles',
  LockUsers: 'lock_users',
  ManageBundles: 'manage_bundles',
  PublishApps: 'publish_apps',
  RemoveApps: 'remove_apps',
  RemoveGroups: 'remove_groups',
  RemoveUsers: 'remove_users',
  RemoveVanities: 'remove_vanities',
  UploadBundles: 'upload_bundles',
  ViewAppSettings: 'view_app_settings',
  ViewApps: 'view_apps',
};
