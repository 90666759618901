<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script>
export const FileType = {
  IMAGE: 'image',
  BUNDLE: 'bundle',
};
</script>

<script setup>
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import RSButton from '@/elements/RSButton.vue';
import { humanizeBytesDecimal } from '@/utils/bytes.filter';
import actionDelete from 'Images/elements/actionDelete.svg';
import placeholderImage from 'Images/image-placeholder.svg';
import { computed, onBeforeUpdate, ref } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  fileType: { type: String, required: true },
  readOnly: { type: Boolean, required: false, default: false },
  showImagePreview: { type: Boolean, required: false, default: true },
  thumbnail: { type: String, required: false, default: null },
});
const emit = defineEmits(['input']);
const store = useStore();

const maxImageSize = computed(
  () => store.state.server.settings.maximumAppImageSize
);
const isPlaceholderImage = computed(
  () => imagePreview.value === placeholderImage
);
const dragAndDropText = computed(() => {
  if (fileName.value) {
    return fileName.value;
  }

  if (props.fileType === FileType.BUNDLE) {
    return 'Drag and drop a bundle or click to upload.';
  }

  return 'Drag and drop an image or click to upload.';
});

const dropZone = ref(null);
const errorMessage = ref(null);
const fileInput = ref(null);
const fileName = ref(null);
const imagePreview = ref(placeholderImage);
const isDraggingOver = ref(false);

const ACCEPTED_FILE_TYPES = {
  [FileType.IMAGE]: ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml'],
  [FileType.BUNDLE]: ['application/gzip', 'application/x-gzip'],
};
const validFileExtensions = {
  [FileType.IMAGE]: '.gif, .jpg, .jpeg, .png, .svg',
  [FileType.BUNDLE]: '.tar.gz, .tgz',
};

onBeforeUpdate(() => {
  if (props.thumbnail) {
    imagePreview.value = props.thumbnail;
    return;
  }
  imagePreview.value = placeholderImage;
});

const onFileSelect = e => {
  const files = e.target.files || e.dataTransfer.files;
  if (!files.length) {
    return;
  }

  const [file] = files;
  if (!ACCEPTED_FILE_TYPES[props.fileType].find(type => type === file.type)) {
    const fileType = file.type ? `"${file.type.replace(/.*\//, '')}"` : 'That';
    errorMessage.value = `${fileType} is not a supported file type.<br>
      You may upload the following types: ${validFileExtensions[props.fileType]}.`;
    fileInput.value = fileInput.value.defaultValue;
    return;
  }

  if (props.fileType === FileType.IMAGE && file.size > maxImageSize.value) {
    errorMessage.value = `That image is larger than the maximum size of ${humanizeBytesDecimal(
      maxImageSize.value
    )}.`;
    fileInput.value = fileInput.value.defaultValue;
    return;
  }

  errorMessage.value = null;
  createImage(file);
  emit('input', { file });
};

const onFileDrop = e => {
  if (props.readOnly) {
    return;
  }

  isDraggingOver.value = false;
  onFileSelect(e);
};

const triggerFileInput = () => {
  fileInput.value.click();
};

const createImage = file => {
  fileName.value = file.name;
  const reader = new FileReader();
  reader.onload = e => {
    imagePreview.value = e.target.result;
  };
  reader.readAsDataURL(file);
};

const onResetImage = () => {
  fileName.value = null;
  fileInput.value.value = '';
  emit('input', { file: null });
};
</script>

<template>
  <div class="image-file-input-container">
    <EmbeddedStatusMessage
      v-if="!!errorMessage"
      :message="errorMessage"
      :show-close="true"
      type="error"
      data-automation="imageError"
      @close="errorMessage = null"
    />
    <div
      ref="dropZone"
      :class="[
        'file-upload-container',
        { 'is-dragging-over': isDraggingOver, 'read-only': readOnly }
      ]"
      role="button"
      tabindex="0"
      aria-label="File upload area"
      @click.stop="triggerFileInput"
      @dragover.prevent="isDraggingOver = !readOnly"
      @dragleave="isDraggingOver = false"
      @drop.prevent="onFileDrop"
      @keydown.enter.prevent="triggerFileInput"
    >
      <div class="image-preview-container">
        <div class="image-container">
          <img
            v-if="showImagePreview"
            alt="Image preview"
            data-automation="image-preview"
            :class="['image-preview', { 'is-placeholder': isPlaceholderImage }]"
            :src="imagePreview"
          >
          <RSButton
            v-if="!isPlaceholderImage && !readOnly"
            data-automation="clear-image-button"
            label="Clear image"
            size="small"
            type="link"
            :icon="actionDelete"
            @click.stop="onResetImage"
            @keydown.enter.stop="onResetImage"
          />
        </div>

        <div class="image-help-container">
          <label
            v-if="!readOnly || fileType === FileType.BUNDLE"
            class="image-label"
            for="fileInput"
            @click.stop
          >
            {{ dragAndDropText }}
          </label>
          <label
            v-else
            class="image-label"
            for="fileInput"
            @click.stop
          >
            Content Thumbnail
          </label>
        </div>
      </div>
      <input
        id="fileInput"
        ref="fileInput"
        aria-label="Upload image"
        class="file-input"
        data-automation="image-file-input"
        style="display: none"
        type="file"
        :accept="validFileExtensions[props.fileType]"
        :disabled="readOnly"
        @change="onFileSelect"
      >
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.statusMessage.error {
  margin-bottom: 1rem;
}

.file-upload-container {
  border: 3px dashed $color-medium-grey;
  border-radius: 3px;

  padding: 0.5rem;
  text-align: center;
  cursor: pointer;

  &.is-dragging-over {
    border: 4px solid $color-selected;
  }

  &.read-only {
    cursor: not-allowed;
    border: 3px solid $color-medium-grey;
  }

  .file-input {
    font-size: 0.9rem;
    font-weight: 600;
  }
}

.image-preview-container {
  display: flex;

  .image-container {
    display: flex;
    flex-direction: column;

    .image-preview {
      border-radius: 5px;
      height: 100px;
      object-fit: contain;
      width: 100px;

      &.is-placeholder {
        opacity: 0.6;
        width: 75px;
      }
    }

    .rs-button {
      margin-top: 0.5rem;
      padding: 2px;
    }
  }

  .image-help-container {
    align-items: center;
    display: flex;
    margin-left: 10px;
    width: 100%;

    .image-label {
      cursor: pointer;
      font-size: 14px;
      margin-top: 0.5rem;
      padding-left: 0.5rem;
      text-align: left;
      width: 100%;
    }
  }
}
</style>
