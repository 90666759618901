// Copyright (C) 2022 by Posit Software, PBC.

import { apiPath, apiV1Path, serverPath } from '@/utils/paths';
import axios from 'axios';
import { createBundle } from './bundle';
import { App } from './dto/app';
import { taskToPromise } from './tasks';
import { keysToCamel, keysToSnake } from './transform';

/**
 * Get an app
 *
 * @deprecated use #getAppBy instead
 * @param {string | number} appId - ID of the app
 * @returns {Promise<App>}
 */
export function getApp(appId) {
  return axios
    .get(apiPath(`applications/${encodeURIComponent(appId)}`))
    .then(({ data }) => new App(keysToCamel(data)));
}

/**
 * Get an app
 *
 * @param {string} guid - GUID of the app
 * @returns {Promise<App>}
 */
export function getAppBy(guid) {
  return axios
    .get(apiV1Path(`content/${encodeURIComponent(guid)}`))
    .then(({ data }) => {
      return App.fromV1(keysToCamel(data));
    });
}

/**
 * Return the URL to the content thumbnail. Returns undefined when the content
 * has no thumbnail (or the thumbnail is not visible to the user).
 * @param {string} contentGuid - GUID of the content
 * @returns {Promise<AxiosResponse<Blob>>}
 */
export function getContentThumbnail(contentGuid) {
  const url = serverPath(`content/${contentGuid}/__thumbnail__`);
  return axios.get(url, { responseType: 'blob' })
    .then(({ data }) => data);
}

export function setContentThumbnail(contentGuid, thumbnailPayload) {
  return axios.put(
    apiV1Path(`content/${encodeURIComponent(contentGuid)}/thumbnail`),
    thumbnailPayload
  );
}

export function deleteContentThumbnail(appGuid) {
  return axios.delete(
    apiV1Path(`content/${encodeURIComponent(appGuid)}/thumbnail`)
  );
}

/**
 * Get an app's environment vars
 * @param {string} guid - GUID for the app
 * @returns {Promise} A Promise that resolves with an array containing the names of the assigned environment variables.
 */
export function getAppVars(guid) {
  const url = apiV1Path(`content/${guid}/environment`);
  return axios.get(url).then(({ data }) => data);
}

/**
 * Create/Update/Remove an app's environment var
 * @param {string} guid The id of the app to create/update/remove the var
 * @param {object} values The values obj to create/update/remove the new var
 * @returns {Promise} A Promise that resolves
 */
export function updateAppVars(guid, values) {
  const url = apiV1Path(`content/${guid}/environment`);
  return axios.patch(url, values);
}

/**
 * createApplication creates a new application record but does not deploy it
 * @param {string} appName application `name` field, must be a slug of some kind
 * @param {string} appTitle title field, free form text
 * @returns {AxiosPromise<any>}
 */
export function createApplication(appData = {}) {
  if (appData.name === undefined) {
    throw new Error('createApplication: name is required');
  }

  return axios.post(apiV1Path('content'), appData);
}

/**
 * deployApplication deploys an application. An appId is required.
 * A bundleId is required when the application is not git-backed.
 * @param {number} appId the application ID
 * @param {number?} bundleId (optional) the bundle ID
 * @returns {AxiosPromise<any>}
 */
export function deployApplication(appId, bundleId = undefined) {
  let data = {};
  if (bundleId !== undefined) {
    data = { bundleId };
  }
  return axios.post(
    apiV1Path(`content/${encodeURIComponent(appId)}/deploy`),
    keysToSnake(data)
  );
}

export function deployApplicationResult(appId, onPoll = () => { }) {
  return deployApplication(appId)
    .then(task => taskToPromise(task.data.task_id, onPoll));
}

export const addExtension = async(bundle) => {
  const appName = `extension-${Date.now()}`;

  const { data: app } = await createApplication({ name: appName, extension: true });
  const createdBundle = await createBundle(app.guid, bundle);
  const { data: deployedResponse } = await deployApplication(app.guid, createdBundle.id);

  return { guid: app.guid, taskId: deployedResponse.task_id };
};

/**
 * Add a user principal to an app's ACL. (Also used to update the user's role.)
 *
 * @param {string | number} appId - ID of the app
 * @param {string} guid - GUID of the user to add/update
 * @param {string} appRole - the access role the user should have for the app
 * @returns {Promise} an empty Promise that revolves when the user is added
 */
export function addUser(appId, guid, appRole) {
  return axios
    .post(
      apiPath(`applications/${encodeURIComponent(appId)}/users`),
      keysToSnake({ guid, appRole }),
    );
}

/**
 * Add a group principal to an app's ACL. (Also used to update the group's role.)
 *
 * @param {string | number} appId - ID of the app
 * @param {string} guid - GUID of the group to add/update
 * @param {string} appRole - the access role the group should have for the app
 * @returns {Promise} an empty Promise that revolves when the group is added
 */
export function addGroup(appId, guid, appRole) {
  return axios
    .post(
      apiPath(`applications/${encodeURIComponent(appId)}/groups`),
      keysToSnake({ guid, appRole }),
    );
}

/**
 * Remove a user's access to an app (remove them from the ACL.)
 *
 * @param {string | number} appId - ID of the app
 * @param {string} userGuid - GUID of the user to remove
 * @returns {Promise} an empty Promise that resolves when the user is removed
 */
export function removeUser(appId, userGuid) {
  return axios
    .delete(apiPath(`applications/${encodeURIComponent(appId)}/users/${encodeURIComponent(userGuid)}`));
}

/**
 * Remove a group's access to an app (remove them from the ACL.)
 *
 * @param {string | number} appId - ID of the app
 * @param {string} groupGuid - GUID of the group to remove
 * @returns {Promise} an empty Promise that revolves when the group is removed
 */
export function removeGroup(appId, groupGuid) {
  return axios
    .delete(apiPath(`applications/${encodeURIComponent(appId)}/groups/${encodeURIComponent(groupGuid)}`));
}
