<!-- Copyright (C) 2023 by Posit Software, PBC. -->
<script setup>
import RSButton from '@/elements/RSButton.vue';
import iconExpanded from 'Images/iconExpanded.svg';
import { computed } from 'vue';

const { title, hideIcon, isExpanded } = defineProps({
  title: { type: String, default: null },
  hideHeader: { type: Boolean, default: false },
  hideIcon: { type: Boolean, default: false },
  isExpanded: { type: Boolean, default: false },
});

const emit = defineEmits(['toggle']);

const expanderLabel = computed(() => isExpanded.value ? 'Collapse' : 'Expand');
</script>

<template>
  <div :class="['collapsible-container', { expanded: isExpanded }]">
    <div
      v-if="!hideHeader"
      class="header"
    >
      <h3
        v-if="title"
        class="title"
      >
        {{ title }}
      </h3>

      <slot />

      <RSButton
        v-if="!hideIcon"
        :icon="iconExpanded"
        :icon-only="true"
        :label="expanderLabel"
        :class="['collapseButton', { expanded: isExpanded }]"
        type="link"
        @click="emit('toggle')"
      />
    </div>

    <div
      :class="['expander', { expanded: isExpanded }]"
      data-automation="expanding-panel"
    >
      <div class="content">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  align-items: center;
  display: flex;
  flex: 1;

  .title {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 600;
  }

  .collapseButton {
    margin-left: 1em;
    height: 20px;
    width: 20px;
    padding: 0;
    transition: transform 0.3s ease-out;
    transform: rotate(90deg);

    &.expanded {
      transform: rotate(0deg);
    }
  }
}

.expander {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-out;

  &.expanded {
    grid-template-rows: 1fr;
  }
}

.content {
  overflow: hidden;
}
</style>
