<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { SET_NAV_DESTINATION_TAB } from '@/store/modules/contentView';
import { loginPath } from '@/utils/paths';
import { debounce, isEmpty } from 'lodash';
import { computed, onBeforeMount, reactive, watch } from 'vue';
import { RouterLink, RouterView, useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ContentSettingsTabs from './ContentSettingsTabs.vue';

const TabRoute = {
  Info: 'apps.info',
  Access: 'apps.access',
  Runtime: 'apps.runtime',
  Output: 'apps.output',
  Tags: 'apps.tags',
  Environment: 'apps.environment',
};

const route = useRoute();
const router = useRouter();
const store = useStore();

const localState = reactive({
  activeTabId: 'access',
  renderPanelComponents: true
});

const app = computed(() => store.state.contentView.app);
const appError = computed(() => store.state.contentView.appError);
const requiresAuth = computed(() => store.state.contentView.requiresAuth);
const isAuthorized = computed(() => !store.state.contentView.isUnauthorized);
const currentVariant = computed(() => store.state.parameterization.currentVariant);
const showSettingsPanel = computed(() => store.state.contentView.showSettingsPanel);
const appHasError = computed(() => requiresAuth.value || !!appError.value);
const loginUrl = computed(() => loginPath({ redirect: location.href }));
const useVariantRouting = computed(() => !isEmpty(currentVariant.value));

const tabRoute = (routeName) => `${routeName}${useVariantRouting.value ? '.variant' : ''}`;

const tabs = [
  {
    id: 'info',
    name: 'Info',
    route: tabRoute(TabRoute.Info),
    testId: 'app-settings-tab__info',
  },
  {
    id: 'access',
    name: 'Access',
    route: tabRoute(TabRoute.Access),
    testId: 'app-settings-tab__access',
  },
  {
    id: 'runtime',
    name: 'Runtime',
    route: TabRoute.Runtime,
    testId: 'app-settings-tab__runtime',
  },
  {
    id: 'schedule',
    name: 'Schedule',
    route: tabRoute(TabRoute.Output),
    testId: 'app-settings-tab__schedule',
  },
  {
    id: 'tags',
    name: 'Tags',
    route: tabRoute(TabRoute.Tags),
    testId: 'app-settings-tab__tags',
  },
  {
    id: 'environmentVariables',
    name: 'Vars',
    route: TabRoute.Environment,
    testId: 'app-settings-tab__environmentVariables',
  },
];

watch(showSettingsPanel, () => {
  debounce(() => {
    if (!showSettingsPanel.value) {
      localState.renderPanelComponents = false;
      const routeParams = { idOrGuid: app.value.guid };
      router.push({
        name: 'apps',
        params: routeParams,
        replace: true,
      });
      return;
    }
    localState.renderPanelComponents = true;
    // default to first tab on render
    resetToAccessTab();
  }, 100)();
});

onBeforeMount(() => {
  localState.renderPanelComponents = showSettingsPanel.value;
  localState.activeTabId = tabs.find(tab => route.name === tab.route)?.id || 'access';
});

const findTabById = (tabName) => tabs.find(tab => tab.id === tabName);
const navigateTo = (tabId) => {
  store.dispatch(SET_NAV_DESTINATION_TAB, { destinationTab: tabId });
  localState.activeTabId = tabId;
  const tab = findTabById(localState.activeTabId);

  const routeParams = { idOrGuid: app.value.guid };
  if (tab.route.match(/\.variant$/)) {
    routeParams.id = currentVariant.value.id;
  }

  router.push({
    name: tab.route,
    params: routeParams,
    replace: true,
  });
};

const resetToAccessTab = () => {
  navigateTo('access');
};
</script>

<template>
  <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
  <!--
    To maintain backwards functionality,
    catch the escape key from any open tab to return to the default access tab.
  -->
  <div
    id="settingsPanel"
    class="contentPanel"
    data-automation="app-settings-main-pane"
    @keydown.esc.prevent.stop="resetToAccessTab"
  >
    <!-- eslint-enable vuejs-accessibility/no-static-element-interactions -->

    <div
      v-if="localState.renderPanelComponents"
      class="appsettings settingsPane"
    >
      <div class="formSection">
        <ContentSettingsTabs
          v-if="!appHasError"
          :tabs="tabs"
          :active-tab-id="localState.activeTabId"
          @update:active-tab-id="navigateTo"
        />
      </div>

      <!-- Tab content -->
      <RouterView
        v-if="isAuthorized"
        name="settings"
      />
    </div>
    <div
      v-if="appHasError"
      class="notfound"
      data-automation="app-settings-not-found"
    >
      <div
        v-if="requiresAuth"
        data-automation="app-settings-not-found__no-auth"
      >
        <h2>You're Not Logged In</h2>
        <p class="rsc-lead">
          You must log in to view and edit content settings.
        </p>
        <a
          class="button default"
          :href="loginUrl"
        >
          Log In
        </a>
      </div>
      <div
        v-else
        data-automation="app-settings-not-found__generic"
      >
        <h2>Not found</h2>
        <p class="rsc-lead">
          This application is missing or not visible to you.
        </p>
        <p>
          The application may have been deleted, or you may have followed an incorrect link.
        </p>
        <RouterLink
          class="button default"
          :to="{ name: 'contentList' }"
        >
          Back to content list
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.notfound {
  position: relative;
  background-color: #f8f8f8;
  padding: 15px;
}

.disabled.disabled {
  background-color: pink;
}

.contentPanel .settingsPane {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  padding: 10px 20px 10px;
}
</style>
