<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup lang='ts'>
import dayjs from 'dayjs';
import { computed, ref, watch } from 'vue';
import FlatPickr from 'vue-flatpickr-component';

interface Props {
  date?: dayjs.Dayjs;
  disabled?: boolean;
  enableTime?: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<(e: 'change', value: dayjs.Dayjs) => void>();

const MIN_START_DATE = '01/01/2010';

const active = ref(false);
const startDate = ref(dayjs(props.date).toDate());

const pickerDate = computed<Date>(() => {
  if (!props.date) {
    // Default to today if no date is provided
    return dayjs().toDate();
  }

  return props.date.toDate();
});

const pickerConfigs = computed(() => ({
  allowInput: !props.disabled,
  altFormat: props.enableTime ? 'M j, Y  h:i K' : 'M j, Y',
  altInput: true,
  altInputClass: 'date-input__input',
  defaultDate: pickerDate.value,
  enableTime: props.enableTime,
  parseDate: parse,
  minDate: MIN_START_DATE,
}));

watch(
  () => props.date,
  newDate => {
    if (newDate.valueOf() !== new Date(startDate.value).getTime()) {
      startDate.value = newDate.toDate();
    }
  }
);

const parse = datestr => {
  const date = dayjs(datestr);
  if (date < dayjs(MIN_START_DATE)) {
    return new Date(MIN_START_DATE);
  }
  return date.toDate();
};

const onChange = ([date]) => {
  emit('change', dayjs(date));
};
</script>

<template>
  <div
    :class="{ active: active }"
    :disabled="disabled ? 'disabled' : null"
    class="date-input rsc-date-input"
  >
    <FlatPickr
      v-model="startDate"
      class="date-input__input"
      :config="pickerConfigs"
      :disabled="props.disabled"
      @on-change="onChange"
    />
  </div>
</template>

<style lang="scss">
.date-input {
  position: relative;
  display: inline-block;
  width: 100%;

  &__input {
    width: 100%;
  }
}
</style>
