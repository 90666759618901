<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup lang="ts">
import truncate from 'lodash/truncate';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { addExtension } from '@/api/app';
import { taskToPromise } from '@/api/tasks';
import { safeAPIErrorMessage } from '@/api/error';
import RSButton from '@/elements/RSButton.vue';

const props = defineProps<{
  extension: Extension
}>();

export interface ExtensionVersion {
  released: string;
  url: string;
  version: string;
};
export interface Extension {
  name: string;
  title: string;
  homepage: string;
  description: string;
  imgUrl?: string;
  latestVersion: ExtensionVersion;
  versions: ExtensionVersion[];
};

const buttonText = ref('Add');
const extensionGuid = ref('');
const isDeploying = ref(false);
const isDeploymentComplete = ref(false);
const showErrorMessage = ref(false);
const errorMessage = ref(null);

const router = useRouter();

const description = computed(() => truncate(props.extension.description, { length: 130, separator: ' ' }));
const logoSrc = computed(() => props.extension.imgUrl || 'images/default-external-content-icon.svg');
const latestExtensionUrl = computed(() => props.extension.latestVersion.url);

const openExtension = () => {
  if (!extensionGuid.value) {
    return;
  }
  router.push({ name: 'apps.access', params: { guid: extensionGuid.value } });
};

const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
  e.preventDefault();

  // Included for legacy support, e.g. Chrome/Edge < 119
  e.returnValue = true;
};

const onDeploymentProgress = (task: { finished: boolean; }) => {
  if (task.finished) {
    buttonText.value = 'Open';
    isDeploying.value = false;
    isDeploymentComplete.value = true;
    window.removeEventListener('beforeunload',  beforeUnloadHandler);
  }
};

const deployExtension = async() => {
  try {
    buttonText.value = 'Deploying...';
    isDeploying.value = true;

    // Avoid letting users unload with window while adding an extension
    window.addEventListener('beforeunload', beforeUnloadHandler);

    const { guid, taskId } = await addExtension({ url: latestExtensionUrl.value });
    extensionGuid.value = guid;
    await taskToPromise(taskId, onDeploymentProgress);
  } catch (err) {
    window.removeEventListener('beforeunload',  beforeUnloadHandler);
    buttonText.value = 'Add';
    isDeploying.value = false;
    showErrorMessage.value = true;
    errorMessage.value = safeAPIErrorMessage(err);
  };
};
</script>

<template>
  <article
    :key="props.extension.name"
    data-automation="gallery-card"
    class="gallery-card"
  >
    <div class="gallery-card__container">
      <img
        :alt="`Logo of ${props.extension.title} extension`"
        :src="logoSrc"
        class="gallery-card__logo"
        data-automation="gallery-card-logo"
      >
      <div
        class="gallery-card__details"
      >
        <h2
          class="gallery-card__title"
          data-automation="gallery-card-title"
        >
          <a
            data-automation="gallery-card-homepage"
            :href="props.extension.homepage"
          >
            {{ props.extension.title }}
          </a>
        </h2>
      </div>
    </div>

    <p
      class="gallery-card__description"
      data-automation="gallery-card-description"
    >
      {{ description }}
    </p>
    <div
      class="gallery-card__actions"
    >
      <RSButton
        class="gallery-card__button"
        :disabled="isDeploying"
        data-automation="gallery-card-button"
        type="primary"
        :label="buttonText"
        @click="isDeploymentComplete ? openExtension() : deployExtension()"
      />
      <p
        v-if="showErrorMessage"
        class="gallery-card__error"
        data-automation="gallery-card-error"
      >
        {{ errorMessage }}
      </p>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.gallery-card {
  background: $color-white;
  border: 1px solid $color-light-grey-4;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  height: 230px;
  margin: 0 2rem 2rem 0;
  padding: 1.25rem;
  width: 360px;

  &__container {
    align-items: center;
    display: flex;
    margin-bottom: 0.875rem;
  }

  &__logo {
    display: block;
    height: 50px;
    object-fit: contain;
    margin-right: 1rem;
    width: 50px;
  }

  &__details {
    display: flex;
    flex-flow: column nowrap;
    min-width: 0
  }

  &__title {
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.2px;
    margin-bottom: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__title a {
    color: $color-dark-grey-3;
  }

  &__actions {
    align-items: center;
    display: flex;
    margin-top: auto;
  }

  &__description {
    font-size: 0.875rem;
    font-weight: normal;
    letter-spacing: 0.2px;
  }

  &__error {
    color: $color-error;
    margin-left: 0.5rem;
  }

  &__button {
    margin-top: auto;
    min-width: 6.5em;
    width: 6.5em;
  }
}
</style>
