<!-- Copyright (C) 2022 by Posit Software, PBC. -->
<template>
  <RSModal
    v-if="active"
    :active="active"
    close-button-label="Close Modal"
    subject="Jump Start Examples"
    :wide-dialog="true"
    dialog-class="jump-start"
    data-automation="jump-start__modal"
    @close="closeJumpStart"
  >
    <template #topControls>
      <LanguageSelector
        :selected-language="selectedExampleLanguage"
        @selected="selectExampleLanguage"
      />
    </template>
    <template #content>
      <div class="jump-start__content">
        <ul
          ref="exampleList"
          role="menu"
          aria-label="List of examples"
          class="jump-start__examples"
          data-automation="jump-start-examples"
          tabindex="0"
          @keyup="onNavigateList"
          @keypress.enter="publishSelected"
        >
          <li
            v-for="example in filteredExamples"
            :key="example.name"
            class="jump-start__example"
            :data-automation="`jump-start-example-${example.name}`"
            role="none"
          >
            <Example
              role="menuitem"
              :example="example"
              :selected="example === selected"
              tabindex="-1"
              @publish="publish"
              @select="select"
              @focus-list="onFocusList"
            />
          </li>
        </ul>
        <div
          v-if="selected"
          class="jump-start__details"
        >
          <h1 class="jump-start__details-title">
            {{ selected.title }}
          </h1>
          <div
            v-show="selected.description !== undefined && selected.description.length > 0"
            class="jump-start__details-description"
          >
            <h2>About this example</h2>
            <p>{{ selected.description }}</p>
            <div v-show="selected.links !== undefined && selected.links.length > 0">
              <h2>
                Learn more about
                <span>
                  {{ ContentTypeShortDescriptions[selected.type] }}
                </span>
              </h2>

              <ul>
                <li
                  v-for="link in selected.links"
                  :key="link.text"
                >
                  <a
                    :href="link.href"
                    :title="`Link to ${link.text}`"
                    target="_blank"
                  >{{ link.text }}</a>
                </li>
              </ul>
            </div>
            <div
              v-show="selected.requirements && selected.requirements.length > 0"
            >
              <h2>Requirements</h2>
              <ul>
                <li
                  v-for="requirement in selected.requirements"
                  :key="requirement"
                >
                  {{ requirement }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <DontShowDialog
        v-if="isDontShowActive"
        :active="isDontShowActive"
        @close="closeDontShow"
      />
    </template>
    <template #controls>
      <div class="jump-start__hide">
        <label
          for="showOnLogin"
          class="rs-checkbox__label"
        >
          <input
            id="showOnLogin"
            :checked="!hide"
            type="checkbox"
            class="rs-checkbox__input"
            @change="toggleHidden"
          >
          Show these examples whenever I log in
        </label>
      </div>
      <div
        v-if="selected"
        class="jump-start__actions"
      >
        <RSButton
          :label="getStartedButtonLabel(selected)"
          data-automation="jump-start__publish-button"
          class="jump-start__publish-button"
          @click="publishSelected"
        />
      </div>
    </template>
  </RSModal>
</template>

<script>
import { getExamples } from '@/api/examples';
import LanguageSelector from '@/components/LanguageSelector';
import { BY_LANGUAGE, ContentTypeShortDescriptions } from '@/constants/contentTypes';
import RSButton from '@/elements/RSButton.vue';
import RSModal from '@/elements/RSModal.vue';
import {
  JUMP_START_CLOSE,
  JUMP_START_INIT,
  JUMP_START_RESET_BACK_NAVIGATION,
  JUMP_START_UPDATE_HIDDEN
} from '@/store/modules/jumpstart';
import { PUBLISH_WIZARD_OPEN } from '@/store/modules/publish';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapMutations, mapState } from 'vuex';
import DontShowDialog from './DontShowDialog';
import Example from './Example';

export default {
  name: 'JumpStart',
  components: {
    DontShowDialog,
    Example,
    LanguageSelector,
    RSButton,
    RSModal,
  },
  data() {
    return {
      ContentTypeShortDescriptions,
      exampleCount: 0,
      examples: [],
      filteredExamples: [],
      isDontShowActive: false,
      selected: null,
      selectedExampleLanguage: 'python',
    };
  },
  computed: {
    ...mapState({
      active: state => state.jumpstart.active,
      hide: state => state.jumpstart.hide,
      backNavigation: state => state.jumpstart.backNavigation,
    }),
  },
  watch: {
    active(isActive) {
      if (isActive && this.backNavigation) {
        this.$nextTick().then(() => {
          this.onFocusList();
          this.resetBackNavigation();
          if (this.selected) {
            const selectedExample = document.querySelector('.jump-start-example--selected');
            if (selectedExample) { selectedExample.scrollIntoView(); }
          }
        });
      }
    }
  },
  mounted() {
    return this.initializeJumpStart()
      .then(getExamples)
      .then(examples => {
        this.examples = examples;
        if (!examples || examples.length === 0) {
          return;
        }
        this.selected = examples[0];
        this.filterExamplesForLanguage();
      })
      .catch(() => {
        this.examples = [];
        this.selected = null;
      });
  },
  methods: {
    getStartedButtonLabel(selected) {
      return `Get Started using "${selected.title}"`;
    },
    closeJumpStart() {
      if (!this.isDontShowActive) { this.close(); }
    },
    closeDontShow() {
      this.isDontShowActive = false;
    },
    select(example) {
      this.selected = example;
    },
    toggleHidden(e) {
      const hidden = !e.target.checked;

      if (hidden) {
        this.isDontShowActive = true;
      }

      this.updateHidden(hidden);
    },
    onNavigateList(keyEvent) {
      let selectedIndex = this.filteredExamples.findIndex(example => example === this.selected);
      switch (keyEvent.key) {
        case 'ArrowLeft':
        case 'ArrowUp':
          selectedIndex = Math.max(0, selectedIndex - 1);
          break;
        case 'ArrowRight':
        case 'ArrowDown':
          selectedIndex = Math.min(this.exampleCount - 1, selectedIndex + 1);
      }

      this.select(this.filteredExamples[selectedIndex]);
      document.querySelectorAll('li.jump-start__example')[selectedIndex].scrollIntoView();
    },
    onFocusList() {
      this.$refs.exampleList.focus();
    },
    publish(example) {
      this.close();
      this.$nextTick().then(() => this.startPublishWizard({
        contentType: example.type,
        copyFrom: example,
      }));
    },
    publishSelected() {
      this.publish(this.selected);
    },
    filterExamplesForLanguage() {
      const allowedContentTypes = BY_LANGUAGE[this.selectedExampleLanguage.toUpperCase()];
      this.filteredExamples = this.examples.filter(ex => allowedContentTypes.includes(ex.type));
      this.exampleCount = this.filteredExamples.length;

      if (isEmpty(this.filteredExamples)) {
        return [];
      }
      if (!this.filteredExamples.includes(this.selected)) {
        this.selected = this.filteredExamples[0];
      }
      return this.filteredExamples;
    },
    selectExampleLanguage(language) {
      this.selectedExampleLanguage = language;
      this.filterExamplesForLanguage();
      // select the first example in the new tab
      this.selected = this.filteredExamples[0];

      this.$refs.exampleList.scrollTo({
        top: 0,
      });
    },
    ...mapMutations({
      close: JUMP_START_CLOSE,
      startPublishWizard: PUBLISH_WIZARD_OPEN,
      resetBackNavigation: JUMP_START_RESET_BACK_NAVIGATION,
    }),
    ...mapActions({
      initializeJumpStart: JUMP_START_INIT,
      updateHidden: JUMP_START_UPDATE_HIDDEN,
    }),
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.jump-start {
  max-height: 100%;
  white-space: wrap;

  ul:focus {
    @include control-focus;
    border-radius: 3px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__examples {
    display: flex;
    overflow: auto;
  }

  &__example {
    display: block;
    margin: 0 1rem 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &__details {
    margin-top: 1rem;
    margin-left: 0;
  }

  &__details-title {
    font-size: 1.8rem;
    font-weight: 300;
    margin: 0 0 0.625rem 0;
  }

  // This is global as these styles are applied to the markup for
  // each example as specified in the jumpstart.yml and not rendered
  // by Vue (therefore cannot use scoped styles).

  &__details-description {
    h2 {
      padding: 4px 6px;
      font-size: 0.5625rem;
      line-height: 1.5em;
      letter-spacing: 1px;
      background-color: $color-light-blue;
      border-radius: 3px;
      text-transform: uppercase;
      margin: 1rem 0;
      font-weight: normal;
    }
    li {
      a {
        margin-left: 0.3rem;
        padding: 0.2rem 0.3rem;
      }
    }
  }

  & > .rs-modal__actions {
    flex-direction: column;
    align-items: end;
  }

  &__hide {
    margin-bottom: 1rem;
  }

  &__publish-button,
  &__download-button {
    margin-bottom: 1rem !important;
  }
}

@media screen and (min-width: 850px) {
  .jump-start {
    /* Magic number (8.25rem), simply 16.5 divided by 2 to act as a in-between size for the modal dialog */
    max-width: calc(100% - 8.25rem) !important;
  }
}

@media screen and (min-width: 1150px) {
  .jump-start {
    max-width: 1000px !important;

    &__content {
      display: flex;
      flex-direction: row;
    }

    &__examples,
    &__details {
      /* Magic number (16.5rem), calculated from the margins, padding, and heights of the various modal elements */
      max-height: calc(100vh - 16.5rem);
      overflow: auto;
    }

    &__examples {
      display: block;
      flex-basis: 550px;
      flex-shrink: 0;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    &__example {
      display: inline-block;
      margin: 0 1rem 1rem 0;
      padding-left: 0.2rem;
      padding-top: 0.2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__details {
      margin-left: 1rem;
      flex-basis: auto;
      flex-shrink: 1;
    }

    & > .rs-modal__actions {
      align-items: center;
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
    }

    &__actions {
      text-align: right;
    }

    &__hide {
      text-align: left;
      width: auto;
    }
  }
}

@media screen and (min-width: 1250px) {
  .jump-start {
    max-width: 1200px !important;
  }
}
</style>
