// Copyright (C) 2025 by Posit Software, PBC.
import { store } from '@/store';

import AppHeader from '@/components/AppHeader/index.vue';
import AdminTagsView from '@/views/system/AdminTagsView.vue';
import AuditLogsView from '@/views/system/AuditLogsView.vue';
import SystemChecksView from '@/views/system/SystemChecksView.vue';
import MetricsView from '@/views/system/metrics/MetricsView.vue';
import ProcessList from '@/views/system/metrics/ProcessList.vue';
import ScheduledContent from '@/views/system/scheduledContent/index.vue';
import ExecutionEnvironments from '@/views/executionEnvironments/ExecutionEnvironments.vue';
import IntegrationsView from '@/views/integrations/IntegrationsView.vue';
import InfoView from '@/views/system/info/InfoView.vue';

export default [
  {
    path: '/admin',
    name: 'admin',
    redirect: () => ({ name: 'system.info' })
  },
  {
    path: '/admin/queue',
    name: 'admin.queue',
    redirect: () => ({ name: 'system.checks' })
  },
  {
    path: '/admin/metrics',
    name: 'admin.metrics',
    redirect: () => ({ name: 'system.metrics' })
  },
  {
    path: '/admin/mail_settings',
    name: 'admin.mail_settings',
    redirect: () => ({ name: 'system.info' })
  },
  {
    path: '/admin/tag_editor',
    name: 'admin.tag_editor',
    redirect: () => ({ name: 'system.tags' })
  },
  {
    path: '/admin/audit_logs',
    name: 'admin.audit_logs',
    redirect: () => ({ name: 'system.auditing' })
  },
  {
    path: '/admin/scheduled_content',
    name: 'admin.scheduled_content',
    redirect: () => ({ name: 'system.schedules' })
  },
  {
    path: '/admin/system_checks',
    name: 'admin.system_checks',
    redirect: () => ({ name: 'system.checks' })
  },
  {
    path: '/admin/processes',
    name: 'admin.processes',
    redirect: () => ({ name: 'system.processes' })
  },
  {
    path: '/environments',
    name: 'environments',
    redirect: () => ({ name: 'system.environments' })
  },
  {
    path: '/integrations',
    name: 'integrations',
    redirect: () => ({ name: 'system.integrations' })
  },
  {
    path: '/system',
    name: 'system',
    redirect: () => ({ name: 'system.info' })
  },
  {
    path: '/system/metrics',
    name: 'system.metrics',
    meta: {
      requireRole: ['publisher', 'administrator'],
      title: 'Metrics',
    },
    components: {
      systemContainer: MetricsView,
      header: AppHeader,
    }
  },
  {
    path: '/system/tags',
    name: 'system.tags',
    meta: {
      requireRole: 'administrator', // publisher read-only
      title: 'Tags',
    },
    components: {
      systemContainer: AdminTagsView,
      header: AppHeader,
    }
  },
  {
    path: '/system/auditing',
    name: 'system.auditing',
    meta: {
      requireRole: 'administrator',
      title: 'Audit Logs',
    },
    components: {
      systemContainer: AuditLogsView,
      header: AppHeader,
    }
  },
  {
    path: '/system/schedules',
    name: 'system.schedules',
    meta: {
      requireRole: 'administrator', // publisher self-content only
      title: 'Scheduled Reports',
    },
    components: {
      systemContainer: ScheduledContent,
      header: AppHeader,
    }
  },
  {
    path: '/system/checks',
    name: 'system.checks',
    meta: {
      requireRole: 'administrator',
      title: 'System Checks',
    },
    components: {
      systemContainer: SystemChecksView,
      header: AppHeader,
    }
  },
  {
    path: '/system/processes',
    name: 'system.processes',
    meta: {
      requireRole: 'administrator',
      title: 'Processes',
    },
    components: {
      systemContainer: ProcessList,
      header: AppHeader,
    }
  },
  {
    path: '/system/environments',
    name: 'system.environments',
    meta: {
      requireAuth: true,
      title: 'Environments',
    },
    components: {
      systemContainer: ExecutionEnvironments,
      header: AppHeader,
    },
  },
  {
    path: '/system/integrations',
    name: 'system.integrations',
    meta: {
      requireRole: ['publisher', 'administrator'],
      enabled: () => store.state.server.settings.oauthIntegrationsEnabled,
      title: 'Integrations',
    },
    components: {
      systemContainer: IntegrationsView,
      header: AppHeader,
    },
  },
  {
    path: '/system/info',
    name: 'system.info',
    meta: {
      requireRole: ['publisher', 'administrator'],
      title: 'System Information',
    },
    components: {
      systemContainer: InfoView,
      header: AppHeader,
    },
  },
];
