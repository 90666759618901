<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { getJobLog, tailJobPath } from '@/api/jobs';
import { utcToLocalTimezone } from '@/utils/timezone';
import { nextTick, onUnmounted, reactive, ref, watch } from 'vue';
import LogLine from './LogLine.vue';

const props = defineProps({
  app: {
    type: Object,
    required: true,
  },
  job: {
    type: Object,
    required: true,
  },
  searchText: {
    type: String,
    default: null,
  },
  searchCurrent: {
    type: Number,
    default: 0,
  },
  wrapLongLines: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['matches', 'reset']);

const output = ref(null);
const localState = reactive({
  tail: null,
  entries: [],
  following: true,
  matchCount: 0,
});

watch(
  () => props.job,
  job => {
    const { guid } = props.app;
    const { key } = job;

    if (localState.tail) {
      localState.tail.removeEventListener('entry', appendLogEntry);
      localState.tail.close();
      localState.tail = null;
    }

    localState.entries = [];
    getJobLog(guid, key)
      .then(entries => {
        localState.entries = entries;
        nextTick().then(() => scrollToLatest());
      })
      .then(() => {
        localState.tail = new EventSource(tailJobPath(guid, key));
        localState.tail.addEventListener('entry', appendLogEntry);
      });
  },
  { immediate: true }
);

watch(
  () => props.searchText,
  () => {
    nextTick().then(() => emit('reset'));
    localState.matchCount = 0;
    emit('matches', 0);
  }
);

watch(
  () => localState.matchCount,
  count => {
    emit('matches', count);
  }
);

watch(
  () => props.searchCurrent,
  newVal => {
    updateCurrent(newVal);
  }
);

onUnmounted(() => {
  if (localState.tail) {
    localState.tail.removeEventListener('entry', appendLogEntry);
    localState.tail.close();
    localState.tail = null;
  }
});

const countMatches = count => {
  localState.matchCount += count;
};

const updateCurrent = value => {
  removeCurrent();

  let current;

  if (value === 0) {
    current = output.value.getElementsByClassName('highlighted')[0];
  } else {
    current =
      output.value.getElementsByClassName('highlighted')[value - 1];
  }

  if (current) {
    current.classList.add('current');
    localState.following = false;
    nextTick().then(() => scrollToCurrent(current));
  }
};
const removeCurrent = () => {
  const all = output.value.getElementsByClassName('current');

  for (const el of all) {
    el.classList.remove('current');
  }
};

const localTimeStamp = timestamp => {
  return utcToLocalTimezone(timestamp, 'YYYY/MM/DD h:mm:ss A');
};

const appendLogEntry = e => {
  const entry = JSON.parse(e.data);
  localState.entries.push(entry);
  nextTick().then(() => scrollToLatest());
};

const onScroll = ({ target: { scrollTop, clientHeight, scrollHeight } }) => {
  if (scrollTop + clientHeight >= scrollHeight) {
    localState.following = true;
  } else {
    localState.following = false;
  }
};
const scrollToCurrent = el => {
  localState.following = false;
  el.scrollIntoView();
};
const scrollToLatest = () => {
  if (output.value && localState.following) {
    output.value.scrollTop = output.value.scrollHeight;
  }
};
</script>

<template>
  <pre
    ref="output"
    data-automation="log-overlay__output"
    class="log-overlay__output"
    tabindex="0"
    @scroll="onScroll"
  >
    <template
      v-for="(entry, i) in localState.entries"
      :key="i"
    >
      <time
        class="timestamp"
        :title="entry.timestamp"
      >
        {{ `${localTimeStamp(entry.timestamp)}: ` }}
      </time>
      <LogLine
        :class="['content', entry.source, wrapLongLines ? 'wrap' : '']"
        :data="entry.data"
        :search-term="searchText"
        @count="countMatches"
      />
    </template>
</pre>
</template>
<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.log-overlay__output {
  @include control-visible-focus;
  padding: 0 1rem;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: auto;
  height: 0;
  margin: 8px;
  background-color: $color-white;
  white-space: normal;
  display: grid;
  grid-template-columns: 13rem 1fr;
  color: black;

  > .timestamp {
    color: #737373;
    font-size: 13px;
    font-style: normal;
    padding-right: 1rem;
  }

  > .content {
    white-space: pre;
    color: green;

    &.stdout {
      color: $color-dark-grey-3;
    }

    &.stderr {
      color: $color-error;
      font-style: italic;
    }

    &.wrap {
      white-space: pre-wrap;
    }
  }
}
</style>
