<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { JUMP_START_INIT } from '@/store/modules/jumpstart';
import { ADD_EXTENSION_MODAL_CLOSE } from '@/store/modules/publish';
import AddExtension from '@/views/content/AddExtension';
import DeploymentWizard from '@/views/content/DeploymentWizard/DeploymentWizard';
import JumpStart from '@/views/content/JumpStart';
import PublishDropdown from '@/views/content/PublishDropdown.vue';
import PublishWizard from '@/views/content/PublishWizard';
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

const emit = defineEmits(['resetContent', 'requestPermissions']);

const store = useStore();
const currentUser = computed(() => store.state.currentUser.user);
const serverSettings = computed(() => store.state.server.settings);
const isJumpstartActive = computed(() => store.state.jumpstart.active);
const isPublishingActive = computed(() => store.state.publish.active);
const isShowAddExtensionModal = computed(() => store.state.publish.showAddExtensionModal);
const canPublish = computed(() => currentUser.value.canPublish());
const canPublishGit = computed(() => {
  return canPublish.value && serverSettings.value.gitEnabled;
});
const canRequestPublisherAccess = computed(() => {
  return (
    currentUser.value.isViewer() &&
    serverSettings.value.viewersCanRequestPrivileges
  );
});

const requestPermissions = () => {
  emit('requestPermissions');
};

onBeforeMount(() => {
  if (serverSettings.value.jumpStartEnabled) {
    store.dispatch(JUMP_START_INIT);
  }
});
</script>

<template>
  <div class="content-list-ops">
    <PublishDropdown
      v-if="canPublish"
      :git-enabled="serverSettings.gitEnabled"
      :git-available="serverSettings.gitAvailable"
      :jump-start-enabled="serverSettings.jumpStartEnabled"
      :extensions-enabled="serverSettings.extensionsEnabled"
      new-view
    />
    <a
      v-if="canRequestPublisherAccess"
      href="#"
      class="publisher-link"
      data-automation="request-publisher-access"
      @click.prevent="requestPermissions"
      @keydown.space.prevent="requestPermissions"
      @keydown.enter.prevent="requestPermissions"
    >
      Publish
    </a>

    <!-- Wizards and Modals -->
    <DeploymentWizard
      v-if="canPublishGit"
      :git-available="serverSettings.gitAvailable"
      @refresh-content-list="emit('resetContent')"
    />
    <PublishWizard v-if="canPublish && isPublishingActive && !isJumpstartActive" />
    <JumpStart v-if="canPublish && serverSettings.jumpStartEnabled && isJumpstartActive" />
    <AddExtension
      v-if="serverSettings.extensionsEnabled"
      :show-modal="isShowAddExtensionModal"
      @refresh-content-list="emit('resetContent')"
      @close="store.commit(ADD_EXTENSION_MODAL_CLOSE)"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.publisher-link {
  color: $color-link;
  text-decoration: none;
  cursor: pointer;
  font-size: $rs-font-size-normal;

  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
  }

  &:visited {
    color: $color-link;
    text-decoration: none;
  }
}
</style>
