<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup lang="ts">
import { computed, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import { getConnectGalleryList } from '@/api/connectGallery';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import Spinner from '@/components/Spinner.vue';
import GalleryEmptyState from '@/views/connectGallery/GalleryEmptyState.vue';
import GalleryCard from '@/components/ConnectGallery/GalleryCard.vue';
import Paginator from '@/components/Paginator.vue';
import DeployExternalContentModal from '@/components/ConnectGallery/DeployExternalContentModal.vue';
import RSButton from '@/elements/RSButton.vue';

const RESULTS_PER_PAGE = 12;
const store = useStore();

const localState = reactive({
  galleryItems: [],
  hasError: false,
  isLoading: true,
  page: 1,
  showDeployModal: false,
});

const firstResult = computed(() => (localState.page - 1) * RESULTS_PER_PAGE);
const visibleItems = computed(() => localState.galleryItems
  .slice(firstResult.value, firstResult.value + RESULTS_PER_PAGE));

const onPageChange = (n: number) => {
  localState.page = n;
};

onMounted(async() => {
  try {
    const data = await getConnectGalleryList();
    if (isEmpty(data)) {
      localState.hasError = true;
      return;
    }
    localState.galleryItems = data;
    localState.isLoading = false;
  } catch (err) {
    localState.isLoading = false;
    localState.hasError = true;
    store.commit(SET_ERROR_MESSAGE_FROM_API, err);
  }
});
</script>

<template>
  <div class="band">
    <div class="bandContent connect-gallery">
      <div 
        v-if="localState.isLoading"
      >
        <Spinner
          data-automation="connect-gallery-loading"
        />
      </div>
      <GalleryEmptyState
        v-else-if="localState.hasError"
        data-automation="connect-gallery-empty"
      />
      <div
        v-else
        data-automation="connect-gallery"
        class="connect-gallery__content"
      >
        <div
          class="connect-gallery__header"
        >
          <h1
            data-automation="connect-gallery-title"
            class="connect-gallery__title"
          >
            Explore the Connect Gallery
          </h1>
          <RSButton
            class="connect-gallery__add-content"
            data-automation="connect-gallery-add-content"
            label="Deploy External Content"
            @click="localState.showDeployModal = true"
          />
        </div>
        <section
          class="connect-gallery__section"
        >
          <div
            class="connect-gallery__list"
            data-automation="connect-gallery-list"
          >
            <GalleryCard
              v-for="extension in visibleItems"
              :key="extension.name"
              :extension="extension"
            />
          </div>
          <Paginator
            :page="localState.page"
            :per-page="RESULTS_PER_PAGE"
            :total="localState.galleryItems.length"
            @change="onPageChange"
          />
        </section>
        <DeployExternalContentModal
          :show-modal="localState.showDeployModal"
          @close="localState.showDeployModal = false"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.connect-gallery {
  padding-top: $header-height;
  padding-bottom: 175px;

  &__content {
    .connect-gallery__header {
      .connect-gallery__add-content {
        border: 1px solid $color-primary;
        background: $color-white;
        color: $color-primary;
        font-size: 0.875rem;
        display: flex;
        padding: 4px 8px;

        &:hover {
          color: $color-white;
          background: $color-primary;
        }

        &::before {
          content: '+';
          font-size: 1.5rem;
          position: relative;
          padding-right: 8px;
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 1.5rem;
    letter-spacing: 0.2px;
  }

  &__section {
    margin: 4rem 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
