<!-- Copyright (C) 2025 by Posit Software, PBC. -->
<script setup>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { computed, onMounted, onUpdated, ref } from 'vue';

const props = defineProps({
  entries: {
    type: Array,
    default() {
      return [];
    },
  },
  embedded: {
    type: Boolean,
    default: true,
  },
});

dayjs.extend(utc);

const follow = ref(true);
const logContents = ref(null);

const filteredEntries = computed(() =>
  props.entries.filter(entry => entry.timestamp || entry.line));

onUpdated(() => {
  if (follow.value) {
    logContents.value.scrollTop = logContents.value.scrollHeight;
  }
});

onMounted(() => {
  logContents.value?.focus();
});

const formattedTimestamp = (entry) => {
  if (!entry.timestamp) {
    return '';
  }
  return `${dayjs.utc(entry.getDayjsTimestamp()).format('MM/DD HH:mm:ss.SSS')} (GMT)`;
};

const logLine = (entry) => entry.getLine();

const isError = (entry) => entry.Error();

const onScroll = () => {
  const maxScroll = logContents.value.scrollHeight - logContents.value.clientHeight;
  follow.value = (logContents.value.scrollTop >= maxScroll - 1);
};
</script>

<template>
  <div
    ref="logContents"
    :class="{
      logOutput: true,
      inDialog: embedded,
      short: embedded
    }"
    tabindex="0"
    @scroll="onScroll"
  >
    <div
      v-for="(entry, index) in filteredEntries"
      :key="index"
    >
      <div
        class="logTimestamp"
      >
        {{ formattedTimestamp(entry) }}
      </div>
      <div
        data-automation="log-message"
        :class="{
          logMessage: true,
          error: isError(entry)
        }"
      >
        {{ logLine(entry) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.logOutput {
  font-family: 'Courier New', monospace;
  overflow-y: auto;

  &.inDialog {
    height: 300px;
    overflow: auto;
    background-color: $color-white;
    @include control-border();
    padding: 0 15px 10px 15px;

    &.short {
      height: 200px;
    }
  }

  &:focus {
    @include control-focus;
  }
}

.logTimestamp {
  font-size: 11px;
  font-style: italic;
  line-height: 13px;
  padding-top: 10px;
}

.logMessage {
  line-height: 18px;
  word-wrap: break-word;

  &.warning {
    color: #800000;
  }

  &.error {
    color: $color-error;
  }
}

.logSummary {
  line-height: 16px;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left top;

  &.error {
    background-image: url(/images/elements/error.svg);
  }

  &.warning {
    background-image: url(/images/elements/warning.svg);
  }
}
</style>
