// Copyright (C) 2023 by Posit Software, PBC.

import { getApp, getAppBy } from '@/api/app';
import { fetchRenderings } from '@/api/parameterization';
import {
  LOAD_BUNDLES,
  RESET_BUNDLES,
} from '@/store/modules/bundles';
import {
  LEGACY_PARAMS_CLEAR,
  LEGACY_PARAMS_SET_UNSAVED_MODAL,
} from '@/store/modules/legacyParams';
import {
  PARAMETERIZATION_FETCH_VARIANTS,
  PARAMETERIZATION_RESET_VARIANTS,
  PARAMETERIZATION_SET_APP,
  PARAMETERIZATION_SET_INITIAL_VARIANT,
} from '@/store/modules/parameterization';
import { isEmpty } from 'lodash';

// Mutations
export const SET_CONTENT_ITEM = 'SET_CONTENT_ITEM';
export const SET_CONTENT_ITEM_ERROR = 'SET_CONTENT_ITEM_ERROR';
export const SET_RENDERINGS_HISTORY = 'SET_RENDERINGS_HISTORY';
export const SET_RENDERINGS_DISPLAYED_ID = 'SET_RENDERINGS_DISPLAYED_ID';
export const TOGGLE_RENDERINGS_HISTORY = 'TOGGLE_RENDERINGS_HISTORY';

export const SET_LOGS_PANEL_VISIBILITY = 'SET_LOGS_PANEL_VISIBILITY';
export const SET_CONTENT_FRAME_RELOADING = 'SET_CONTENT_FRAME_RELOADING';
export const SET_SETTINGS_PANEL_VISIBILITY = 'SET_SETTINGS_PANEL_VISIBILITY';
export const SET_PARAMETERS_PANEL_VISIBILITY = 'SET_PARAMETERS_PANEL_VISIBILITY';
export const PREVIEW_LOCKED_MESSAGE = 'PREVIEW_LOCKED_MESSAGE';
export const HIDE_LOCKED_MESSAGE_PREVIEW = 'HIDE_LOCKED_MESSAGE_PREVIEW';
export const UPDATE_LOCKED_STATE = 'UPDATE_LOCKED_STATE';
export const UPDATE_APP_METADATA = 'UPDATE_APP_METADATA';
export const SET_NAV_DESTINATION_TAB = 'SET_NAV_DESTINATION_TAB';
export const SET_NAVIGATION = 'SET_NAVIGATION';
export const CLEAR_NAVIGATION = 'CLEAR_NAVIGATION';

// Actions
export const LOAD_CONTENT_VIEW = 'LOAD_CONTENT_VIEW';
export const LOAD_RENDERINGS_HISTORY = 'LOAD_RENDERINGS_HISTORY';
export const CLEAR_PREP_CONTENT_VIEW = 'CLEAR_PREP_CONTENT_VIEW';
export const TOGGLE_PANELS = 'TOGGLE_PANELS';

export const LOGS_PANEL = 'LOGS_PANEL';
export const SETTINGS_PANEL = 'SETTINGS_PANEL';
export const PARAMETERS_PANEL = 'PARAMETERS_PANEL';
export const OPEN_PANEL = true;
export const CLOSE_PANEL = false;

export const defaultState = () => ({
  app: null,
  appError: null,
  destinationTab: null,
  isUnauthorized: false,
  lockedMessagePreview: null,
  reloadingContentFrame: false,
  renderingHistory: {
    showHistoryPane: false,
    items: [],
    displayedId: 0,
  },
  requiresAuth: false,
  showLockedMessagePreview: false,
  showLogs: false,
  showParametersPanel: false,
  showSettingsPanel: false,
});

/* eslint-disable no-shadow */
// "history is already declared in the upper scope"
// we don't care particularly about this one

export default {
  state: defaultState(),
  mutations: {
    [SET_CONTENT_ITEM](state, { app = null, isUnauthorized = false, requiresAuth = false }) {
      // Reset some fields from previous views
      state.showLogs = false;
      state.showParametersPanel = false;
      state.renderingHistory.showHistoryPane = false;

      state.app = app;
      state.requiresAuth = requiresAuth;
      state.isUnauthorized = isUnauthorized;
    },
    [SET_CONTENT_ITEM_ERROR](state, error) {
      state.appError = error;
    },
    [SET_RENDERINGS_HISTORY](state, { history = [], displayedId = 0 }) {
      state.renderingHistory.items = history;
      state.renderingHistory.displayedId = displayedId;
    },
    [TOGGLE_RENDERINGS_HISTORY](state) {
      state.renderingHistory.showHistoryPane = !state.renderingHistory.showHistoryPane;
      if (!state.renderingHistory.showHistoryPane) {
        state.renderingHistory.displayedId =
          state.renderingHistory.items.find(r => r.active)?.id || 0;
      }
    },
    [SET_RENDERINGS_DISPLAYED_ID](state, displayedId) {
      state.renderingHistory.displayedId = displayedId;
    },
    [SET_LOGS_PANEL_VISIBILITY](state, flag = false) {
      state.showLogs = flag;
      if (flag) {
        state.showSettingsPanel = false;
        state.showParametersPanel = false;
      }
    },
    [SET_CONTENT_FRAME_RELOADING](state, flag = false) {
      state.reloadingContentFrame = flag;
    },
    [SET_SETTINGS_PANEL_VISIBILITY](state, flag = false) {
      state.showSettingsPanel = flag;
      if (flag) {
        state.showLogs = false;
        state.showParametersPanel = false;
      }
    },
    [SET_PARAMETERS_PANEL_VISIBILITY](state, flag = false) {
      state.showParametersPanel = flag;
      if (flag) {
        state.showLogs = false;
        state.showSettingsPanel = false;
      }
    },
    [UPDATE_APP_METADATA](state, newContent) {
      state.app.title = newContent.title;
      state.app.displayName = newContent.title;
      state.app.description = newContent.description;
      state.app.locked = newContent.locked;
      state.app.lockedMessage = newContent.lockedMessage;
    },
    [PREVIEW_LOCKED_MESSAGE](state, lockedMessage) {
      state.showLockedMessagePreview = true;
      state.lockedMessagePreview = lockedMessage;
    },
    [HIDE_LOCKED_MESSAGE_PREVIEW](state) {
      state.showLockedMessagePreview = false;
    },
    [UPDATE_LOCKED_STATE](state, { locked = false, lockedMessage = '' }) {
      state.app.locked = locked;
      state.app.lockedMessage = lockedMessage;
    },
    [SET_NAVIGATION](state, { destinationTab }) {
      state.destinationTab = destinationTab;
    },
    [CLEAR_NAVIGATION](state) {
      state.destinationTab = null;
    },
  },
  actions: {
    // We handle app routes via id or guid.
    // - GUID is desired over id due to security implications,
    // - ID is easy to guess since it is a serialized number.
    // For a very long time we used id for app routing,
    // and since customers might have many bookmarks and URLs saved using id,
    // we should still support it.

    // This action pulls the app twice,
    // -  The first time trying with guid and the v1 API, since only the v1 API can respond with a 403
    //    when using guid, to be able to handle the unauthorized view, for users to be allowed to request access.
    // -  The second time using the v0 API and the app Id.
    //    Also, the v0 API provides more data to be used by the dashboard like the app users and groups.
    async [LOAD_CONTENT_VIEW](
      { commit, dispatch, rootState },
      { appIdOrGuid, variantId, onlyAppData = false }
    ) {
      const isGuid = isNaN(Number(appIdOrGuid));

      // Only loading-refreshing app data, no bundles nor variants nor renderings
      // Not clearing bundles, variants or renderings
      if (!onlyAppData) {
        dispatch(CLEAR_PREP_CONTENT_VIEW);
      }
      commit(LEGACY_PARAMS_CLEAR);

      try {
        // Calling v1 API if using guid just to catch 403s
        // catch block below will pick unauthorized status
        if (isGuid) {
          await getAppBy(appIdOrGuid);
        }

        const app = await getApp(appIdOrGuid);
        commit(SET_CONTENT_ITEM, { app });

        // App populated, return if applicable
        if (onlyAppData) {
          return;
        }

        // If is a renderable app (support variants)
        // Call to load variants.
        if (app.isRenderable()) {
          // TODO: we can stop pulling the app in parameterization module
          commit(PARAMETERIZATION_SET_APP, app);
          if (variantId) {
            commit(PARAMETERIZATION_SET_INITIAL_VARIANT, Number(variantId));
          }
          await dispatch(PARAMETERIZATION_FETCH_VARIANTS, app.id);
          // If current variant loaded, pull renderings history
          const { currentVariant } = rootState.parameterization;
          if (!isEmpty(currentVariant)) {
            await dispatch(LOAD_RENDERINGS_HISTORY, currentVariant.id);
          }
        }

        // Only pull the bundles if the current user is editor
        // If not an editor, means user does not have permissions to see this app.
        // Then, no bundles, no source versions to show.
        // 
        // An exception is made for static content, for which we want to be able to display
        // publication history to viewers.
        const currentUser = rootState.currentUser.user; 
        if ((!isEmpty(currentUser) && currentUser.isAppEditor(app)) || app.isStatic()) {
          await dispatch(LOAD_BUNDLES, app.guid);
        }
      } catch (err) {
        const status = err?.response?.status;
        if (status === 401) {
          commit(SET_CONTENT_ITEM, { requiresAuth: true });
        } else if (status === 403) {
          commit(SET_CONTENT_ITEM, { isUnauthorized: true });
        } else {
          commit(SET_CONTENT_ITEM_ERROR, err);
        }
      }
    },
    async [LOAD_RENDERINGS_HISTORY]({ commit }, variantId) {
      return fetchRenderings(variantId)
        .then(history => {
          const active = history.find(r => r.active);
          commit(SET_RENDERINGS_HISTORY, { history, displayedId: active?.id });
        });
    },
    [CLEAR_PREP_CONTENT_VIEW]({ commit }) {
      commit(RESET_BUNDLES);
      commit(PARAMETERIZATION_RESET_VARIANTS);
      commit(SET_RENDERINGS_HISTORY, { history: [], displayedId: 0 });
      commit(SET_CONTENT_ITEM_ERROR, null);
    },
    [TOGGLE_PANELS]({ commit, state, rootState }, { panel, action }) {
      const { form: paramsForm } = rootState.legacyParams;
      const closingParamsDirectly = panel === PARAMETERS_PANEL && action === CLOSE_PANEL;
      const closingParamsByOtherPanel = (
        state.showParametersPanel &&
        panel !== PARAMETERS_PANEL &&
        action === OPEN_PANEL
      );

      // This toggle callback function runs:
      // - Immediately if there are no changes in parameters panel
      // - Later by a legacy params action and only when confirming to discard changes.
      const toggleCallback = async() => {
        if (closingParamsByOtherPanel || closingParamsDirectly) {
          // Clear params data
          commit(LEGACY_PARAMS_CLEAR);
        }

        switch (panel) {
          case LOGS_PANEL:
            commit(SET_LOGS_PANEL_VISIBILITY, action);
            break;
          case SETTINGS_PANEL:
            commit(SET_SETTINGS_PANEL_VISIBILITY, action);
            break;
          case PARAMETERS_PANEL:
            commit(SET_PARAMETERS_PANEL_VISIBILITY, action);
            break;
          default:
            break;
        }
      };

      // If params panel has changes, show confirm modal
      // and call original toggle when ignoring changes.
      if (paramsForm.dirty) {
        commit(LEGACY_PARAMS_SET_UNSAVED_MODAL, {
          show: true,
          ignoreCallback: toggleCallback,
        });
        return;
      }

      return toggleCallback();
    },
    [SET_NAV_DESTINATION_TAB]({ commit }, { destinationTab }) {
      commit(SET_NAVIGATION, { destinationTab });
      setTimeout(
        () => commit(CLEAR_NAVIGATION),
        500
      );
    }
  },
};
/* eslint-enable no-shadow */
