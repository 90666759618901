<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup lang="ts">
import { App } from '@/api/dto/app.js';
import isEmpty from 'lodash/isEmpty';
import { computed, onBeforeUpdate, ref } from 'vue';
import { useStore } from 'vuex';
import SummarizedContentItem from './SummarizedContentItem.vue';
import TabbedSearchResults from './TabbedSearchResults.vue';

interface Props {
  items: (typeof App)[];
}

const props = defineProps<Props>();

const emptyStateMessages = {
  'my-work': 'You own no content matching this query.',
  bookmarks: 'You have no bookmarks matching this query. Try bookmarking something!',
  'shared-with-me': 'There is no content matching this query that is shared with you.',
  all: 'No content found for this query.',
};

const store = useStore();

const activeTab = ref('my-work');
const activeItems = ref(props.items);

const currentUser = computed(() => store.state.currentUser.user);

const getTabs = () =>
  currentUser.value.isViewer()
    ? [
      {
        id: 'shared-with-me',
        name: 'Shared With Me',
        items: props.items.filter(item => item.ownerGuid !== currentUser.value.guid),
        emptyMessage: emptyStateMessages['shared-with-me'],
      },
      {
        id: 'bookmarks',
        name: 'Bookmarks',
        items: props.items.filter(item => item.bookmarked),
        emptyMessage: emptyStateMessages.bookmarks,
      },
      { id: 'all', name: 'All', items: props.items, emptyMessage: emptyStateMessages.all },
    ]
    : [
      {
        id: 'my-work',
        name: 'My Work',
        items: props.items.filter(item => item.ownerGuid === currentUser.value.guid),
        emptyMessage: emptyStateMessages['my-work'],
      },
      {
        id: 'bookmarks',
        name: 'Bookmarks',
        items: props.items.filter(item => item.bookmarked),
        emptyMessage: emptyStateMessages.bookmarks,
      },
      {
        id: 'shared-with-me',
        name: 'Shared with me',
        items: props.items.filter(item => item.ownerGuid !== currentUser.value.guid),
        emptyMessage: emptyStateMessages['shared-with-me'],
      },
      { id: 'all', name: 'All', items: props.items, emptyMessage: emptyStateMessages.all },
    ];

const getActiveItems = () => getTabs().find(t => t.id === activeTab.value)?.items || [];
const getEmptyMessage = () => getTabs().find(t => t.id === activeTab.value)?.emptyMessage;

const onTabSelect = tab => {
  activeTab.value = tab;
  activeItems.value = getActiveItems();
};

onBeforeUpdate(() => {
  activeItems.value = getActiveItems();
});
</script>

<template>
  <TabbedSearchResults
    :tabs="getTabs()"
    :active-tab="activeTab"
    @tab-select="onTabSelect"
  />

  <div
    v-if="isEmpty(activeItems)"
    class="empty-state"
  >
    <p class="empty-state-message">
      {{ getEmptyMessage() }}
    </p>
  </div>

  <SummarizedContentItem 
    v-for="item in activeItems"
    v-else
    :key="item.id"
    :item="item"
  />
</template>

<style lang="scss" scoped>
@import 'Styles/shared/colors.scss';

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin-bottom: 1rem;

  &-message {
    font-size: 1.5rem;
    color: $color-medium-dark-grey;
    font-style: italic;
  }
}
</style>
