<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup>
import FeatureUsage from '@/views/system/info/FeatureUsage.vue';
import About from '@/views/system/info/About.vue';
import MailSettings from '@/views/system/info/MailSettings.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const isAdmin = computed(() => store.state.currentUser.user.isAdmin());
</script>

<template>
  <div class="system-view">
    <div>
      <h1
        class="sectionTitle"
        data-automation="system-info"
      >
        System Info
      </h1>
      <About /> 
      <div v-if="isAdmin">
        <MailSettings />
      </div>
    </div>
    <FeatureUsage /> 
  </div>
</template>

<style lang="scss" scoped>
.system-view {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  @media (max-width: 1024px) {
    display: block;
    gap: 0;
  }
}
</style>
