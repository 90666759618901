<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div>
    <RSInputCheckbox
      v-model="sendEmail"
      name="send-enabled"
      data-automation="schedule__email"
      label="Send email after update"
      :disabled="emailDisabled"
      @change="emitChange"
    />

    <div
      v-if="sendEmail"
      data-automation="schedule__email-full-controls"
    >
      <p
        v-if="canEditVariant"
        class="owners-opt-out"
      >
        Owners are always notified
        <span v-if="isAppOwner">
          unless they
          <RouterLink
            class="link-like-button"
            data-automation="schedule__email-opt-out"
            :to="{ name: 'apps.subscriptions', params: { guid: app.guid } }"
          >
            opt-out
          </RouterLink>

        </span>
      </p>

      <span
        class="send-collaborators vertical-space"
        :class="{ 'disabled-control': !canEditVariant }"
      >
        <RSInputCheckbox
          v-model="sendToCollabs"
          name="send-collaborators"
          data-automation="schedule__email-collabs"
          label="Send to all collaborators"
          :disabled="!canEditVariant"
          @change="emitChange"
        />
      </span>

      <span
        class="send-viewers"
        :class="{ 'showing-mail-all': mailAllEnabled, 'disabled-control': !canEditVariant }"
      >
        <RSInputCheckbox
          v-model="sendToViewers"
          name="send-viewers"
          data-automation="schedule__email-viewers"
          :label="sendViewersLabel"
          :disabled="!canEditVariant"
          @change="emitChange"
        />
      </span>

      <!-- When mail all is configured -->
      <RSRadioGroup
        v-if="mailAllEnabled"
        v-model="mailAllValue"
        name="mail-all-viewers"
        class="mail-all-viewers"
        :class="{ 'disabled-control': !canEditVariant }"
        data-automation="schedule__email-all"
        :options="mailAllOptions"
        @change="emitChange"
      />

      <div
        class="rs-field__help-label"
      >
        Additional Recipients
      </div>
      <PrincipalSearchSelect
        v-if="canEditVariant"
        name="send-additional"
        data-automation="schedule__email-additional-search"
        type="user"
        label="Additional Recipients"
        :server-settings="{ authentication: { externalUserSearch: false } }"
        @select="addRecipient"
      />

      <div
        class="additional-recipients-list"
        data-automation="schedule__email-additional-list"
      >
        <div
          v-for="(recp, index) in additionalRecipients"
          :key="recp.displayName"
          :data-automation="`schedule__email-additional-${index}`"
          class="additional-recipient"
        >
          <RSPrincipalInfo
            :initials="recp.displayInitials"
            :name="recp.displayName"
          />
          <button
            v-if="canEditVariant"
            class="additional-recipient__remove"
            :data-automation="`schedule__email-remove-recipient-${index}`"
            :aria-label="`Remove ${recp.displayName} from additional recipients`"
            @click="() => removeRecipient(index)"
          />
        </div>
      </div>

      <p
        v-if="viewersRestriction"
        data-automation="schedule__email-viewer-restriction"
      >
        Information about additional recipients is not shown due to privacy concerns.
      </p>
    </div>
  </div>
</template>

<script>
import AccessTypes from '@/api/dto/accessType';
import PrincipalSearchSelect from '@/components/PrincipalSearchSelect';
import RSInputCheckbox from '@/elements/RSInputCheckbox.vue';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo.vue';
import RSRadioGroup from '@/elements/RSRadioGroup.vue';
import {
  SHOW_ERROR_MESSAGE,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { RouterLink } from 'vue-router';
import { mapActions } from 'vuex';

const ALL = AccessTypes.All;
const ACL = AccessTypes.Acl;

export default {
  name: 'ScheduleEmail',
  components: {
    RSRadioGroup,
    RSInputCheckbox,
    RSPrincipalInfo,
    PrincipalSearchSelect,
    RouterLink,
  },
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
    serverSettings: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    app: {
      type: Object,
      required: true,
    },
    accessList: {
      type: Array,
      required: true,
    },
    accessGroups: {
      type: Array,
      required: true,
    },
    collabs: {
      type: Boolean,
      required: true,
    },
    viewers: {
      type: Boolean,
      required: true,
    },
    all: {
      type: Boolean,
      required: true,
    },
    subscribers: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change'],
  data() {
    const canEditVariant = this.currentUser.isAppEditor(this.app);
    return {
      canEditVariant,
      sendEmail: this.enabled,
      sendToCollabs: this.collabs,
      sendToViewers: this.viewers || this.all,
      additionalRecipients: [...this.subscribers],
      mailAllValue: this.all ? ALL : ACL,
      mailAllOptions: [
        {
          value: ACL,
          label: 'listed in the Access panel',
          disabled: !canEditVariant,
        },
        {
          value: ALL,
          label: 'all users in the system (Server Broadcast)',
          disabled: !canEditVariant,
        },
      ],
    };
  },
  computed: {
    emailDisabled() {
      return !this.canEditVariant || !this.serverSettings.mailConfigured;
    },
    sendViewersLabel() {
      return `Send to all viewers${this.mailAllEnabled ? ':' : ''}`;
    },
    isAppOwner() {
      return this.currentUser.isAppOwner(this.app);
    },
    mailAllEnabled() {
      return this.serverSettings.mailAll && this.app.accessType !== AccessTypes.Acl;
    },
    viewersRestriction() {
      return (
        this.serverSettings.viewersCanOnlySeeThemselves &&
        !this.currentUser.canPublish()
      );
    },
  },
  methods: {
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    userInList(user, list) {
      return !!list.find(u => u.guid === user.guid);
    },
    subscriberHasAccess(rec) {
      if (this.app.accessType !== AccessTypes.Acl) {
        return true;
      }
      const userListed = this.userInList(rec, this.accessList);
      const userInGroups = !!this.accessGroups.find(g => {
        return this.userInList(rec, g.members || []);
      });
      return userListed || userInGroups;
    },
    addRecipient(rec) {
      if (rec.guid === this.app.ownerGuid) {
        this.setInfoMessage({
          message: 'The content owner does not need to be added as a recipient.',
          autoHide: false,
        });
        return;
      }

      if (!this.subscriberHasAccess(rec)) {
        this.setErrorMessage({
          message: 'User must have permission to view the document in order to be subscribed to it.'
        });
        return;
      }

      this.additionalRecipients.push(rec);
      this.emitChange();
    },
    removeRecipient(pos) {
      this.additionalRecipients.splice(pos, 1);
      this.emitChange();
    },
    async emitChange() {
      await this.$nextTick();
      this.$emit('change', {
        sendEmail: this.sendEmail,
        collaborators: this.sendToCollabs,
        viewers: this.sendToViewers,
        mailAll: this.mailAllValue === ALL,
        additionalRecipients: this.additionalRecipients,
      });
    },
  },
};
</script>

<style lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.disabled-control .rs-field label,
.disabled-control .rs-radiogroup label {
  color: $color-dark-grey;
}

.owners-opt-out {
  font-size: 0.74rem;
}

.send-viewers .rs-field {
  margin-bottom: 0.9rem;
}

.send-collaborators .rs-field,
.showing-mail-all .rs-field {
  margin-bottom: 0;
}

.vertical-space .rs-field {
  margin-top: 10px;
}

.mail-all-viewers {
  padding-left: 1rem;
}

.additional-recipient {
  border-bottom: 1px solid $color-light-grey-3;
  display: flex;
  justify-content: space-between;
  padding: 0.6rem;

  &__remove {
    width: $rs-icon-size;
    height: $rs-icon-size;
    margin: 0;
    background-color: transparent;
    color: $color-secondary-inverse;
    background-position: center;
    background-size: $rs-icon-size $rs-icon-size;
    background-repeat: no-repeat;
    background-image: url('/images/elements/close.svg');
  }
}
</style>
