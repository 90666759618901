<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="access__access-list">
    <RSInformationToggle>
      <template #title>
        Who can view or change this {{ ContentTypeLabel[contentType] }}
      </template>
      <template #help>
        <p>
          A viewer will be able to access and view the content.
          Any type of account can be made a viewer for a given piece of content.
        </p>
        <p>
          A collaborator will be able to publish content updates and manage
          the settings for a given deployment. Collaborators must have either
          "publisher" or "administrator" accounts.
        </p>
      </template>
    </RSInformationToggle>

    <ul
      class="access__access-list__listing"
      data-automation="principal-access-list"
    >
      <li
        v-for="principal in filteredPrincipals"
        :key="principal.guid"
        :class="{'rs-principal': true, 'blur-highlight': principal.highlight}"
        data-automation="principal-item"
      >
        <div
          v-if="principal.displayInitials"
          class="rs-principal__icon rs-principal__icon--initials"
        >
          <div class="rs-principal__initials">
            {{ principal.displayInitials }}
          </div>
        </div>
        <div
          v-else-if="isGroup(principal)"
          class="rs-principal__icon rs-principal__icon--group"
        />
        <div class="access__access-list__user-section">
          <div
            v-if="isUser(principal) || isOwner(principal)"
            class="access__access-list__user-area"
          >
            <span
              v-if="principal.username !== ''"
              :title="`${principal.displayName} ${principal.username}`"
            >
              <RouterLink
                :to="{ name: 'people.users.profile', params: { guid: principal.guid } }"
              >
                {{ truncate(principal.displayName, 18) }}
              </RouterLink>
              {{ truncate(principal.username, 18) }}
            </span>
            <span
              v-else
              :title="principal.displayName"
            >
              {{ truncate(principal.displayName, 19) }}
            </span>
            <span
              v-if="principal.displayStatuses"
              class="rs-principal__info--secondary"
            >
              ({{ principal.displayStatuses }})
            </span>
          </div>
          <div
            v-else-if="isGroup(principal)"
            class="access__access-list__user-area"
          >
            <RouterLink
              :to="{ name: 'people.groups.edit', params: { groupId: principal.guid } }"
              :title="principal.name"
            >
              {{ truncate(principal.name, 18) }}
            </RouterLink>
          </div>
        </div>
        <ModeSelector
          v-if="!isOwner(principal)"
          :mode-value="modeSelectorValue(principal)"
          class="access__access-list__principal-mode-selector"
          in-principal
          :enabled="modeSelectorEnabled(principal)"
          @select="onSelectMode($event, principal)"
        />
        <button
          :disabled="!showRemoveButton(principal)"
          class="access__access-list__remove-item"
          aria-label="Remove principal"
          data-automation="principal-remove"
          @click="removePrincipal(principal)"
        />
      </li>
    </ul>

    <div class="buttons">
      <RSButton
        v-if="canEditPermissions"
        data-automation="remove-all-principals"
        icon="images/elements/actionDelete.svg"
        type="link"
        label="Remove All Groups and Users"
        size="small"
        :disabled="filteredPrincipals.length === 1"
        @click="onRemoveAll"
      />
    </div>

    <div
      v-if="owner.username === ''"
      class="access__access-list__privacy"
    >
      <div class="rs-field__text">
        <span>
          <p>
            Some information about who can view or change
            this {{ ContentTypeLabel[contentType] }} is not shown due to privacy concerns.
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ContentTypeLabel } from '@/api/dto/app';
import AppRoles from '@/api/dto/appRole';
import UserRoles from '@/api/dto/userRole';
import RSButton from '@/elements/RSButton.vue';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import { truncate } from '@/utils/truncate.filter';
import ModeSelector from './ModeSelector';
import { RouterLink } from 'vue-router';

export default {
  name: 'PrincipalList',
  components: {
    ModeSelector,
    RSButton,
    RSInformationToggle,
    RouterLink,
  },
  props: {
    principals: {
      type: Array,
      required: true,
    },
    canEditPermissions: {
      type: Boolean,
      required: true,
    },
    currentUserGuid: {
      type: String,
      required: true,
    },
    owner: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      required: true,
    }
  },
  emits: [
    'updatePrincipal',
    'removePrincipal',
  ],
  data: () => ({ ContentTypeLabel }),
  computed: {
    filteredPrincipals() {
      return [this.owner, ...this.principals.filter(p => !p.deleted)];
    }
  },
  methods: {
    truncate,
    modeSelectorValue(principal) {
      return principal.appRole && AppRoles.stringOf(principal.appRole);
    },
    modeSelectorEnabled(principal) {
      return this.canEditPermissions && !(this.isUser(principal) && this.isViewer(principal));
    },
    showRemoveButton(principal) {
      return !this.isOwner(principal) &&
        (this.canEditPermissions || this.currentUserGuid === principal.guid);
    },
    isUser(principal) {
      return principal.type === 'user';
    },
    isViewer(principal) {
      return principal.userRole === UserRoles.Viewer;
    },
    isGroup(principal) {
      return principal.type === 'group';
    },
    isOwner(principal) {
      return principal.guid === this.owner.guid;
    },
    onSelectMode(mode, principal) {
      this.$emit('updatePrincipal', { principal, mode });
    },
    removePrincipal(principal) {
      this.$emit('removePrincipal', principal);
    },
    onRemoveAll() {
      this.filteredPrincipals.forEach(principal => {
        if (!this.isOwner(principal)) {
          this.removePrincipal(principal);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.access__access-list {
  padding: 0;
  &__listing {
    padding: 10px 0;
    li {
      background: $color-white;
      padding: 7px;
      margin: 0;
      border-bottom: 1px dotted $color-light-grey-2;
    }
  }
  &__info-notice {
    padding: 10px;
  }
  &__user-area {
    margin-left: 0.5rem;
  }
  &__principal-mode-selector {
    flex: 1;
    &.mode-selector__button {
      border: 0;
    }
    :deep(.open) {
      // this moves the mode-selector dropdown in line with principal
      // without affecting its placement when used within other components
      right: 9px;
    }
  }
  &__user-section {
    display: flex;
    flex: 4;
  }
  &__remove-item {
    width: $rs-icon-size;
    height: $rs-icon-size;
    margin: 0;
    background-color: $color-white;
    color: $color-secondary-inverse;
    background-position: center;
    background-size: $rs-icon-size $rs-icon-size;
    background-repeat: no-repeat;

    &:enabled {
      background-image: url('/images/elements/close.svg');
    }
  }

  .rs-principal {
    display: flex;
    align-items: center;
    vertical-align: middle;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;

    &__icon {
      display: flex;
      align-items: center;
      width: $rs-icon-size;
      height: $rs-icon-size;
      min-width: $rs-icon-size;
      border-radius: calc($rs-icon-size / 2);
      overflow: hidden;

      &--initials {
        background-color: $color-secondary;
      }

      &--group {
        background-image: url('/images/elements/iconGroup.svg');
        background-repeat: no-repeat;
        background-size: $rs-icon-size $rs-icon-size;
      }
    }

    &__initials {
      margin: auto;
      font-size: 1rem;
      font-weight: 300;
      padding-top: 2px;
      transform: rotate(-15deg);
      color: $color-secondary-inverse;
    }

    &__info {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 0.5rem;

      &--secondary {
        color: $color-dark-grey;
        font-size: $rs-font-size-smallest;
        margin-top: 3px;
      }
    }

    &.blur-highlight {
      box-shadow: 0 3px 6px 6px rgba($color-primary, 0.3);
      position: relative;
      z-index: 1;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

  }
}
</style>
