<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup lang="ts">
import { App } from '@/api/dto/app.js';
import { User } from '@/api/dto/user.js';
import BookmarkButton from '@/components/BookmarkButton.vue';
import { serverPath } from '@/utils/paths';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { onBeforeMount, ref } from 'vue';
import { RouterLink } from 'vue-router';
import { contentTypeLabel } from './contentTypes';

const props = defineProps<Props>();

dayjs.extend(LocalizedFormat);

interface Props {
  item: typeof App;
}

const itemRef = ref(props.item);

const contentThumbnailPath = contentGuid => {
  return serverPath(`content/${contentGuid}/__thumbnail__`);
};

const contentThumbnailFallback = (evt, app) => {
  const imgType = app.thumbnailType();
  evt.target.src = `images/contenttype/${imgType}.svg`;
  evt.target.classList.add('content-summarized-item__image--default');
};

onBeforeMount(() => {
  itemRef.value.ownerDisplayname = new User(itemRef.value.owner).displayName;
});
</script>

<template>
  <div class="item-row">
    <img
      class="item-row__image"
      :alt="item.displayName"
      :src="contentThumbnailPath(item.guid)"
      data-automation="summarized-item-thumbnail"
      @error="evt => contentThumbnailFallback(evt, item)"
    >
    <div class="item-row__details">
      <div class="item-row__details-top">
        <RouterLink
          :to="{ name: 'apps', params: { guid: item.guid } }"
          class="item-row__details-title"
        >
          {{ item.contentDisplayName() }}
        </RouterLink>
      </div>
      <p class="item-row__details-description">
        {{ item.description }}
      </p>
      <div class="item-row__details-attributes">
        <span
          v-if="item.appMode"
          class="item-row__details-attributes-type"
        >
          Type: {{ contentTypeLabel(item.appMode) }}
        </span>
        <span
          v-if="item.owner"
          class="item-row__details-attributes-owner"
        >
          Owner:
          <RouterLink
            :to="{ name: 'people.users.profile', params: { guid: item.owner.guid } }"
          >
            {{ itemRef.ownerDisplayname }}
          </RouterLink>
        </span>
        <span
          v-if="item.lastDeployedTime"
          class="item-row__details-attributes-lastpublished publish-date"
        >
          Last Published: {{ dayjs(item.lastDeployedTime).format('lll') }}
        </span>
      </div>
    </div>

    <BookmarkButton
      :bookmarked="itemRef.bookmarked"
      :guid="item.guid"
      @created="itemRef.bookmarked = true"
      @deleted="itemRef.bookmarked = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/colors.scss';
@import 'Styles/shared/mixins.scss';

.item-row {
  border-bottom: 1px solid $color-posit-light-blue-1;
  display: flex;
  gap: .7rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  max-width: 1200px;

  &__image {
    align-self: center;
    border-radius: 5px;
    height: 5rem;
    width: 6.25rem;
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-top: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;

    &-top {
      align-items: center;
      display: flex;
      margin-bottom: .75rem;
    }

    &-title {
      color: $color-posit-dark-gray-1;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.7rem;
    }

    &-description {
      color: $color-posit-gray;
      display: -webkit-box;
      font-size: .9rem;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-bottom: .75rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-name {
      display: flex;
      align-items: center;
    }

    &-attributes {
      color: $color-posit-light-gray-3;
      font-size: .8rem;
      line-height: 1rem;

      span {
        margin-bottom: .1rem;
        margin-right: .5rem;
        width: 10rem;
      }

      &-lastpublished.publish-date {
        width: 14rem;
      }

      @include for-medium-screens {
        flex-direction: column;
        width: 100%;

        span {
          margin-bottom: .5rem;
          width: 100%;
        }
      }
    }
  }
}
</style>
