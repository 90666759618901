// Copyright (C) 2024 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '@/utils/paths';
import { keysToCamel, keysToSnake } from '@/api/transform';

export const getUserSessions = () => axios.get(apiV1Path('oauth/sessions')).then(({ data }) => keysToCamel(data));

export const getAvailableIntegrations = () => axios.get(apiV1Path('oauth/integrations')).then(({ data }) => keysToCamel(data));

export const getContentAssociations = (guid) => axios
  .get(apiV1Path(`content/${encodeURIComponent(guid)}/oauth/integrations/associations`))
  .then(({ data }) => keysToCamel(data));

export const setContentAssociations = (guid, assocations) => axios
  .put(apiV1Path(`content/${encodeURIComponent(guid)}/oauth/integrations/associations`), keysToSnake(assocations))
  .then(({ data }) => keysToCamel(data));

export const getIntegrationInfo = (guid) => axios.get(apiV1Path(`oauth/integrations/${encodeURIComponent(guid)}`))
  .then(({ data }) => keysToCamel(data));

export const createIntegration = (integration) => axios.post(apiV1Path('oauth/integrations'), keysToSnake(integration))
  .then(({ data }) => keysToCamel(data));

export  const updateIntegration = (guid, integration) => axios
  .patch(apiV1Path(`oauth/integrations/${encodeURIComponent(guid)}`), keysToSnake(integration))
  .then(({ data }) => keysToCamel(data));

export  const verifyServiceAccountIntegration = (guid) => axios
  .post(apiV1Path(`oauth/integrations/${encodeURIComponent(guid)}/verify`))
  .then(({ data }) => keysToCamel(data));

export const deleteIntegration = (guid) => axios.delete(apiV1Path(`oauth/integrations/${encodeURIComponent(guid)}`))
  .then(({ data }) => keysToCamel(data));

export const getIntegrationTemplate = (template) => axios.get(apiV1Path(`oauth/templates/${encodeURIComponent(template)}`))
  .then(({ data }) => keysToCamel(data));

export const getTemplatesList = () => axios.get(apiV1Path('oauth/templates')).then(({ data }) => keysToCamel(data));
