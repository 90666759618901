<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup>
import { markFeatureUsed } from '@/api/featureUsage';
import { serverBasePath, serverURL, urlWithPaths } from '@/utils/paths';
import { isEmpty } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  dropdown: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const app = computed(() => store.state.contentView.app);
const appHistoryDisplayedId = computed(() => store.state.bundles.displayedId);
const adhocVariant = computed(() => store.state.legacyParams.adhocVariant);
const serverSettings = computed(() => store.state.server.settings);
const variant = computed(() => store.state.parameterization.currentVariant);
const variantHistoryDisplayedId = computed(
  () => store.state.contentView.renderingHistory.displayedId
);
const dataAutomation = computed(() =>
  props.dropdown ? 'menuitem-open-solo--dropdown' : 'menuitem-open-solo');
const classes = computed(() => ['openSolo', props.dropdown ? 'narrow' : 'wide']);
const openSoloURL = computed(() => {
  // NOTE: This used to come from `app.contentUrl`, but was changed to support
  // customer environments struggling with proxy configuration.
  let url = '';

  if (serverSettings.value?.useWindowLocation) {
    if (app.value?.vanityUrl) {
      try {
        const vanityUrl = new URL(app.value.vanityUrl);
        const vanityPath = vanityUrl.pathname;

        if (vanityPath.startsWith(serverBasePath)) {
          url = serverURL(vanityPath.replace(serverBasePath, ''));
        } else {
          url = serverURL(vanityUrl.pathname);
        }
      } catch {
        // Unable to parse vanity URL, fallback to content URL
        url = '';
      }
    }

    // If we failed to map a vanity URL, just use a content URL
    if (!url) {
      url = serverURL(`content/${app.value.guid}/`);
    }
  } else {
    url = app.value?.vanityUrl || app.value?.contentUrl;
  }

  const paths = [];
  if (!isEmpty(variant.value)) {
    if (!isEmpty(adhocVariant.value)) {
      paths.push(`v${adhocVariant.value.key}`);
    } else if (!variant.value.isDefault) {
      paths.push(`v${variant.value.key}`);
    }

    if (showHistoricalVariant.value) {
      paths.push(`_rev${variantHistoryDisplayedId.value}/`);
    }
  } else if (
    appHistoryDisplayedId.value > 0 &&
    appHistoryDisplayedId.value !== String(app.value.bundleId)
  ) {
    paths.push(`_rev${appHistoryDisplayedId.value}`);
  }

  return urlWithPaths(url, paths);
});
const showHistoricalVariant = computed(
  () => isEmpty(adhocVariant.value) && variantHistoryDisplayedId.value !== variant.value.renderingId
);
const runAction = () => {
  markFeatureUsed('open_solo');
  window.open(openSoloURL.value, '_blank');
};
</script>

<template>
  <button
    type="button"
    aria-label="Open Solo (opens a new window)"
    title="Open Solo"
    class="action content-actions__open-solo-button"
    :class="classes"
    :data-automation="dataAutomation"
    :disabled="!serverSettings"
    @click="runAction"
  >
    <span
      v-if="props.dropdown"
      class="actionTitle"
    >
      Open Solo
    </span>
  </button>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';

.openSolo {
  background-image: url(Images/elements/actionSolo.svg);
  &.wide {
    display: inline-block;
  }

  &.narrow {
    display: none;
  }
}

@include for-medium-screens {
  .openSolo {
    &.wide {
      display: none;
    }

    &.narrow {
      display: block;
    }
  }
}
</style>
