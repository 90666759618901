// Copyright (C) 2025 by Posit Software, PBC.

import { useStore } from 'vuex';
import { ExecutionTypeK8S } from '@/api/serverSettings';

export const peopleTabs = (loc) => {
  const store = useStore();
  const isAdmin = store.state.currentUser.user.isAdmin();
  const serverSettings = store.state.server.settings;

  const items = [];
  if ((!serverSettings.authentication.externalGroupId
    || serverSettings.authentication.externalGroupSearch)
    && serverSettings.authentication.groupsEnabled) {
    items.push(
      {
        label: 'Users',
        id: 'people.users',
        class: 'users',
        testId: `${loc}-nav-users`
      },
      {
        label: 'Groups',
        id: 'people.groups',
        class: 'groups',
        testId: `${loc}-nav-groups`
      },
    );
  }
  if (isAdmin) {
    items.push({
      label: 'User Metrics',
      id: 'people.metrics',
      class: 'metrics',
      testId: `${loc}-nav-user-metrics`
    });
  }

  return items;
};

export const systemTabs = (loc) => {
  const store = useStore();
  const isAdmin = store.state.currentUser.user.isAdmin();
  const serverSettings = store.state.server.settings;

  return [
    {
      id: 'system.info',
      testId: `${loc}-nav-info`,
      class: 'info',
      label: 'Info',
      visible: true,
    }, {
      id: 'system.metrics',
      testId: `${loc}-nav-metrics`,
      class: 'metrics',
      label: 'Metrics',
      visible: true,
    }, {
      id: 'system.tags',
      testId: `${loc}-nav-tags`,
      class: 'tags',
      label: 'Tags',
      visible: isAdmin, // will be updated when read-only tags available
    }, {
      id: 'system.auditing',
      testId: `${loc}-nav-logs`,
      class: 'auditLogs',
      label: 'Audit Logs',
      visible: isAdmin,
    }, {
      id: 'system.schedules',
      testId: `${loc}-nav-scheduled`,
      class: 'scheduled',
      label: 'Scheduled Content',
      visible: isAdmin, // will be updated after API changes
    }, {
      id: 'system.checks',
      testId: `${loc}-nav-systemchecks`,
      class: 'systemChecks',
      label: 'System Checks',
      visible: isAdmin,
    }, {
      id: 'system.processes',
      testId: `${loc}-nav-processes`,
      class: 'processes',
      label: 'Processes',
      visible: isAdmin,
    }, {
      id: 'system.environments',
      testId: `${loc}-nav-environments`,
      class: 'environments',
      label: 'Environments',
      visible: serverSettings.executionType === ExecutionTypeK8S,
    }, {
      id: 'system.integrations',
      testId: `${loc}-nav-integrations`,
      class: 'integrations',
      label: 'Integrations',
      visible: serverSettings.oauthIntegrationsEnabled,
    },
  ].filter(tab => tab.visible);
};
