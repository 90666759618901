<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="rStudioHeader"
    class="connect-header"
  >
    <ServerNotices
      v-if="initialized"
      :ng-server="serverSettings"
    />
    <div class="band">
      <div class="innards">
        <div class="connect-header__left-side">
          <button
            v-if="isAuthenticated"
            id="menuToggler"
            class="connect-header__menuToggler"
            aria-label="Navigation Menu"
            :aria-controls="showNavPanel ? 'navPanel' : null"
            data-automation="menu-toggler"
            @click="toggleNavPanel"
          />
          <NotificationBadge
            v-if="showFeedNotification"
            class="whats-new-notification"
          />
          <RouterLink
            :to="{ name: 'root' }"
            aria-label="Home"
            data-automation="header-menu-logo"
          >
            <!-- eslint-disable vue/no-useless-v-bind -->
            <img
              class="connect-header__logo"
              :src="'__logo__'"
              alt=""
            >
            <!-- eslint-enable vue/no-useless-v-bind -->
          </RouterLink>
        </div>
        <div
          v-if="initialized"
          class="menu main"
        >
          <span
            v-if="isAuthenticated"
            data-automation="main-menu"
            class="menuItems"
          >
            <RouterLink
              :to="{ name: 'contentList' }"
              class="menuItem"
              :class="{ active: isActiveTab('contentList') }"
              data-automation="header-menu-content"
            >
              Content
            </RouterLink>
            <RouterLink
              :to="{ name: 'people' }"
              class="menuItem"
              :class="{ active: isActiveTab('people') }"
              data-automation="header-menu-people"
            >
              People
            </RouterLink>
            <RouterLink
              v-if="isPublisherOrAdmin"
              :to="{ name: 'system.info' }"
              class="menuItem"
              :class="{ active: isActiveTab('system.') }"
              data-automation="header-menu-system"
            >
              System
            </RouterLink>
            <RouterLink
              v-if="!viewerHideDocs"
              :to="{ name: 'docs' }"
              class="menuItem"
              :class="{ active: isActiveTab('docs') }"
              data-automation="header-menu-documentation"
            >
              Documentation
            </RouterLink>
          </span>
        </div>
        <div>
          <ServerSettingsKeeper />
          <CurrentUser
            v-if="initialized"
            id="currentUser"
            :server-settings="serverSettings"
          />
        </div>
      </div>
    </div>
    <StatusMessage />
  </div>
</template>

<script>
import { ExecutionTypeK8S } from '@/api/serverSettings';
import CurrentUser from '@/components/CurrentUser';
import ServerSettingsKeeper from '@/components/ServerSettingsKeeper.vue';
import NotificationBadge from '@/components/NotificationBadge.vue';
import StatusMessage from '@/components/StatusMessage';
import { SHOW_NAV_PANEL } from '@/store/modules/navigation';
import ServerNotices from '@/views/ServerNotices';
import { mapMutations, mapState } from 'vuex';
import { RouterLink } from 'vue-router';

export default {
  name: 'AppHeader',
  components: {
    ServerNotices,
    CurrentUser,
    StatusMessage,
    ServerSettingsKeeper,
    NotificationBadge,
    RouterLink,
  },
  computed: {
    initialized() {
      return !!this.serverSettings;
    },
    isAdmin() {
      return this.currentUser?.isAdmin();
    },
    isPublisher() {
      return this.currentUser?.isPublisher();
    },
    isPublisherOrAdmin() {
      return this.isAdmin || this.isPublisher;
    },
    viewerHideDocs() {
      return !this.isPublisherOrAdmin && this.serverSettings.hideViewerDocumentation;
    },
    shouldShowIntegrations() {
      return this.serverSettings.oauthIntegrationsEnabled && this.isPublisherOrAdmin;
    },
    shouldShowEnvironmentsMenu() {
      return Boolean(
        this.serverSettings.executionType === ExecutionTypeK8S &&
          this.isPublisherOrAdmin
      );
    },
    isActiveTab() {
      const currentPath = this.$route.name;
      return prefix => currentPath.startsWith(prefix);
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      serverSettings: state => state.server.settings,
      showNavPanel: state => state.navigation.showNavPanel,
      showFeedNotification: state => state.whatsNew.showNotification,
    })
  },
  methods: {
    ...mapMutations({
      showingNavPanel: SHOW_NAV_PANEL,
    }),
    toggleNavPanel() {
      if (this.isAuthenticated) {
        this.showingNavPanel(!this.showNavPanel);
      } else {
        this.$router.push({ name: 'welcome' });
      }
      this.$nextTick().then(() => {
        const title = document.querySelector('.menuSectionTitle a');
        title?.focus();
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.whats-new-notification {
  bottom: 6px;
  position: relative;
  right: 10px;
}

#rStudioHeader {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  z-index: 100;

  background-color: rgba(255, 255, 255, .98);

  @include drop-shadow(0, 3px, 15px, 0px, rgba(0,0,0,0.1));
  @include transition-property(left, top);
  @include normal-transition-duration();

  #menuToggler {
    background-color: $color-white;
    padding: 0;
    display: inline-block;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    vertical-align: bottom;
    @include control-visible-focus;
    background-image: url(/images/elements/menuToggler.svg);
    cursor: pointer;
  }

  .menuItems {
    display: inline-block;
  }

  .innards {
    padding: 5px 0;
    @include flex-space-between();
    align-items: center;

    @include transition-property(padding);
    @include slow-transition-duration();
  }

  .connect-header {
    &__left-side {
      align-items: center;
      display: flex;
    }

    &__logo {
      height: 2.72rem;
      margin-left: 1rem;
      max-width: 160px;
      width: auto;
    }
  }

  .server-settings-keeper {
    display: inline-block;
    margin-right: 1rem;
  }

  #currentUser {
    display: inline-block;
    height:30px;
    white-space: nowrap;
  }
}

.menuItem:focus-visible {
  border-color: transparent;
}

@media screen and (max-width: 1023px){
  #rStudioHeader {
    .menu.main {
      display: none;
    }
  }
}
</style>
