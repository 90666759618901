// Copyright (C) 2023 by Posit Software, PBC.

import { store } from '@/store';

/*
 * This module is built communicate through iframes when needed:

 * Embedded Content View: Content rendering in an iframe within the Dashboard.
 * The unauthorized view, which is a small JS application that shows up when
 * the user doesn't have permissions to see the content, needs to communicate with the
 * Dashboard's ACL when an administrator self-grants access to the application.
 *    a) Call to the iframe to re-render when the ACL changes.
 *    b) Call to the ACL to be updated when admin self-grants access via the unauthorized app.
 */

export const BroadcastMessageType = {
  Ping: 'RSC-Ping',
  PingResponse: 'RSC-Ping-Response',
  Event: 'RSC-Event',
  StateChange: 'RSC-State-Change',
  AdminContentAccess: 'RSC-Admin-Content-Access',
  SubTypeObject: {
    LoginFailure: {
      subType: 'LoginFailure',
    },
    LoginSuccess: {
      subType: 'LoginSuccess',
    },
  },
};

export class IframeBus {
  constructor() {
    this.lastHref = null;
    this.lastRoute = null;
    this.lastLoggedIn = false;
    this.lastLocation = null;
    this.loginSuccessMsgSent = false;
    this.checkStateInterval = null;
    this.routeNameProvider = () => '';
    this.adminContentAccessCallback = () => {};
    this.runningInIframe = !(
      window.self === window.top
      || window.location === window.parent.location
    );
    this.runningInPopup = window.opener && window.opener !== window;
    this.started = false;

    if ('BroadcastChannel' in window) {
      this.broadcastChannel = new BroadcastChannel('rstudio-connect');
      this.broadcastChannel.onmessage = this.processMessage.bind(this);
    } else {
      this.broadcastChannel = {
        postMessage: () => {},
        onmessage: () => {},
      };
    }
  }

  /**
   * Start polling and listening events.
   * Checks Dashbaord state immediately.
   * @param {Function} routeNameProvider A function that returns the actual route name.
   * @returns {Boolean} Whether it started polling or not.
   */
  Start(routeNameProvider) {
    // If start was done already, avoid duplicate services.
    // AND
    // Only when running within an iframe or pop-up,
    // send pings or check status to the parent.
    // E.g:
    // - Logging in via a pop up to embedded content
    const shouldPingState = this.runningInPopup || this.runningInIframe;
    if (this.started || !shouldPingState) {
      return false;
    }

    this.started = true;
    this.routeNameProvider = routeNameProvider;
    this.checkState();
    this.checkStateInterval = setInterval(this.checkState.bind(this), 1000);

    // ensure we get a chance to send a notification before leaving
    window.addEventListener('beforeunload', this.checkState.bind(this), true);

    return true;
  }

  /**
   * Set a callback function to be run when the bus detects an admin has self-granted
   * access to a piece of content.
   * @param {Function} fn The function to be run.
   */
  OnAdminContentAccessDo(fn) {
    this.adminContentAccessCallback = fn;
  }

  /**
   * Create the message body.
   * @param {String} type One of the message types from BroadcastMessageType.
   * @returns {Object} The message body.
   */
  createMessage(type) {
    const { isAuthenticated } = store.state.currentUser;
    return {
      type,
      current: this.routeNameProvider(),
      previous: this.lastRoute,
      loggedIn: isAuthenticated,
      location: location.pathname,
      href: location.href,
    };
  }

  /**
   * Create a message that includes event information.
   * The event information is specifically and solely used by the Setup Assistant.
   * @param {String} type One of the message types from BroadcastMessageType.
   * @param {Object} eventInfo One of the message subtype objects from BroadcastMessageType.SubTypeObject.
   * @returns {Object} The event-message body.
   */
  createEvent(type, eventInfo) {
    const msg = this.createMessage(type);
    msg.eventInfo = {
      ...eventInfo,
      dateTime: new Date(),
    };
    return msg;
  }

  /**
   * Broadcast a message to the parent window.
   * @param {Object} msg The message to be broadcasted to the parent window.
   */
  send(msg) {
    this.broadcastChannel.postMessage(msg);
    (window.opener || window.parent).postMessage(msg, '*');
  }

  /**
   * Method to collect the current state and broadcast a message if:
   * - Logged in status changes. (Login success is sent only once.)
   * - Route location changes in the dashboard.
   */
  checkState() {
    const msg = this.createMessage(BroadcastMessageType.StateChange);
    if (msg.loggedIn && !this.loginSuccessMsgSent) {
      const ev = this.createEvent(
        BroadcastMessageType.Event,
        BroadcastMessageType.SubTypeObject.LoginSuccess,
      );
      this.send(ev);
      this.send(msg);
      this.loginSuccessMsgSent = true;
    }
    if (
      msg.current !== this.lastRoute ||
      msg.loggedIn !== this.lastLoggedIn ||
      msg.location !== this.lastLocation ||
      msg.href !== this.lastHref
    ) {
      // send out the state change
      this.send(msg);
      // reset for the next interval comparison
      this.lastHref = msg.href;
      this.lastRoute = msg.current;
      this.lastLoggedIn = msg.loggedIn;
      this.lastLocation = msg.location;
    }
  }

  /**
   * Method that processes incoming messages and creates responses accordinly.
   * @param {Object} msg The incoming message.
   */
  processMessage(msg) {
    if (!msg || !msg.data) {
      return;
    }
    const response = this.createMessage(BroadcastMessageType.PingResponse);
    switch (msg.data.type) {
      case BroadcastMessageType.Ping:
        this.send(response);
        break;
      case BroadcastMessageType.AdminContentAccess:
        this.adminContentAccessCallback();
        this.send(response);
        break;
    }
  }
}

export const Bus = new IframeBus();
