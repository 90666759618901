// Copyright (C) 2022 by Posit Software, PBC.

import { keysToCamel, keysToSnake } from '@/api/transform';
import { apiPath, apiV1Path } from '@/utils/paths';
import axios from 'axios';

const ExecutionTypeK8S = 'launcher-kubernetes';
const ExecutionTypeNative = 'native';

const getRInstallations = () => axios.get(apiV1Path('server_settings/r')).then(response => {
  return response.data.installations;
});

const getPythonInstallations = () => axios.get(apiV1Path('server_settings/python')).then(response => {
  return response.data.installations;
});

const getQuartoInstallations = () => axios.get(apiV1Path('server_settings/quarto')).then(response => {
  return response.data.installations;
});

const getTensorFlowInstallations = () => axios.get(apiV1Path('server_settings/tensorflow')).then(response => {
  return response.data.installations;
});

const getMailSettings = () => axios
  .get(apiPath('server_settings/mail'))
  .then(({ data }) => keysToCamel(data));

const updateMailSettings = data => axios.put(apiPath('server_settings/mail'), keysToSnake(data));

const testMailSettings = () => axios.get(apiPath('server_settings/mail/test'));

const NANOSECOND = 1e9;
const getRuntimeDefaultsAndLimits = appMode => axios
  .get(apiPath(`server_settings/scheduler/${encodeURIComponent(appMode)}`))
  .then(({ data }) => keysToCamel(data))
  .then(data =>
    Object.keys(data).reduce((acc, key) => {
      if (key.match('Timeout')) {
        acc[key] = data[key] / NANOSECOND;
        return acc;
      }
      acc[key] = data[key];
      return acc;
    }, {}));

const getServerSettings = () => axios.get(apiPath('server_settings'))
  .then(({ data }) => keysToCamel(data));

const getApplicationsSettings = () => axios.get(apiPath('server_settings/applications'))
  .then(({ data }) => keysToCamel(data));

export {
  ExecutionTypeK8S,
  ExecutionTypeNative,
  getApplicationsSettings,
  getMailSettings,
  getPythonInstallations,
  getQuartoInstallations, getRInstallations,
  getRuntimeDefaultsAndLimits,
  getServerSettings, getTensorFlowInstallations, testMailSettings,
  updateMailSettings
};

