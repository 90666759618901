<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { addNewRemoteGroup } from '@/api/groups';
import { addNewRemoteUser } from '@/api/users';
import PrincipalSearchSelect from '@/components/PrincipalSearchSelect';
import Spinner from '@/components/Spinner';
import RSButton from '@/elements/RSButton.vue';
import RSModal from '@/elements/RSModal.vue';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { computed, onBeforeMount, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  serverSettings: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close', 'import']);

const store = useStore();

const searchSelect = ref(null);

const localState = reactive({
  importAPIMethod: null,
  importInProgress: false,
  labels: {},
  selection: null,
  showSyncingMemberships: false,
  syncingMembershipsTimeout: null,
});

const disableButton = computed(
  () => !localState.selection || localState.importInProgress
);

onBeforeMount(() => {
  const availableTypes = ['group', 'user'];
  if (!availableTypes.includes(props.type)) {
    throw new Error(
      `:type '${props.type}' is invalid. Must be one of: ${availableTypes.join(
        ', '
      )}`
    );
  }

  // Define upfront, the local labels and methods to be used
  // related to entity type (user or group)
  switch (props.type) {
    case 'user':
      localState.importAPIMethod = addNewRemoteUser;
      localState.labels = {
        subject: 'Add User',
        details: 'Search for the User to be added to Posit Connect.',
        searchLabel: 'Search for users',
        buttonLabel: 'Add User',
        successMessageFor: userName => `User ${userName} added.`,
      };
      break;
    case 'group':
      localState.importAPIMethod = addNewRemoteGroup;
      localState.labels = {
        subject: 'Import Group',
        details: `Search for the Group to be imported to ${props.serverSettings.systemDisplayName}.`,
        searchLabel: 'Search Groups',
        buttonLabel: 'Import Group',
        successMessageFor: groupName => `Group ${groupName} added.`,
      };
      break;
  }
});

onMounted(() => {
  searchSelect.value?.focusElement();
});

const handleChange = () => {
  // clear existing selection if search text changes
  if (localState.selection) {
    localState.selection = null;
  }
};
const handleSelection = selection => {
  localState.selection = selection;
};
const initSyncingMembershipsMsg = () => {
  if (props.type === 'group') {
    localState.syncingMembershipsTimeout = setTimeout(() => {
      localState.showSyncingMemberships = true;
    }, 300);
  }
};
const importEntity = () => {
  store.commit(CLEAR_STATUS_MESSAGE);
  localState.importInProgress = true;

  // If selection has GUID, it already exists in Connect
  if (localState.selection.guid) {
    emit('import', localState.selection);
    return;
  }
  initSyncingMembershipsMsg();
  return localState
    .importAPIMethod(localState.selection.tempTicket)
    .then(importSuccess)
    .catch(importError)
    .finally(() => {
      localState.importInProgress = false;
      clearTimeout(localState.syncingMembershipsTimeout);
    });
};

const importSuccess = newEntity => {
  emit('import', newEntity);
  store.dispatch(SHOW_INFO_MESSAGE, {
    message: localState.labels.successMessageFor(newEntity.displayName),
  });
};

const importError = err => {
  emit('close');
  store.commit(SET_ERROR_MESSAGE_FROM_API, err);
};

const onClose = () => {
  if (!localState.importInProgress) {
    emit('close');
  }
};
</script>

<template>
  <RSModal
    :active="true"
    :subject="localState.labels.subject"
    :closeable="!localState.importInProgress"
    @close="onClose"
  >
    <template #content>
      <div
        class="details rsc-text"
        :data-automation="`import-remote-${type}-msg`"
      >
        <p>
          {{ localState.labels.details }}
        </p>
      </div>
      <PrincipalSearchSelect
        ref="searchSelect"
        :label="localState.labels.searchLabel"
        :server-settings="serverSettings"
        :has-clear="true"
        :remote-lookup="true"
        :data-automation="`search-remote-${type}`"
        :name="`search-remote-${type}`"
        :type="type"
        @input="handleChange"
        @select="handleSelection"
      />
    </template>
    <template #controls>
      <div
        v-if="localState.showSyncingMemberships"
        class="syncing-members-msg"
      >
        <div class="syncing-members-msg__spinner">
          <Spinner />
        </div>
        <span> Importing group memberships... </span>
      </div>
      <RSButton
        v-if="!localState.showSyncingMemberships"
        :id="`import-${type}-submit`"
        :label="localState.labels.buttonLabel"
        :disabled="disableButton"
        @click="importEntity"
      />
    </template>
  </RSModal>
</template>

<style scoped>
.rsc-text p {
  margin-bottom: 1rem;
}

.syncing-members-msg {
  display: flex;
  line-height: 2rem;
}

.syncing-members-msg__spinner {
  height: 2rem;
  margin-right: 0.6rem;
  width: 2rem;
}
</style>
