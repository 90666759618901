// Copyright (C) 2025 by Posit Software, PBC.

const url = 'https://raw.githubusercontent.com/posit-dev/connect-extensions/refs/heads/main/extensions.json';

export async function getConnectGalleryList() {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('HTTP Error');
    } else {
      const data = await response.json();
      return data.extensions;
    }
  } catch (error) {
    throw new Error(`Fetch error: ${error.response}`);
  }
};
