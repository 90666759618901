// Copyright (C) 2025 by Posit Software, PBC.

import { apiV1Path } from '@/utils/paths';
import removeMultipleSpaces from '@/utils/removeMultipleSpaces';
import axios from 'axios';
import { keysToCamel, keysToSnake } from './transform';

/**
 * API calls for connect api tags
 */

/**
 * Fetch and transform the tags tree from api.
 * @returns {Promise} A Promise that resolves with an array containing the tags tree.
 */
export const getTagsTree = async() => {
  const tree = [];
  const tags = await getTags();

  // take the list of tags and turn it into a tree.
  const map = {};

  // Initialize the map with each tag
  tags.forEach(tag => {
    map[tag.id] = { ...tag, children: [] };
  });

  // Build the tree structure
  tags.forEach(tag => {
    if (tag.parentId === null) {
      // Top-level tag
      tree.push(map[tag.id]);
    } else if (map[tag.parentId]) {
      map[tag.parentId].children.push(map[tag.id]);
    }
  });

  return tree;
};

/**
 * Creates a new tag via api
 * @param {Object} payload The tag's properties.
 * @param {string} payload.name Required. The name for the new tag.
 * @param {number} payload.parentId The parent id for this tag. Required only for tags, not categories.
 * @returns {Promise} A Promise that resolves with the created tag.
 */
export const createTag = payload => {
  payload.name = removeMultipleSpaces(payload.name);
  const path = apiV1Path('tags');
  return axios.post(path, keysToSnake(payload))
    .then(response => keysToCamel(response.data));
};

/**
 * Deletes a tag via api
 * @param {Object} payload Properties of the tag to be deleted.
 * @param {(string|number)} payload.id Required. The tag's id.
 * @param {(string|number)} payload.version Required. The tag's version.
 * @returns {Promise} The executed Promise for the deletion.
 */
export const deleteTag = id => {
  const path = apiV1Path(`tags/${id}`);
  return axios.delete(path);
};

/**
 * Creates a new version for a given tag
 * @param {Object} payload The tag's properties
 * @param {string} payload.name Required. The name for the new tag.
 * @param {(string|number)} payload.id Required. The tag's id.
 * @param {(string|number)} payload.version Required. The tag's version.
 * @returns {Promise} A Promise that resolves with the new tag's version.
 */
// eslint-disable-next-line no-shadow
export const renameTag = ({ id, name, parentId }) => {
  const path = apiV1Path(`tags/${id}`);
  return axios.patch(path, keysToSnake({ name, parentId })).then(response => {
    return keysToCamel(response.data);
  });
};

// V1 Tags API
export const getTags = () =>
  axios.get(apiV1Path('tags')).then(({ data }) =>
    keysToCamel(data));
