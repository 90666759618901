<!-- Copyright (C) 2025 by Posit Software, PBC. -->
<template>
  <section
    class="connect-gallery-empty"
  >
    <div>
      <p 
        data-automation="connect-gallery-empty-message"
      >
        Oops, we can't load the gallery.
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.connect-gallery-empty {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  text-align: center;
  padding: 10rem 0 4rem 0;

  p {
    color: $color-dark-grey;
    margin-bottom: 1.375rem;
  }
}
</style>
