<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup>
import isEmpty from 'lodash/isEmpty';
import { computed } from 'vue';
import { useStore } from 'vuex';
import NotSchedulableMsg from './NotSchedulableMsg';
import ScheduleForm from './ScheduleForm';

const store = useStore();
const app = computed(() => store.state.contentView.app);
const currentVariant = computed(() => store.state.parameterization.currentVariant);
const isDeployed = computed(() => app.value.isDeployed());

const notSchedulable = computed(() => isDeployed.value && !app.value.isRenderable());
const canBeScheduled = computed(() =>
  isDeployed.value && 
  !isEmpty(currentVariant) &&
  app.value.isRenderable());
</script>

<template>
  <div data-automation="app-settings__schedule">
    <p
      v-if="!isDeployed"
      data-automation="app-settings__schedule-undeployed"
    >
      This has not been fully deployed. Please try publishing again.
    </p>

    <NotSchedulableMsg v-if="notSchedulable" />

    <ScheduleForm
      v-if="canBeScheduled"
      data-automation="schedule-tab__schedulable"
    />
  </div>
</template>