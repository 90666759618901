<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { getPermissions, getUser } from '@/api/users';
import {
  CLEAR_ACTIVITY_MESSAGE,
  SET_ACTIVITY_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';
import { computed, onBeforeMount, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import PublishedContentList from './PublishedContentList';
import UserInformation from './UserInformation';

const props = defineProps({
  guid: {
    type: String,
    required: true,
  },
});
const route = useRoute();
const store = useStore();

const localState = reactive({
  loaded: false,
  api: {
    profileFieldPermissions: null,
    userProfile: null,
  },

});

const currentUser = computed(() => store.state.currentUser.user);
const serverSettings = computed(() => store.state.server.settings);

onBeforeMount(() => {
  const page = route.name;

  store.commit(SET_ACTIVITY_MESSAGE, {
    page,
    message: 'Loading User Profile...',
  });

  return Promise.all([
    getUser(props.guid),
    getPermissions(props.guid),
  ])
    .then(
      ([
        userProfile,
        profileFieldPermissions,
      ]) => {
        localState.api.userProfile = userProfile;
        localState.api.profileFieldPermissions = profileFieldPermissions;
        localState.loaded = true;
      }
    )
    .catch(setErrorMessageFromAPI)
    .finally(() => {
      store.commit(CLEAR_ACTIVITY_MESSAGE, page);
    });
});

const setErrorMessageFromAPI = (error) => store.commit(SET_ERROR_MESSAGE_FROM_API, error);

const fetchUserProfile = async() => {
  try {
    localState.api.userProfile = await getUser(props.guid);
  } catch (error) {
    setErrorMessageFromAPI(error);
  }
};
</script>

<template>
  <div
    v-if="localState.loaded"
    class="majorMinorColumnsContainer minorLeft"
  >
    <div class="minorColumn">
      <UserInformation
        :current-user="currentUser"
        :profile-field-permissions="localState.api.profileFieldPermissions"
        :user-profile="localState.api.userProfile"
        :server-settings="serverSettings"
        @refresh-user-profile="fetchUserProfile"
      />
    </div>
    <div class="majorColumn">
      <PublishedContentList :user-name="localState.api.userProfile.username" />
    </div>
  </div>
</template>

