// Copyright (C) 2023 by Posit Software, PBC.

import ContentListView from '@/views/content/contentList/ContentListView.vue';
import AppHeader from '@/components/AppHeader/index.vue';
import ContentTypes from '@/views/content/contentList/contentType';

const contentListMeta = (defaultContentType = ContentTypes.All) => ({
  title: 'Content Listing',
  requireAuth: true,
  isContentList: true,
  defaultContentType,
});

export default [
  {
    path: '/content',
    redirect: () => ({ name: 'contentList' })
  },
  {
    path: '/content/listing',
    name: 'contentList',
    components: {
      default: ContentListView,
      header: AppHeader,
    },
    meta: contentListMeta(),
  },
  {
    path: '/content/apps',
    name: 'contentListApps',
    components: {
      default: ContentListView,
      header: AppHeader,
    },
    meta: contentListMeta(ContentTypes.Application),

  },
  {
    path: '/content/documents',
    name: 'contentListDocuments',
    components: {
      default: ContentListView,
      header: AppHeader,
    },
    meta: contentListMeta(ContentTypes.Document),
  },
  {
    path: '/content/plots',
    name: 'contentListPlots',
    components: {
      default: ContentListView,
      header: AppHeader,
    },
    meta: contentListMeta(ContentTypes.Plot),
  },
  {
    path: '/content/pins',
    name: 'contentListPins',
    components: {
      default: ContentListView,
      header: AppHeader,
    },
    meta: contentListMeta(ContentTypes.Pin),
  },
  {
    path: '/content/apis',
    name: 'contentListApis',
    components: {
      default: ContentListView,
      header: AppHeader,
    },
    meta: contentListMeta(ContentTypes.Api),
  },
  // Legacy routes
  {
    path: '/recent',
    redirect: () => ({ name: 'contentList' })
  },
  {
    path: '/apps',
    redirect: () => ({ name: 'contentListApps' })
  },
  {
    path: '/documents',
    redirect: () => ({ name: 'contentListDocuments' })
  },
  {
    path: '/reports',
    redirect: () => ({ name: 'contentListDocuments' })
  },
  {
    path: '/plots',
    redirect: () => ({ name: 'contentListPlots' })
  }
];
