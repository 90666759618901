<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed, nextTick, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { searchGroups } from '@/api/groups';
import BaseButton from '@/components/BaseButton';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import ImportRemoteEntityDialog from '@/components/ImportRemoteEntityDialog';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import RSPager from '@/elements/RSPager.vue';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo.vue';
import RSTable from '@/elements/RSTable.vue';
import RSTableCell from '@/elements/RSTableCell.vue';
import RSTableRow from '@/elements/RSTableRow.vue';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { groupPath } from '@/utils/paths';
import CreateGroupDialog from '@/views/groups/CreateGroupDialog';
import DeleteGroupDialog from '@/views/groups/DeleteGroupDialog';
import GroupsOptionsPanel from '@/views/groups/GroupsOptionsPanel';
import RenameGroupDialog from '@/views/groups/RenameGroupDialog';

const store = useStore();

const currentUser = computed(() => store.state.currentUser.user);

const serverSettings = computed(() => store.state.server.settings);

const localState = reactive({
  dataLoaded: false,
  loading: false,
  searchParameters: {
    prefix: '',
    currentPage: 1,
  },
  groups: [],
  highlightFlag: false,
  totalPages: 0,
  deleteTargetGroup: {},
  previousFocus: null,
  renameTargetGroup: { target: {} },
  showAddGroupDialog: false,
  showDeleteGroupDialog: false,
  showGroupRenameDialog: false,
  showOptionsPanel: true,
  tableHeaders: [{ label: 'Group', size: 3 }],
});

const addGroupButton = ref(null);
const optionsButton = ref(null);
const optionsPanel = ref(null);

const highlightClass = computed(() => {
  // Use a class that handles the highlight of new rows
  return rowIndex => (localState.highlightFlag && rowIndex === 0 ? 'highlight-once' : '');
});

const emptyResults = computed(() => !localState.groups.length);

const isLoading = computed(() => !localState.dataLoaded || localState.loading);

const showPager = computed(() => localState.totalPages > 1);

const disablePreviousPagination = computed(() => localState.searchParameters.currentPage === 1);

const disableNextPagination = computed(() =>
  localState.searchParameters.currentPage === localState.totalPages);

const authProviderName = computed(() => serverSettings.value.authentication.name);

const hasExternalGroupOwner = computed(() =>
  serverSettings.value.authentication.externalGroupOwner);

const hasExternalGroupMembers = computed(() =>
  serverSettings.value.authentication.externalGroupMembers);

const hasExternalGroupSearch = computed(() =>
  serverSettings.value.authentication.externalGroupSearch);

const enableAddGroupsBtn = computed(() => canCreateGroup.value || canAddRemoteGroup.value);

const showCreateGroupDialog = computed(() => localState.showAddGroupDialog && canCreateGroup.value);

const showAddRemoteGroupDialog = computed(() =>
  localState.showAddGroupDialog && canAddRemoteGroup.value);

const canCreateGroup = computed(() => currentUser.value.canCreateGroup(serverSettings.value));

const canAddRemoteGroup = computed(() => currentUser.value.canAddRemoteGroup(serverSettings.value));

const canEditGroup = computed(() => {
  return group => currentUser.value.canEditGroup(group, serverSettings.value);
});

const canDeleteGroup = computed(() => {
  return group => currentUser.value.canDeleteGroup(group);
});

const canSearchById = computed(() => Boolean(serverSettings.value.authentication.externalGroupId));

const title = computed(() => {
  let filter = localState.searchParameters.prefix ? 'Matching groups' : 'Groups';

  if (hasExternalGroupOwner.value) {
    filter = `${filter} (${authProviderName.value})`;
  }

  return filter;
});

const authOriginMsg = computed(() => {
  let msg = 'Connect is using local membership information.';
  if (hasExternalGroupMembers.value) {
    msg = `Connect is using membership information from your remote ${authProviderName.value} provider.`;
  }

  return msg;
});

const newGroupButtonTitle = computed(() => {
  let msg = 'Add Group';
  if (hasExternalGroupSearch.value) {
    msg = 'Import Group';
  }

  return msg;
});

onMounted(async() => {
  await search();
  localState.dataLoaded = true;
});

const onClickRow = (_groupId, target) => {
  saveFocusedElement(target);
};

const onClickRename = ({ currentTarget }, group, index) => {
  toggleRenameModal(group, index, currentTarget);
};

const toggleAddModal = () => {
  localState.showAddGroupDialog = !localState.showAddGroupDialog;
  if (!localState.showAddGroupDialog) {
    nextTick().then(() => addGroupButton.value?.focus());
  }
};

const saveFocusedElement = (element) => {
  if (element) { localState.previousFocus = element; }
};

const restorePreviousFocus = () => {
  if (!localState.previousFocus) { return; }
  nextTick().then(() => {
    localState.previousFocus && localState.previousFocus.focus();
    localState.previousFocus = null;
  });
};

const toggleDeleteModal = (deleteTargetGroup, target) => {
  saveFocusedElement(target);
  localState.deleteTargetGroup = deleteTargetGroup || {};
  localState.showDeleteGroupDialog = !localState.showDeleteGroupDialog;

  if (!localState.showDeleteGroupDialog) { restorePreviousFocus(); }
};

const toggleRenameModal = (renameTarget, index, target) => {
  saveFocusedElement(target);
  localState.renameTargetGroup = renameTarget ? { index, target: renameTarget } : { target: {} };
  localState.showGroupRenameDialog = !localState.showGroupRenameDialog;

  if (!localState.showGroupRenameDialog) { restorePreviousFocus(); }
};

const handleDeleteResolution = (targetGUID) => {
  toggleDeleteModal();
  if (targetGUID) {
    removeGroupFromTable(targetGUID);
  }
};

const handleRenamingGroup = (updatedGroup) => {
  const { index } = localState.renameTargetGroup;
  localState.groups[index].name = updatedGroup.name;
  localState.groups[index].displayName = updatedGroup.displayName;
  toggleRenameModal();
  localState.renameTargetGroup = { target: {} };
};

const removeGroupFromTable = (targetGUID) => {
  const index = localState.groups.findIndex(group => {
    return group.guid === targetGUID;
  });
  if (index !== -1) {
    localState.groups.splice(index, 1);
  }
};

const groupHREF = group => ({
  href: groupPath(group.guid),
  title: `Group ${group.name}'s details`,
});

const toggleOptionsPanel = () => {
  localState.showOptionsPanel = !localState.showOptionsPanel;
  nextTick().then(() => {
    const focused = localState.showOptionsPanel ? optionsPanel : optionsButton;
    focused.value?.focus();
  });
};

const groupImported = (group) => {
  // Evaluate if remote group already exists
  // and try to remove it from current page if needed
  // (to be placed on top for user to see it)
  if (group.guid) {
    removeGroupFromTable(group.guid);
  }
  groupCreated(group);
};

const groupCreated = (group) => {
  localState.groups.unshift(group);
  toggleAddModal();
  highlightNewGroup();
};

const highlightNewGroup = () => {
  localState.highlightFlag = true;
  setTimeout(() => {
    localState.highlightFlag = false;
  }, 1000);
};

const updateSearchOptions = ({ prefix }) => {
  localState.searchParameters.currentPage = 1;
  localState.searchParameters.prefix = prefix;
  return search();
};

const search = () => {
  const { prefix, currentPage } = localState.searchParameters;
  const timeoutId = setTimeout(() => (localState.loading = true), 300);
  return searchGroups(serverSettings.value, {
    prefix,
    includeRemote: false,
    pageNumber: currentPage,
  })
    .then(({ results, currentPage: newCurrentPage, totalPages }) => {
      localState.searchParameters.currentPage = newCurrentPage;
      localState.groups = results;
      localState.totalPages = totalPages;
    })
    .catch(e => store.commit(SET_ERROR_MESSAGE_FROM_API, e))
    .finally(() => {
      clearTimeout(timeoutId);
      localState.loading = false;
    });
};

const gotoPage = (direction) => {
  switch (direction) {
    case 'first':
      localState.searchParameters.currentPage = 1;
      break;
    case 'previous':
      localState.searchParameters.currentPage -= 1;
      break;
    case 'next':
      localState.searchParameters.currentPage += 1;
      break;
    case 'last':
      localState.searchParameters.currentPage = localState.totalPages;
      break;
  }
  return search();
};
</script>

<!-- Renders the Groups View -->
<template>
  <div :class="['contentWithOptionsPanel', { hideOptionsPanel: !localState.showOptionsPanel }]">
    <div
      id="groupsPanel"
      class="majorColumn"
    >
      <div
        v-if="localState.dataLoaded"
        class="sectionTitle flex"
      >
        <RSInformationToggle>
          <template #title>
            <div
              data-automation="group-title"
              class="title"
              role="heading"
              aria-level="1"
            >
              {{ title }}
            </div>
          </template>
          <template #rightSideControls>
            <div class="actionBar inline showTitles">
              <BaseButton
                v-if="enableAddGroupsBtn"
                ref="addGroupButton"
                :label="newGroupButtonTitle"
                button-class="action new"
                :title="newGroupButtonTitle"
                :aria-label="newGroupButtonTitle"
                data-automation="add-group"
                @clicked.prevent="toggleAddModal"
              />
              <BaseButton
                v-if="!localState.showOptionsPanel"
                ref="optionsButton"
                label="Options"
                button-class="action toggleOptions"
                title="Options"
                aria-label="Options"
                data-automation="add-group"
                @clicked.prevent="toggleOptionsPanel"
              />
            </div>
          </template>
          <template #help>
            {{ authOriginMsg }}
          </template>
        </RSInformationToggle>
      </div>
      <div
        v-if="emptyResults"
        class="emptyListMessage"
        data-automation="groups__list--empty"
      >
        No results.
      </div>

      <EmbeddedStatusMessage
        v-if="isLoading"
        :show-close="false"
        message="Getting groups..."
        type="activity"
      />

      <RSTable
        v-if="!emptyResults"
        :columns="localState.tableHeaders"
        data-automation="groups__list"
      >
        <RSTableRow
          v-for="(group, index) in localState.groups"
          :key="group.guid"
          :class="highlightClass(index)"
          :row-id="group.guid"
          :deletable="canDeleteGroup(group)"
          delete-button-label="Delete Group"
          :row-label="`Group ${group.name}'s details`"
          @delete="(_rowId, currentTarget) => toggleDeleteModal(group, currentTarget)"
        >
          <RSTableCell
            :cell-id="`group-${group.guid}-link`"
            :has-icon="true"
            :fill="true"
            :link="groupHREF(group)"
            :clickable="true"
            @click="onClickRow"
          >
            <RSPrincipalInfo
              :is-group="true"
              :name="group.name"
            />
          </RSTableCell>
          <RSTableCell
            v-if="canEditGroup(group)"
            data-automation="group-rename-cell"
          >
            <div class="actionBar">
              <button
                class="action edit"
                :aria-label="`Rename Group ${group.name}`"
                @click.stop="e => onClickRename(e, group, index)"
              />
            </div>
          </RSTableCell>
        </RSTableRow>
      </RSTable>
      <RSPager
        v-if="showPager"
        :disable-left-actions="disablePreviousPagination"
        :disable-right-actions="disableNextPagination"
        @first-page="gotoPage('first')"
        @previous-page="gotoPage('previous')"
        @next-page="gotoPage('next')"
        @last-page="gotoPage('last')"
      />
    </div>
    <div
      v-if="localState.dataLoaded && localState.showOptionsPanel"
      class="minorColumn"
    >
      <div class="optionsPositioner">
        <div class="band">
          <div class="innards bandContent">
            <GroupsOptionsPanel
              v-if="localState.showOptionsPanel"
              ref="optionsPanel"
              :can-add-groups="enableAddGroupsBtn"
              :can-search-by-id="canSearchById"
              :is-remote="hasExternalGroupSearch"
              @close="toggleOptionsPanel"
              @filter-change="updateSearchOptions"
            />
          </div>
        </div>
      </div>
    </div>

    <CreateGroupDialog
      v-if="localState.dataLoaded && showCreateGroupDialog"
      @created="groupCreated"
      @close="toggleAddModal"
    />
    <DeleteGroupDialog
      v-if="localState.showDeleteGroupDialog"
      :group="localState.deleteTargetGroup"
      @close="handleDeleteResolution"
    />
    <RenameGroupDialog
      v-if="localState.showGroupRenameDialog"
      :group="localState.renameTargetGroup.target"
      @close="toggleRenameModal"
      @rename="handleRenamingGroup"
    />
    <ImportRemoteEntityDialog
      v-if="showAddRemoteGroupDialog"
      type="group"
      :server-settings="serverSettings"
      @import="groupImported"
      @close="toggleAddModal"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';

.optionsPositioner {
  position: fixed;
  z-index: 10;
  left: 0px;
  width: 100%;
  height: 0px;

  @include transition-property(left, right);
  @include normal-transition-duration();
}
.actionBar {
  margin: 0;
}

.action.edit {
  background-image: url('/images/elements/actionEdit.svg');
  background-color: transparent;
}

.title {
  margin-left: 2px;
  padding-left: 2px;
}

.highlight-once {
  @include highlight-once();
}

:deep(.rs-help-toggler) {
  width: 100%;
}

:deep(.rs-help-toggler__controls) {
  width: 100%;
  justify-content: space-between;
}
</style>

