<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { deleteContent } from '@/api/content';
import { ContentTypeLabel } from '@/api/dto/app';
import RSButton from '@/elements/RSButton.vue';
import RSModalForm from '@/elements/RSModalForm.vue';
import { SHOW_ERROR_MESSAGE, SHOW_INFO_MESSAGE } from '@/store/modules/messages';
import truncate from 'lodash/truncate';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const emit = defineEmits(['deleteModal']);

const router = useRouter();
const store = useStore();

const app = computed(() => store.state.contentView.app);
const category = computed(() => ContentTypeLabel[app.value.contentType()]);
const appTitle = computed(() => app.value.displayName);

const onConfirm = async() => {
  try {
    await deleteContent(app.value.guid);
    await store.dispatch(SHOW_INFO_MESSAGE, {
      message: `Deleted ${category.value}: ${appTitle.value}`,
      autoHide: false,
    });

    await router.push({ name: 'contentList' });
  } catch {
    store.dispatch(SHOW_ERROR_MESSAGE, {
      message: `Unable to delete ${category.value}: ${appTitle.value}`,
    });
  }
};
</script>

<template>
  <RSModalForm
    :active="true"
    :subject="`Remove ${truncate(appTitle, { length: 60 })}?`"
    @close="emit('deleteModal')"
    @submit="onConfirm"
  >
    <template #content>
      <p class="details">
        Are you sure you want to remove <span class="emphasize">{{ appTitle }}</span>?
      </p>
      <p class="details">
        This operation cannot be undone.
      </p>
    </template>
    <template #controls>
      <RSButton
        type="secondary"
        label="No"
        title="No"
        data-automation="confirm-dialog-cancel"
        @click="emit('deleteModal')"
      />
      <RSButton
        type="primary"
        label="Yes"
        title="Yes"
        data-automation="confirm-dialog-submit"
      />
    </template>
  </RSModalForm>
</template>

<style scoped lang="scss">
.details {
  font-size: 1rem;
  text-align: left;
  white-space: normal;
}

button.primary {
  margin-left: 1rem;
}
</style>
