// Copyright (C) 2024 by Posit Software, PBC.

import axios from 'axios';

import { apiV1Path } from '@/utils/paths';
import { keysToCamel } from '@/api/transform';
import { ContentPackage } from '@/api/dto/package';

// getContentPackages retrieves the packages for a given content GUID
export const getContentPackages = guid => {
  const url = apiV1Path(`content/${guid}/packages`);
  return axios
    .get(url)
    .then(({ data }) => {
      if (!data) {
        return [];
      }
      return keysToCamel(data).map(pkg => new ContentPackage(pkg));
    });
};
