<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup>
import { deployApplicationResult } from '@/api/app';
import { JobLogLine } from '@/api/dto/job';
import { safeAPIErrorMessage } from '@/api/error';
import { getBranchesResult } from '@/api/git';
import EmbeddedStatusMessage, {
  ActivityMessage,
  ErrorMessage,
} from '@/components/EmbeddedStatusMessage';
import LogViewer from '@/components/LogViewer';
import RSButton from '@/elements/RSButton.vue';
import RSModal from '@/elements/RSModal.vue';
import { reloadWindow } from '@/utils/windowUtil';
import { computed, reactive } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  appGuid: {
    type: String,
    required: true,
  },
  git: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const initialData = () => ({
  activity: {
    show: false,
    text: '',
    type: '',
  },
  buttonText: 'OK',
  logEntries: [],
  message: {
    show: false,
    text: '',
  },
  showDeployLogs: false,
  showOk: false,
  showUpdateNowDialog: false,
  task: {
    finished: false,
    code: -1,
  },
});
const localState = reactive(initialData());

const appIsLocked = computed(() => store.state.contentView.app?.locked);

const updateNowClicked = async() => {
  showDialog();

  if (appIsLocked.value) {
    showStatus('Cannot update locked content.', {
      plain: true,
    });
    return;
  }

  showStatus('Checking for changes');

  try {
    const { data } = await getBranchesResult(props.git.repository);

    hideStatus();
    processBranchesResult(data);
  } catch (err) {
    hideStatus();
    showStatus(safeAPIErrorMessage(err), { error: true });
  }
};

const processBranchesResult = async(refs) => {
  const matchingRef = refs.filter(ref => ref.branch === props.git.branch);
  // uncomment the following line to force deploys (useful while testing)
  // matchingRef[0] = {ref: '', branch: git.branch};

  if (matchingRef.length === 0) {
    // branch doesn't exist anymore
    showStatus(
      `Failed to find branch '${props.git.branch}'.`,
      { error: true }
    );
  } else if (matchingRef[0].ref === props.git.lastKnownCommit) {
    // no changes in branch
    showStatus('No changes were found in the Git repository.', {
      plain: true,
    });
  } else if (matchingRef[0].ref !== props.git.lastKnownCommit) {
    // newer commit detected
    showStatus('Changes were found in the Git repository. Deploying ...');
    localState.showDeployLogs = true;

    try {
      const task = await deployApplicationResult(props.appGuid, taskCallback);
      if (task.error) {
        showStatus(task.error, { error: true });
      } else {
        hideStatus();
        localState.buttonText = 'Open Content';
      }
    } catch (err) {
      showStatus(err.error, { error: true });
      throw err;
    }
  }
};

const showDialog = () => {
  localState.showUpdateNowDialog = true;
};

const hideDialog = () => {
  if (localState.task.finished && localState.task.code === 0) {
    // reload page on successful deployment
    reloadWindow();
  }

  const initalizedData = initialData();
  Object.keys(initalizedData).forEach(key => {
    localState[key] = initalizedData[key];
  });
};

const showStatus = (text, { error = false, plain = false } = {}) => {
  localState.showOk = true;
  hideStatus();

  if (plain) {
    localState.message = { show: true, text };
  } else {
    localState.activity = {
      show: true,
      text,
      type: error ? ErrorMessage : ActivityMessage,
    };
  }
};
const hideStatus = () => {
  localState.activity.show = false;
  localState.message.show = false;
};
const taskCallback = (task) => {
  const newEntries = task.output.map(line => (new JobLogLine({
    line: line,
    isError: false,
  })));
  localState.logEntries = localState.logEntries.concat(newEntries);
  localState.task.finished = task.finished;
  localState.task.code = task.code;
};
</script>

<template>
  <div>
    <div class="actions alignRight extraSpacingAbove">
      <RSButton
        label="Update Now"
        class="rsc-button-small"
        data-automation="git-update-now"
        @click.prevent="updateNowClicked"
      />
    </div>

    <RSModal
      v-if="localState.showUpdateNowDialog"
      :active="true"
      subject="Updating from Git repository"
      class="unhide-overflow"
      @close="hideDialog"
      @submit="hideDialog"
    >
      <template #content>
        <EmbeddedStatusMessage
          v-if="localState.activity.show"
          data-automation="git-updatenow-activity"
          :message="localState.activity.text"
          :show-close="false"
          :type="localState.activity.type"
        />
        <div
          v-if="localState.message.show"
          data-automation="git-updatenow-message"
        >
          {{ localState.message.text }}
        </div>
        <div v-if="localState.showDeployLogs">
          <div class="chunk">
            <LogViewer
              :entries="localState.logEntries" 
              data-automation="git-updatenow-logviewer"
            />
          </div>
        </div>
      </template>
      <template
        v-if="localState.showOk"
        #controls
      >
        <RSButton
          id="gun-ok"
          :label="localState.buttonText"
          data-automation="git-updatenow-ok-button"
          @click="hideDialog"
        />
      </template>
    </RSModal>
  </div>
</template>

<style scoped>
.actions {
  line-height: 30px;
}
.rsc-button-small {
  font-size: 0.8rem;
}
.unhide-overflow {
  .statusMessage {
    overflow: visible;
    margin-bottom: 0.9rem;
  }
  .statusMessage.showing {
    max-height: 120px;
  }
}
</style>
