<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup>
import TabSelector, { TabShape } from '@/components/TabSelector.vue';
import { useSkipToMainContent } from '@/composables/skipToMainContent';
import RSInputSelect from '@/elements/RSInputSelect.vue';
import { systemTabs } from '@/utils/subNav';
import { useWindowSize } from '@vueuse/core';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const { width } = useWindowSize();

useSkipToMainContent(() => {
  tabSelector.value?.focusTab(route.name);
  dropDownSelector.value?.focusElement();
});

const selectedPage = ref(route.name);
const tabs = systemTabs('system');

const options = tabs.map(({ id, label }) => ({
  value: id,
  label,
}));

const tabSelector = ref(null);
const dropDownSelector = ref(null);

const onTabSelect = (tabId) => {
  selectedPage.value = tabId;
  router.replace({ name: tabId });
};

const isLargeWindow = computed(() => width.value > 1023);
</script>

<template>
  <div class="band">
    <div class="bandContent mainPage">
      <div
        v-if="isLargeWindow"
        class="tab-bar"
      >
        <TabSelector
          ref="tabSelector"
          name="admin-tabs"
          :active-tab-id="route.name"
          :shape="TabShape.SQUARE"
          :tabs="tabs"
          @update:active-tab-id="onTabSelect"
        />
      </div>
      <div
        v-else
        class="select-box"
      >
        <RSInputSelect
          ref="dropDownSelector"
          v-model="selectedPage"
          label="System Pages"
          name="admin-tabs"
          :show-label="false"
          :options="options"
          @change="onTabSelect"
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tab-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.select-box {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
</style>
