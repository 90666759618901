<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import UserRoles from '@/api/dto/userRole';
import { lockUser, unlockUser } from '@/api/users';
import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import RSInputCheckbox from '@/elements/RSInputCheckbox.vue';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo.vue';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { activeTime } from '@/utils/activeTime.filter';
import upperFirst from 'lodash/upperFirst';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import ChangePassword from './ChangePassword';
import ConfirmAccount from './ConfirmAccount';
import EditProfile from './EditProfile';
import ResetPassword from './ResetPassword';

const props = defineProps({
  currentUser: {
    type: Object,
    required: true,
  },
  profileFieldPermissions: {
    type: Object,
    required: true,
  },
  userProfile: {
    type: Object,
    required: true,
  },
  serverSettings: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['refreshUserProfile']);
const store = useStore();

const retryUserLock = ref(false);

const roleName = computed(() => {
  const userRole = UserRoles.stringOf(props.userProfile.userRole);
  return upperFirst(userRole);
});

const canLockOrUnlockUser = computed(() =>
  props.currentUser.canLockOrUnlockUser(props.userProfile));

const accountLocked = computed(() => props.userProfile.locked);

const canEmailAuthenticationLinks = computed(() => {
  // if mail is configured and we are able to modify accounts
  // note: external user data is not subject to account modification
  return (
    props.serverSettings.mailConfigured &&
    !props.serverSettings.externalUserData
  );
});

const toggleAccountLocked = async(executeRestrictedApi) => {
  const api = props.userProfile.locked ? unlockUser : lockUser;

  try {
    await executeRestrictedApi(api(props.userProfile.guid));
    refreshUserProfile();
  } catch (e) {
    if (!(e instanceof ReauthenticationInProgressError)) {
      store.commit(SET_ERROR_MESSAGE_FROM_API, e);
    } else {
      retryUserLock.value = true;
    }
  }
};
const refreshAfterSudo = () => {
  if (retryUserLock.value) {
    const api = props.userProfile.locked ? unlockUser : lockUser;
    api(props.userProfile.guid);
    refreshUserProfile();
    retryUserLock.value = false;
  }
};

const refreshUserProfile = () => {
  emit('refreshUserProfile');
};
</script>

<template>
  <div>
    <RSPrincipalInfo
      :initials="userProfile.displayInitials"
      :title="userProfile.username"
      data-automation="user-principal-info"
    />
    <div class="rsc-user-info">
      <div data-automation="ui-name-status">
        {{ userProfile.displayName }}
        <span
          v-if="userProfile.displayStatuses"
          class="rsc-user-info__status"
        >
          ({{ userProfile.displayStatuses }})
        </span>
      </div>

      <div data-automation="ui-role">
        {{ roleName }}
      </div>

      <div
        v-if="userProfile.email"
        data-automation="ui-email"
      >
        <a :href="`mailto:${userProfile.email}`">{{ userProfile.email }}</a>
      </div>

      <div data-automation="ui-joined">
        Joined: {{ activeTime(userProfile.createdTime) }}
      </div>
      <RestrictedAccessWrapper
        v-slot="{ executeRestrictedApi }"
        :eager="false"
        @authenticated="refreshAfterSudo"
      >
        <RSInputCheckbox
          v-if="canLockOrUnlockUser"
          :model-value="accountLocked"
          label="Account Locked"
          help="A locked user is prohibited from signing into Posit Connect, deploying content,
            and otherwise interacting with the service. Unlocking the user restores these privileges,
            if allowed by your license."
          name="ui-locked"
          data-automation="ui-locked"
          @change="toggleAccountLocked(executeRestrictedApi)"
        />
      </RestrictedAccessWrapper>
    </div>
    <div class="rs-divider" />
    <div class="rs-actions pull--right">
      <ResetPassword
        v-if="currentUser.canResetPassword(serverSettings, userProfile)"
        class="rs-action"
        :can-email="canEmailAuthenticationLinks"
        :user-profile="userProfile"
      />
      <ConfirmAccount
        v-if="currentUser.canConfirmAccount(serverSettings, userProfile)"
        class="rs-action"
        :can-email="canEmailAuthenticationLinks"
        :user-profile="userProfile"
      />
      <ChangePassword
        v-if="currentUser.canChangePassword(serverSettings, userProfile)"
        class="rs-action"
        @changed-password="refreshUserProfile"
      />
      <EditProfile
        v-if="currentUser.canEditUser(userProfile)"
        class="rs-action"
        :execute-restricted-api="executeRestrictedApi"
        :current-user="currentUser"
        :profile-field-permissions="profileFieldPermissions"
        :server-settings="serverSettings"
        :user-profile="userProfile"
        @profile-updated="refreshUserProfile"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

.rsc-user-info {
  margin-top: 1rem;
  line-height: 2;
  word-wrap: break-word;
  word-break: break-word;

  &__status {
    color: $color-dark-grey;
    font-size: $rs-font-size-smallest;
  }
}

.rs-actions.pull--right{
  display: flex;
  justify-content: flex-end;

  & .rs-action:not(:first-child) {
    margin-left: 1rem;
  }
}
</style>
