<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <RSButton
      ref="openModalButton"
      label="Reset Password"
      data-automation="rp-trigger"
      @click="resetPassword"
    />
    <CopyTextDialog
      v-if="copyUrlDialog.show"
      title="Reset Password Link"
      description="
        Users cannot reset their password themselves without an email server configured in Posit Connect.
        Administrators will need to copy the reset password link to the clipboard and pass it to the user
        via other means. This should be done without visiting the link since administrators must have no
        knowledge of the users' passwords."
      copied-message="The reset password link has been copied to the clipboard."
      text-label="Copy Reset Password Link"
      :text="copyUrlDialog.url"
      @close="copyUrlDialog.show = false"
    />

    <RSModal
      v-if="showSendEmailDialog"
      :active="true"
      subject="Reset Password"
      @close="closeModal"
    >
      <template #content>
        Send reset password link to {{ userProfile.email }}?
      </template>
      <template #controls>
        <RSButton
          label="Send"
          data-automation="rp-send"
          @click="sendEmail"
        />
      </template>
    </RSModal>
  </div>
</template>

<script>
import RSButton from '@/elements/RSButton.vue';
import RSModal from '@/elements/RSModal.vue';

import { sendOrGetResetPasswordLink } from '@/api/authentication';
import CopyTextDialog from '@/components/CopyTextDialog';
import {
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {
    CopyTextDialog,
    RSButton,
    RSModal,
  },
  props: {
    userProfile: {
      type: Object,
      required: true,
    },
    canEmail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      copyUrlDialog: {
        show: false,
        url: '',
      },
      showSendEmailDialog: false,
    };
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    closeModal() {
      this.showSendEmailDialog = false;
      this.$nextTick().then(() => this.$refs.openModalButton.focusElement());
    },
    resetPassword() {
      if (this.canEmail) {
        this.showSendEmailDialog = true;
      } else {
        // show copyable 'reset password' link
        return sendOrGetResetPasswordLink(this.userProfile.username)
          .then(({ url }) => {
            this.copyUrlDialog.url = url;
            this.copyUrlDialog.show = true;
          })
          .catch(e => {
            this.copyUrlDialog.show = false;
            this.setErrorMessageFromAPI(e);
          });
      }
    },
    sendEmail() {
      return sendOrGetResetPasswordLink(this.userProfile.username)
        .then(() => {
          this.setInfoMessage({ message: `A reset password link has been emailed to ${this.userProfile.email}.` });
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.closeModal();
        });
    },
  },
};
</script>
