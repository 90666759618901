// Copyright (C) 2024 by Posit Software, PBC.

import axios from 'axios';
import { apiV1Path } from '@/utils/paths';

export function createBookmark(guid) {
  return axios.post(apiV1Path(`content/${guid}/bookmark`))
    .then(response => {
      if (response?.status === 201) {
        return true;
      }

      return Promise.reject({ response });
    });
}

export function deleteBookmark(guid) {
  return axios.delete(apiV1Path(`content/${guid}/bookmark`))
    .then(response => {
      if (response?.status === 204) {
        return true;
      }

      return Promise.reject({ response });
    });
}
