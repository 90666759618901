// Copyright (C) 2022 by Posit Software, PBC.

/**
 * Utility functions for content listings.
 */

import { User } from '@/api/dto/user';
import isEmpty from 'lodash/isEmpty';

/**
 * @param {Object} app - an App DTO
 * @returns {boolean} - true if a piece of content has an identifiable owner
 */
export const hasOwner = (app) => !isEmpty(app.owner?.username) && !isEmpty(app.ownerGuid);

// Helper to construct a (partial) User DTO so we can access computed fields (displayInitials, displayName).
// The User DTO is missing most fields and so is not suitable for general use.
const appOwner = ({ owner }) => 
  new User({
    firstName: owner?.firstName || '',
    lastName: owner?.lastName || '',
    username: owner?.username,
  });

/**
 * @param {Object} app - an App DTO
 * @returns {string} - the app owner's initials
 */
export function displayOwnerInitials(app) {
  return appOwner(app).displayInitials;
}

/**
 * @param {Object} app - an App DTO
 * @returns {string} - the app owner's display name
 */
export function displayOwnerName(app) {
  return appOwner(app).displayName;
}

/**
 * @param {Object} git - the `git` field of an App DTO
 * @returns {string} - an empty string if `git` is falsey, else a translated "from Git" string
 */
export function fromGit(git) {
  return git ? ' from Git' : '';
}

export const contentTooltips = {
  unknown: 'View item',
  application: 'View application',
  document: 'View document',
  site: 'View site',
  plot: 'View plot',
  pin: 'View pin',
  api: 'View API',
  tensorFlowApi: 'View TensorFlow API',
};

export const contentDescriptions = {
  unknown: 'Item',
  application: 'Application',
  document: 'Document',
  site: 'Site',
  plot: 'Plot',
  pin: 'Pin',
  api: 'API',
  tensorFlowApi: 'TensorFlow Model API',
};
