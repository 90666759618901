<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    v-if="api.loaded"
    class="majorMinorColumnsContainer minorLeft group-edit-view"
  >
    <div class="minorColumn">
      <RSPrincipalInfo
        :is-group="true"
        :title="api.group.displayName"
        data-automation="group-principal-info"
      />
      <div class="rs-divider" />

      <div v-if="canAddGroupMember">
        <label
          class="sectionTitle rsc-label-title"
          for="ge-add-member"
        >
          Add Member
        </label>

        <PrincipalSearchSelect
          label="Add Member"
          :server-settings="serverSettings"
          :has-clear="true"
          data-automation="ge-search"
          name="ge-add-member"
          type="user"
          @select="addUserToGroup"
        />

        <div class="rs-divider" />
      </div>
      <!-- RSPrincipalInfo has content -->
      <!-- eslint-disable vuejs-accessibility/anchor-has-content -->
      <a
        v-if="displayOwnerDetails"
        :href="groupOwnerProfileLink"
      >
        <RSPrincipalInfo
          :initials="api.group.owner.displayInitials"
          :name="api.group.owner.displayName"
          :status="api.group.owner.displayStatuses"
          additional-status="owner"
        />
      </a>
      <!-- eslint-enable vuejs-accessibility/anchor-has-content -->
      <div v-else>
        Remote group owned by your authentication provider
      </div>
    </div>

    <div class="majorColumn">
      <div
        v-if="!hasExternalGroupSearch"
        class="sectionTitle flex"
      >
        <div>
          Members
        </div>
        <GroupEditActions
          v-if="canRemoveGroup"
          ref="groupActions"
          :group-name="api.group.displayName"
          :can-edit="canEditGroup"
          :can-remove="canRemoveGroup"
          @rename="toggleGroupRenameDialog"
          @remove="toggleGroupRemovalDialog"
        />
      </div>
      <RSInformationToggle
        v-if="hasExternalGroupSearch"
      >
        <template #title>
          <h3>
            Members
          </h3>
        </template>
        <template #rightSideControls>
          <GroupEditActions
            v-if="canRemoveGroup"
            :group-name="api.group.displayName"
            :can-edit="canEditGroup"
            :can-remove="canRemoveGroup"
            @rename="toggleGroupRenameDialog"
            @remove="toggleGroupRemovalDialog"
          />
        </template>
        <template #help>
          <p>
            Group details are updated in the background throughout the day.
            Learn more about the sync interval in the Posit Connect
            <a
              target="_blank"
              :href="adminGuideUrl"
            >
              Admin Guide
            </a>
          </p>
        </template>
      </RSInformationToggle>
      <DeleteGroupDialog
        v-if="showGroupRemovalDialog"
        :group="api.group"
        @close="handleDeleteResolution"
      />
      <RenameGroupDialog
        v-if="api.loaded && showGroupRenameDialog"
        :group="api.group"
        @close="toggleGroupRenameDialog"
        @rename="handleRenamingGroup"
      />
      <GroupMemberList
        :group="api.group"
        :members="api.members.results"
        :loading="api.members.loading"
        :total-pages="api.members.totalPages"
        :current-page="api.members.page"
        :current-user="currentUser"
        :server-settings="serverSettings"
        @remove-member="removeGroupMember"
      />
    </div>
  </div>
</template>

<script>
import {
  addGroupMember,
  deleteGroupMember,
  getGroup,
  getGroupMembers,
} from '@/api/groups';
import {
  addNewRemoteUser,
  getUser,
} from '@/api/users';
import PrincipalSearchSelect from '@/components/PrincipalSearchSelect';
import RSInformationToggle from '@/elements/RSInformationToggle.vue';
import RSPrincipalInfo from '@/elements/RSPrincipalInfo.vue';
import {
  CLEAR_ACTIVITY_MESSAGE,
  SET_ACTIVITY_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';
import { docsPath, userPath } from '@/utils/paths';
import DeleteGroupDialog from '@/views/groups/DeleteGroupDialog';
import RenameGroupDialog from '@/views/groups/RenameGroupDialog';
import { mapMutations, mapState } from 'vuex';
import GroupEditActions from './GroupEditActions';
import GroupMemberList from './GroupMemberList';

export default {
  name: 'GroupEdit',
  components: {
    GroupEditActions,
    GroupMemberList,
    PrincipalSearchSelect,
    DeleteGroupDialog,
    RenameGroupDialog,
    RSPrincipalInfo,
    RSInformationToggle,
  },
  props: {
    groupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      api: {
        loaded: false,
        group: null,
        members: {
          loading: false,
          results: [],
          page: 1,
          totalPages: 1,
        },
      },
      showGroupRemovalDialog: false,
      showGroupRenameDialog: false,
    };
  },
  computed: {
    adminGuideUrl() {
      return docsPath('admin');
    },
    displayOwnerDetails() {
      return Boolean(this.api.group.ownerGuid);
    },
    hasExternalGroupSearch() {
      return this.serverSettings.authentication.externalGroupSearch;
    },
    canEditGroup() {
      return this.currentUser.canEditGroup(this.api.group, this.serverSettings);
    },
    canRemoveGroup() {
      return this.currentUser.canDeleteGroup(this.api.group, this.serverSettings);
    },
    canAddGroupMember() {
      return this.currentUser.canAddGroupMember(this.api.group, this.serverSettings);
    },
    groupOwnerProfileLink() {
      if (this.api.group.ownerGuid) {
        return userPath(this.api.group.ownerGuid);
      }
      return null;
    },
    ...mapState({
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
    }),
  },
  async created() {
    await this.init();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
      setActivityMessage: SET_ACTIVITY_MESSAGE,
      clearActivityMessage: CLEAR_ACTIVITY_MESSAGE,
    }),
    async init() {
      const page = this.$route.name;

      const activityMsgTimeout = setTimeout(() => {
        this.setActivityMessage({
          page,
          message: 'Getting members...',
        });
      }, 1000);

      return getGroup(this.groupId)
        .then(group => {
          this.api.group = group;

          // TODO: These can be removed once we are fully using vue-router
          const apiCallsStack = [];

          if (group.ownerGuid) {
            apiCallsStack.push(getUser(group.ownerGuid));
          }
          return Promise.all([
            ...apiCallsStack,
            this.loadMembersPage(),
          ]);
        })
        .then(([groupOwner]) => {
          this.api.group.owner = this.api.group.ownerGuid ? groupOwner : null;
          this.api.loaded = true;
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          clearTimeout(activityMsgTimeout);
          this.clearActivityMessage();
        });
    },
    loadMembersPage() {
      const timeoutId = setTimeout(() => (this.api.members.loading = true), 300);
      return getGroupMembers(this.api.group.guid, {
        pageNumber: this.api.members.page,
      })
        .then(({ results, totalPages }) => {
          this.api.members.results = results;
          this.api.members.totalPages = totalPages;
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          clearTimeout(timeoutId);
          this.api.members.loading = false;
        });
    },
    toggleGroupRemovalDialog() {
      this.showGroupRemovalDialog = !this.showGroupRemovalDialog;
      if (!this.showGroupRemovalDialog) {
        const focusLast = this.$refs.groupActions && this.$refs.groupActions.focusLast;
        this.$nextTick().then(() => focusLast && focusLast());
      }
    },
    toggleGroupRenameDialog() {
      this.showGroupRenameDialog = !this.showGroupRenameDialog;
      if (!this.showGroupRenameDialog) {
        const focusLast = this.$refs.groupActions.focusLast;
        this.$nextTick().then(() => focusLast && focusLast());
      }
    },
    handleDeleteResolution(deletedGroupGuid) {
      this.toggleGroupRemovalDialog();
      if (deletedGroupGuid) {
        this.$router.push('/people/groups');
      }
    },
    handleRenamingGroup(renamedGroup) {
      this.api.group.name = renamedGroup.name;
      this.api.group.displayName = renamedGroup.displayName;
      this.toggleGroupRenameDialog();
    },
    async addUserToGroup(user) {
      try {
        const resolvedUser = await (user.guid
          ? Promise.resolve(user)
          : addNewRemoteUser(user.tempTicket));
        await addGroupMember(this.groupId, resolvedUser.guid);
        this.api.members.results.unshift(resolvedUser);
      } catch (err) {
        this.setErrorMessageFromAPI(err);
      }
    },
    removeGroupMember({ guid, index }) {
      return deleteGroupMember(this.api.group.guid, guid)
        .then(() => {
          // remove deleted member from list
          this.api.members.results.splice(index, 1);
        })
        .catch(this.setErrorMessageFromAPI);
    },
    gotoMembersPage(direction) {
      switch (direction) {
        case 'first':
          this.api.members.page = 1;
          break;
        case 'previous':
          this.api.members.page -= 1;
          break;
        case 'next':
          this.api.members.page += 1;
          break;
        case 'last':
          this.api.members.page = this.api.members.totalPages;
          break;
      }
      return this.loadMembersPage();
    },
  },
};
</script>

<style lang="css">
.group-edit-view .rsc-label-title {
  line-height: unset;
  display: block;
}

.group-edit-view .action.edit {
  background-image: url('/images/elements/actionEdit.svg');
}

.group-edit-view .rs-help-toggler {
  margin-bottom: 1rem;
}

.group-edit-view .rs-help-toggler__controls {
  justify-content: space-between;
  width: 100%;
}
</style>
