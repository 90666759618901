<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="rStudioHeader"
    class="connect-header contentHeader"
    :class="{ logsActive, historyActive }"
  >
    <div class="band contentHeader">
      <div class="innards">
        <div id="leftSide">
          <template v-if="!isAuthenticated">
            <div>
              <RouterLink
                :to="{ name: 'welcome' }"
                data-automation="content-header-logo-link"
              >
                <!-- eslint-disable vue/no-useless-v-bind -->
                <img
                  class="connect-header__logo"
                  :src="'__logo__'"
                  alt="Return to Welcome"
                >
                <!-- eslint-enable vue/no-useless-v-bind -->
              </RouterLink>
            </div>
          </template>
          <template v-else>
            <button
              id="menuToggler"
              data-automation="menu-toggler"
              aria-label="Menu Toggle"
              class="connect-header__menu-button"
              @click="openNavPanel"
            />
            <NotificationBadge
              v-if="showFeedNotification"
              class="whats-new-notification"
            />
          </template>

          <ContentPath />
        </div>

        <div id="rightSide">
          <ServerSettingsKeeper />
          <ContentActions
            v-if="app && currentUser"
          />
          <CurrentUser id="currentUser" />
        </div>
      </div>
    </div>

    <BundleHistory v-if="isAppHistoryVisible" />
    <RenderingHistory v-if="isVariantHistoryVisible" />
    <StatusMessage />
  </div>
</template>

<script>
import CurrentUser from '@/components/CurrentUser';
import { BundleHistory, RenderingHistory } from '@/components/History';
import ServerSettingsKeeper from '@/components/ServerSettingsKeeper.vue';
import StatusMessage from '@/components/StatusMessage';
import NotificationBadge from '@/components/NotificationBadge.vue';
import { SHOW_NAV_PANEL } from '@/store/modules/navigation';
import { HIDE_LOGS_OVERLAY } from '@/store/modules/contentView';
import { RouterLink } from 'vue-router';
import { mapActions, mapMutations, mapState } from 'vuex';
import ContentActions from './ContentActions';
import ContentPath from './ContentPath';

export default {
  name: 'ContentHeader',
  components: {
    BundleHistory,
    ContentActions,
    ContentPath,
    CurrentUser,
    RenderingHistory,
    ServerSettingsKeeper,
    StatusMessage,
    NotificationBadge,
    RouterLink,
  },
  computed: {
    ...mapState({
      app: state => state.contentView.app,
      currentUser: state => state.currentUser.user,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      isAppHistoryVisible: state => state.bundles.showHistoryPane,
      isVariantHistoryVisible: state => state.contentView.renderingHistory.showHistoryPane,
      showLogs: state => state.contentView.showLogs,
      showFeedNotification: state => state.whatsNew.showNotification,
    }),
    logsActive() {
      return this.showLogs;
    },
    historyActive() {
      return this.isAppHistoryVisible || this.isVariantHistoryVisible;
    },
  },
  methods: {
    ...mapActions({
      hideLogsOverlay: HIDE_LOGS_OVERLAY,
    }),
    ...mapMutations({
      showingNavPanel: SHOW_NAV_PANEL,
    }),
    async openNavPanel() {
      await this.hideLogsOverlay();
      this.showingNavPanel(true);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_mixins';
#rStudioHeader {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  z-index: 100;

  background-color: rgba(255, 255, 255, .98);

  @include drop-shadow(0, 3px, 15px, 0px, rgba(0,0,0,0.1));
  @include transition-property(left, top);
  @include normal-transition-duration();

  &.connect-header__logo {
    height: 2.72rem;
    margin-left: 1rem;
    max-width: 160px;
    width: auto;
  }

  #menuToggler {
    background-color: $color-white;
    padding: 0;
    display: inline-block;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    vertical-align: bottom;
    @include control-visible-focus;
    background-image: url(/images/elements/menuToggler.svg);
    cursor: pointer;
  }

  .innards {
    padding: 9px 0;
    @include flex-space-between();
    align-items: center;
  }
}

.historyActive {
  height: auto!important;
}
.whats-new-notification {
  top: 2px;
  position: relative;
  right: 12px;
}

#leftSide {
  position: relative;
  white-space: nowrap;
  min-width: 0;

  @include flex-space-after();

  @include for-medium-screens {
    .path {
      display: none
    }
  }
}

#rightSide {
  position: relative;
  white-space: nowrap;
}

#currentUser {
  display: inline-block;
  height:30px;
  white-space: nowrap;
}
</style>
