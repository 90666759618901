<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div>
    <div
      v-for="(category, index) in tagsTree"
      :key="category.id"
    >
      <RSCheckboxGroup
        :title="category.label"
        :read-only="readOnly"
        :options="category.children"
        name="app-tags-tree"
        class="category-checkboxes"
        @change="handleChange($event, category.id, index)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getTagsTree } from '@/api/tags';
import RSCheckboxGroup from '@/elements/RSCheckboxGroup.vue';
import {
  isTagSelected,
  tagsCategoryTreeToQueryFilter,
  buildCategoryTreePaths,
  buildSelectableTreeAndPaths,
} from './tagsCatalogUtils';
import { CONTENT_LIST_SET_DESELECT_TAG } from '@/store/modules/contentList';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';

export default {
  name: 'TagsCatalogSelector',
  components: {
    RSCheckboxGroup,
  },
  props: {
    selected: {
      type: Set,
      default: () => new Set(),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    prefetched: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'empty'],
  data() {
    return {
      tagsTree: [],
      loading: true,
    };
  },
  computed: {
    ...mapState({
      contentList: state => state.contentList,
    }),
  },
  watch: {
    selected(newSelected) {
      if (this.contentList.deselectTag.tagId) {
        // deselect a specific tag and potentially parent/grandparent/...
        this.updateTagSelection(
          this.tagsTree,
          this.contentList.deselectTag.tagId,
          this.contentList.deselectTag.categoryId
        );
        this.setDeselectTag({ tagId: null, categoryId: null });
      } else if (newSelected.size === 0) {
        // deselect all tags
        this.updateCheckedRegistry(newSelected);
      }
    },
  },
  created() {
    // We copy the selected to a registry that will be manipulated, doesn't need to be observed
    this.selectedRegistry = new Set(this.selected);
    this.init();
  },
  methods: {
    ...mapMutations({
      setDeselectTag: CONTENT_LIST_SET_DESELECT_TAG,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    init() {
      if (this.prefetched) {
        this.tagsTree = JSON.parse(JSON.stringify(this.contentList.tagsTree));
        this.loading = false;
        return Promise.resolve();
      }

      return getTagsTree()
        .then(tagsTree => {
          if (!tagsTree.length) {
            this.$emit('empty');
          } else {
            const { selectableTree } = buildSelectableTreeAndPaths(
              tagsTree,
              this.selectedRegistry
            );
            this.tagsTree = selectableTree;
          }
        })
        .catch(this.setErrorMessageFromAPI)
        .finally(() => {
          this.loading = false;
        });
    },
    // deselects the leaf tag and the parent/grandparent/great grandparent/...
    // when no other siblings are selected at each level going back up the tree
    updateTagSelection(
      tags,
      tagId,
      categoryId,
      parentTag = {},
      branchLevel = 0
    ) {
      let selectedSiblings = 0;
      let foundTag = false;
      tags.forEach(tag => {
        // bail for all non-matching categories
        if (branchLevel === 0 && categoryId !== tag.id) {
          return;
        }

        // found the tag to deselect
        if (tagId === tag.id) {
          tag.checked = false;
          foundTag = true;
          return;
        }

        if (tag.children && tag.children.length && !foundTag) {
          foundTag = this.updateTagSelection(
            tag.children,
            tagId,
            categoryId,
            tag,
            branchLevel + 1
          );
        }

        // this is a sibling that is checked so count it
        if (tagId !== tag.id && tag.checked) {
          selectedSiblings++;
        }
      });

      // found the tag to deselect and there are no other selected siblings
      // so deselect the parent/grandparent/great grandparent/...
      if (foundTag && selectedSiblings < 1) {
        parentTag.checked = false;
      }

      return foundTag;
    },
    forceSelectionChecks(tags) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < tags.length; i++) {
        const tagId = tags[i].id;
        const checked = isTagSelected(tagId, this.selectedRegistry);
        tags[i].checked = checked;
        if (tags[i].children.length) {
          this.forceSelectionChecks(tags[i].children);
        }
      }
    },
    updateCheckedRegistry(newSelectionSet) {
      this.selectedRegistry = new Set(newSelectionSet);
      this.forceSelectionChecks(this.tagsTree);
    },
    handleChange(ev, categoryId, categoryIndex) {
      const category = this.tagsTree[categoryIndex];
      const categoryFilter = tagsCategoryTreeToQueryFilter(category);
      const selectedTags = buildCategoryTreePaths(ev.selectionTree);
      this.$emit('change', {
        target: ev.target,
        checked: ev.checked,
        deselectedChildren: ev.deselectedChildren,
        categoryId,
        categoryFilter,
        categoryName: category.label,
        selectedTags,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.category-checkboxes {
  margin-bottom: 1.2rem;
}
</style>
