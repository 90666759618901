<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script setup>
import { computed } from 'vue';
import ContentPackagesTable from './ContentPackagesTable.vue';

const props = defineProps({
  packages: {
    type: Array,
    required: true,
  },
});

const pythonPackages = computed(() => props.packages.filter(pkg => pkg.language === 'python'));
const rPackages = computed(() => props.packages.filter(pkg => pkg.language === 'r'));
</script>

<template>
  <ContentPackagesTable
    v-if="pythonPackages.length > 0"
    data-automation="python-packages"
    :packages="pythonPackages"
    title="Python Packages"
  />
  <ContentPackagesTable
    v-if="rPackages.length > 0"
    data-automation="r-packages"
    :packages="rPackages"
    title="R Packages"
  />
</template>
