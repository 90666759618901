// Copyright (C) 2023 by Posit Software, PBC.

import AppHeader from '@/components/AppHeader/index.vue';
import AdminQueueView from '@/views/admin/AdminQueueView.vue';
import AdminTagsView from '@/views/admin/AdminTagsView.vue';
import AuditLogsView from '@/views/admin/AuditLogsView.vue';
import MailSettingsView from '@/views/admin/MailSettingsView.vue';
import SystemChecksView from '@/views/admin/SystemChecksView.vue';
import MetricsView from '@/views/admin/metrics/MetricsView.vue';
import ProcessList from '@/views/admin/metrics/ProcessList.vue';
import ScheduledContent from '@/views/admin/scheduledContent/index.vue';

export default [
  {
    path: '/admin',
    name: 'admin',
    redirect: () => ({ name: 'admin.metrics' })
  },
  {
    path: '/admin/metrics',
    name: 'admin.metrics',
    meta: {
      requireRole: 'administrator',
      title: 'Metrics',
    },
    components: {
      adminContainer: MetricsView,
      header: AppHeader,
    }
  },
  {
    path: '/admin/mail_settings',
    name: 'admin.mail_settings',
    meta: {
      requireRole: 'administrator',
      title: 'Mail Settings',
    },
    components: {
      adminContainer: MailSettingsView,
      header: AppHeader,
    }
  },
  {
    path: '/admin/tag_editor',
    name: 'admin.tag_editor',
    meta: {
      requireRole: 'administrator',
      title: 'Tags',
    },
    components: {
      adminContainer: AdminTagsView,
      header: AppHeader,
    }
  },
  {
    path: '/admin/audit_logs',
    name: 'admin.audit_logs',
    meta: {
      requireRole: 'administrator',
      title: 'Audit Logs',
    },
    components: {
      adminContainer: AuditLogsView,
      header: AppHeader,
    }
  },
  {
    path: '/admin/scheduled_content',
    name: 'admin.scheduled_content',
    meta: {
      requireRole: 'administrator',
      title: 'Scheduled Reports',
    },
    components: {
      adminContainer: ScheduledContent,
      header: AppHeader,
    }
  },
  {
    path: '/admin/system_checks',
    name: 'admin.system_checks',
    meta: {
      requireRole: 'administrator',
      title: 'System Checks',
    },
    components: {
      adminContainer: SystemChecksView,
      header: AppHeader,
    }
  },
  {
    path: '/admin/queue',
    name: 'admin.queue',
    meta: {
      requireRole: 'administrator',
      title: 'Queue',
    },
    components: {
      adminContainer: AdminQueueView,
      header: AppHeader,
    }
  },
  {
    path: '/admin/processes',
    name: 'admin.processes',
    meta: {
      requireRole: 'administrator',
      title: 'Processes',
    },
    components: {
      adminContainer: ProcessList,
      header: AppHeader,
    }
  },
];
