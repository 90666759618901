<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div class="band pushFooter">
    <div class="bandContent mainPage">
      <div
        class="fullPageFormContainer"
        data-automation="register-view"
      >
        <h1 class="formTitle">
          Sign Up
        </h1>
        <form
          class="signup"
          name="registrationForm"
          novalidate
          autocomplete="off"
          @submit.prevent="submitRegisterForm"
        >
          <fieldset>
            <div
              id="username-wrapper"
              class="textInputContainer"
            >
              <RSInputText
                ref="username"
                v-model.trim="form.username"
                autocomplete="off"
                name="username"
                data-automation="username"
                label="Username"
                :message="v$.form.username.$error ? errorMessages.username : null"
                :required="true"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="first-name-wrapper"
              class="textInputContainer"
            >
              <RSInputText
                v-model.trim="form.firstName"
                autocomplete="off"
                name="first_name"
                data-automation="first_name"
                label="First Name"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="last-name-wrapper"
              class="textInputContainer"
            >
              <RSInputText
                v-model.trim="form.lastName"
                autocomplete="off"
                name="last_name"
                data-automation="last_name"
                label="Last Name"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="email-wrapper"
              class="textInputContainer"
            >
              <RSInputText
                ref="email"
                v-model.trim="form.email"
                autocomplete="off"
                name="email"
                type="email"
                data-automation="email"
                label="Email"
                :message="v$.form.email.$error ? errorMessages.email : null"
                :required="true"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="password-wrapper"
              class="textInputContainer"
            >
              <RSInputPassword
                key="password"
                ref="password"
                v-model="form.password"
                autocomplete="off"
                name="password"
                data-automation="password"
                label="Password"
                :message="v$.form.password.$error ? errorMessages.password : null"
                :required="true"
              />
            </div>
          </fieldset>

          <fieldset>
            <div
              id="password2-wrapper"
              class="textInputContainer"
            >
              <RSInputPassword
                key="password2"
                ref="password2"
                v-model="form.password2"
                autocomplete="off"
                name="password2"
                data-automation="password2"
                label="Password (again)"
                :message="v$.form.password2.$error ? errorMessages.password2 : null"
                :required="true"
              />
            </div>
          </fieldset>

          <div class="actions">
            <RSButton
              :disabled="isCreatingUser"
              :label="submitBtnText"
              data-automation="signup-button"
            />
          </div>
          <p
            v-if="server.authentication.notice"
            data-automation="authentication-notice"
          >
            {{ server.authentication.notice }}
          </p>
        </form>
        <div class="formFooter">
          Already have an account?
          <RouterLink :to="routeWithRedirect('login_view')">
            Log In
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/authentication';
import { addNewLocalUser } from '@/api/users';
import RSButton from '@/elements/RSButton.vue';
import RSInputPassword from '@/elements/RSInputPassword.vue';
import RSInputText from '@/elements/RSInputText.vue';
import { routeWithRedirect } from '@/router';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
} from '@/store/modules/messages';
import { getHashQueryParameter, serverURL } from '@/utils/paths';
import * as Validators from '@/utils/validators';
import { useVuelidate } from '@vuelidate/core';
import _ from 'lodash';
import { mapMutations, mapState } from 'vuex';
import { RouterLink } from 'vue-router';

export default {
  name: 'Register',
  components: {
    RSInputText,
    RSInputPassword,
    RSButton,
    RouterLink
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      routeWithRedirect,
      isCreatingUser: false,
      form: {
        username: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
      },
      validationMessages: {
        username: {
          required: 'Username is required.',
          minLength: 'Username is too short. Must be at least 3 characters.',
          maxLength: 'Username is too long. Must be at most 64 characters.',
          prohibited: 'Username is prohibited by policy.',
          firstLetter: 'The first character in a username must be a letter.',
          validCharacters: 'Invalid character used. Can only use letters, numbers, periods, and underscores (_).'
        },
        email: {
          required: 'Email is required.',
          email: 'Not a valid email address.',
          noSpaces: 'Not a valid email address.',
          noEmojis: 'Not a valid email address.'
        },
        password: {
          required: 'This field cannot be blank.',
          minLength: 'Password is too short. Must be at least 6 characters.'
        },
        password2: {
          required: 'This field cannot be blank.',
          same: 'Passwords must match exactly.'
        }
      }
    };
  },
  validations() {
    return this.formValidations();
  },
  computed: {
    submitBtnText() {
      return this.isCreatingUser
        ? 'Please wait...'
        : 'Sign Up';
    },
    errorMessages() {
      const validations = _.pick(this.v$.form, [
        'username',
        'email',
        'password',
        'password2'
      ]);

      const errors = {};
      Object.entries(validations).forEach(([field, fieldValidationObj]) => {
        if (fieldValidationObj.$error) {
          const errorKey = fieldValidationObj.$errors[0].$validator;
          errors[field] = this.validationMessages[field][errorKey];
        }
      });

      return errors;
    },
    ...mapState({
      server: state => state.server.settings,
    })
  },
  mounted() {
    this.$refs.username.focusElement();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    submitRegisterForm() {
      this.v$.form.$touch();

      if (this.v$.form.$invalid) {
        this.focusFirstInvalidField();
        return;
      }

      this.isCreatingUser = true;

      addNewLocalUser({
        username: this.form.username,
        email: this.form.email,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        password: this.form.password,
        userRole: '',
        userMustSetPassword: false
      })
        .then(() => {
          this.clearStatusMessage();
          login({ username: this.form.username, password: this.form.password }).then(
            () => {
              let homeUrl = serverURL('connect');
              const [urlQueryParam] = getHashQueryParameter('url') || [];
              if (urlQueryParam) {
                try {
                  const url = new URL(urlQueryParam);
                  const newUrl = `${url.pathname}${url.hash}`;
                  homeUrl = `${homeUrl}?url=${newUrl}`;
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                } catch (e) {
                  // ignore the error, and redirect to the home page.
                }
              }

              window.location = homeUrl;
            }
          );
        })
        .catch(error => {
          this.setErrorMessageFromAPI(error);
        })
        .finally(() => {
          this.isCreatingUser = false;
        });
    },
    focusFirstInvalidField() {
      const firstInvalidField = Object.keys(this.v$.form).find(
        key => this.v$.form[key].$error
      );
      this.$refs[firstInvalidField].$el.querySelector('input').focus();
    },
    formValidations() {
      return {
        form: {
          username: Validators.usernameValidator(this.server),
          email: {
            ...Validators.emailValidator(),
            noSpaces: value => !value || !value.match(/\s/),
            noEmojis: value => !value || !value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/)
          },
          password: Validators.password(),
          password2: Validators.repeatPassword(this.form.password),
        },
      };
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_variables';
@import 'Styles/shared/_colors';

.pushFooter {
  position: relative;
  min-height: 100%;
  margin-bottom: -$footer-height;

  &:after {
    content: "";
    height: $footer-height;
    display: block;
  }
}

.fullPageFormContainer {
  position: relative;
  max-width: 400px;
  margin: 0 auto;

  form {
    background-color: $color-light-grey;
    padding: 20px 40px 40px 40px;

    .actions {
      text-align: right;
      white-space: nowrap;
      margin-top: 30px;

      button {
        width: 100%;
        text-align: center;
      }
    }
  }

  .formTitle {
    padding: 25px 30px;
    font-size: 18px;
    background-color: $color-light-grey;
    margin: 0;
    font-weight: normal;
    border-bottom: 1px solid #fff;
    text-align: center;
  }

  .textInputContainer {
    padding-top: 15px;
  }
  
  .formFooter {
    text-align: center;
    padding: 20px 50px;
    background-color: $color-light-grey-2;
  }
}

@media screen and (max-width: 420px){

.fullPageFormContainer {
  max-width: 400px;

  form {
    padding: 20px;
  }

  .formTitle {
    padding-left: 20px;
    padding-right: 20px;
  }
}

}
</style>
