<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSTable
    v-if="applications.length"
    :columns="tableColumns"
    table-name="Content"
    data-automation="content-list__table"
  >
    <RSTableRow
      v-for="app in applications"
      :key="app.guid"
    >
      <RSTableCell>
        <BookmarkButton
          :bookmarked="app.bookmarked"
          :guid="app.guid"
          @created="app.bookmarked = true"
          @deleted="app.bookmarked = false"
        />
      </RSTableCell>

      <RSTableCell
        :cell-id="`app-name-${app.guid}`"
        :has-icon="true"
        :fill="true"
        data-automation="content-row-icon-title-cell"
      >
        <RouterLink
          :to="{ name: 'apps', params: { guid: app.guid } }"
          class="content-table__content-title"
        >
          <div class="content-table__content-name">
            <div>
              <i
                :class="app.iconClass()"
                class="rs-icon rs-icon--large"
              />
            </div>
            <div
              data-automation="content-table__row__display-name"
              :class="[{ 'unpublished': !app.isDeployed() }, 'content-table__display-name']"
            >
              {{ app.contentDisplayName() || 'Untitled' }}
            </div>
          </div>
        </RouterLink>
      </RSTableCell>
      <RSTableCell data-automation="content-row-type-cell">
        {{ descriptionFor(app) }}
      </RSTableCell>
      <!-- Content author (owner info is available) -->
      <RSTableCell
        v-if="hasOwner(app)"
        :cell-id="`${app.id}-author`"
        data-automation="content-row-author-cell"
      >
        <RouterLink
          :to="{ name: 'people.users.profile', params: { guid: app.ownerGuid } }"
        >
          <span data-automation="content-row-author">
            {{ ownerName(app) }}
          </span>
        </RouterLink>
      </RSTableCell>
      <!-- Content author (empty cell b/c owner info is missing) -->
      <RSTableCell
        v-else
        data-automation="content-row-author-cell"
      />
      <RSTableCell
        v-if="app.isDeployed()"
        data-automation="content-row-updated-cell"
      >
        {{ lastDeployedTime(app) }}{{ fromGit(app.git) }}
      </RSTableCell>
      <RSTableCell
        v-else
        class="unpublished"
      >
        unpublished
      </RSTableCell>
    </RSTableRow>
  </RSTable>
</template>

<script>
import RSTable from '@/elements/RSTable.vue';
import RSTableCell from '@/elements/RSTableCell.vue';
import RSTableRow from '@/elements/RSTableRow.vue';

import BookmarkButton from '@/components/BookmarkButton.vue';

import { activeTime } from '@/utils/activeTime.filter';
import { displayOwnerName, fromGit, hasOwner } from '@/utils/contentList';
import truncate from 'lodash/truncate';
import { RouterLink } from 'vue-router';
import { contentTypeLabel } from './contentTypes';

export default {
  name: 'ContentTable',
  components: {
    BookmarkButton,
    RouterLink,
    RSTable,
    RSTableRow,
    RSTableCell,
  },
  props: {
    applications: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.tableColumns = [
      {
        label: '<span class="sr-only">Bookmarks</span>',
      },
      {
        label: 'Name',
        width: '100%',
      },
      { label: 'Type' },
      { label: 'Owner' },
      { label: 'Last Deployed' },
    ];
  },
  methods: {
    hasOwner,
    fromGit,
    ownerName(app) {
      const ownerName = displayOwnerName(app);
      return truncate(ownerName, { length: 18 });
    },
    lastDeployedTime(app) {
      return activeTime(app.lastDeployedTime);
    },
    descriptionFor(app) {
      return contentTypeLabel(app.appMode);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-table {
  &__content-title {
    display: block;
  }

  &__content-name {
    display: flex;
    align-items: center;
  }

  &__display-name {
    margin-left: 0.5em;
    white-space: normal;
    line-height: 1.2rem;
    word-wrap: break-word;
    word-break: break-word;
    flex: 1;
    &.unpublished {
      font-style: italic;
    }
  }
}
.unpublished {
  font-style: italic;
}
</style>
