<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { UPDATE_LAST_VIEWED_TIMESTAMP, SHOW_NOTIFICATION } from '@/store/modules/whatsNew';
import WhatsNewArticle from '@/components/WhatsNew/WhatsNewArticle.vue';
import WhatsNewEmptyState from '@/components/WhatsNew/WhatsNewEmptyState.vue';
import Spinner from '@/components/Spinner.vue';

dayjs.extend(utc);

const localTime = dayjs.utc();
const store = useStore();
const feed = computed(() => store.state.whatsNew.feed);
const isLoading = computed(() => store.state.whatsNew.isLoading);
const hasError = computed(() => store.state.whatsNew.hasFeedError);

const updateLastViewedTimestamp = () => {
  if (!isLoading.value && !hasError.value) {
    store.dispatch(UPDATE_LAST_VIEWED_TIMESTAMP, localTime.format());
    store.commit(SHOW_NOTIFICATION, false);
  }
};

onMounted(() => {
  updateLastViewedTimestamp();
});
</script>

<template>
  <div class="band">
    <div class="bandContent whats-new-content">
      <div 
        v-if="isLoading"
      >
        <Spinner />
      </div>
      <WhatsNewEmptyState
        v-if="hasError"
        data-automation="whats-new-empty"
      />
      <section
        v-if="!isLoading && !hasError && feed.length > 0"
        id="whats-new"
        class="whats-new-section"
        data-automation="whats-new"
      >
        <h1 class="whats-new-title">
          <img
            src="/images/elements/whatsNew.svg"
            role="presentation"
            alt=""
            class="whats-new-icon"
          >
          What's new
        </h1>
        <WhatsNewArticle
          v-for="article in feed"
          :key="article.guid"
          :article="article"
          data-automation="whats-new-article-list"
        />
      </section>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_variables';

.whats-new-content {
  padding-top: $header-height;
  padding-bottom: 175px;
}

.whats-new-title {
  font-size: 1.5rem;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 22px;
  margin-bottom: 24px;
}

.whats-new-icon {
  height: 20px;
  vertical-align: middle;
  width: 20px;
}
</style>
