<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<template>
  <button
    type="button"
    title="Bookmark"
    aria-label="Toggle bookmark"
    :class="{ bookmarked }"
    @click="toggleBookmark"
  >
    <svg
      role="img"
      aria-labelledby="title"
      :class="{ bookmarked }"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      class="size-6"
    >
      <title id="title">{{ title }}</title>
      <path
        fill-rule="evenodd"
        d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
</template>

<script setup>
import { computed } from 'vue';
import { createBookmark, deleteBookmark } from '@/api/bookmark';
import { useStore } from 'vuex';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';

const props = defineProps({
  bookmarked: {
    type: Boolean,
    required: true,
  },
  guid: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['created', 'deleted']);

const store = useStore();

const setErrorMessage = err => store.commit(SET_ERROR_MESSAGE_FROM_API, err);

const title = computed(() => (props.bookmarked ? 'Bookmarked' : 'Not bookmarked'));

const toggleBookmark = () => {
  if (!props.bookmarked) {
    createBookmark(props.guid)
      .then(() => {
        emit('created');
      })
      .catch(setErrorMessage);
  } else {
    deleteBookmark(props.guid)
      .then(() => {
        emit('deleted');
      })
      .catch(setErrorMessage);
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

button {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
}

svg {
  width: 20px;
  height: 20px;
  transition: fill .3s ease;

  &:not(.bookmarked) {
    fill: $color-button-background;

    &:hover {
      fill: darken($color-button-background, 20%);
    }
  }

  &.bookmarked {
    fill: $color-posit-blue;
  }
}
</style>