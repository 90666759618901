<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  This component responsibility is to show the activation token blurb,
  handle websudo re-authentication if needed,
  and display the outcome blurb (success, error, cancelled).
-->

<template>
  <div
    data-automation="activate-ide-token"
  >
    <RestrictedAccessWrapper
      v-slot="{ executeRestrictedApi }"
      :eager="false"
    >
      <h2 class="rsc-lead">
        {{ subtitle }}
      </h2>

      <div
        v-if="isWaitingInput"
      >
        <p>
          You're almost done configuring R to publish content to Posit
          Connect. Click the <span class="emphasize">Connect</span> button below to link your Posit
          Connect account (<span class="emphasize">{{ username }}</span>) to
          the <span class="emphasize">rsconnect</span> R package.
        </p>

        <p>
          If you didn't reach this screen by initiating an account link from the
          RStudio IDE or in R via <code>rsconnect::connectUser</code>, click <span class="emphasize">Cancel</span>.
        </p>

        <div class="token-actions">
          <RSButton
            type="secondary"
            data-automation="cancel-token-btn"
            label="Cancel"
            @click.prevent="cancelToken"
          />
          <RSButton
            data-automation="activate-token-btn"
            label="Connect"
            @click.prevent="() => activateToken(executeRestrictedApi)"
          />
        </div>
      </div>

      <!-- Connecting Success -->
      <div
        v-if="tokenActivated"
        data-automation="activation-success-blurb"
      >
        <p>
          You've successfully connected the <span class="emphasize">rsconnect</span> package
          to Posit Connect! If you're using the RStudio IDE, click the
          <span class="emphasize">Connect Account</span> button to finish adding this account.
        </p>

        <p>
          Now you are ready to publish a
          <a
            target="_blank"
            :href="publishingDocs"
          >
            Shiny application, R Markdown document, or any plot
          </a>
          created with R.
        </p>
      </div>

      <!-- Connecting Failed -->
      <div
        v-if="activationFailed"
        data-automation="activation-failed-blurb"
      >
        <p>
          We couldn't connect your account. Try connecting again, and contact
          your administrator if the problem persists.
        </p>
      </div>

      <!-- Connection Cancelled -->
      <div
        v-if="activationCancelled"
        data-automation="activation-cancelled-blurb"
      >
        <p>
          If this was in error, please read the
          <a
            target="_blank"
            :href="connectingDocs"
          >
            documentation
          </a>
          for help getting the RStudio IDE connected to your account.
        </p>
      </div>
    </RestrictedAccessWrapper>
  </div>
</template>

<script>
import { connectUserToken } from '@/api/users';
import RestrictedAccessWrapper, {
  ReauthenticationInProgressError,
} from '@/components/RestrictedAccessWrapper';
import RSButton from '@/elements/RSButton.vue';
import { docsPath } from '@/utils/paths';

const TOKEN_WAITING = 'waiting';
const TOKEN_FAILED = 'failed';
const TOKEN_ACTIVATED = 'activated';
const TOKEN_CANCELLED = 'cancelled';

export default {
  name: 'ActivateToken',
  components: {
    RSButton,
    RestrictedAccessWrapper,
  },
  props: {
    username: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tokenStatus: TOKEN_WAITING,
    };
  },
  computed: {
    subtitle() {
      switch (this.tokenStatus) {
        case TOKEN_FAILED:
          return 'Connection Failed';
        case TOKEN_ACTIVATED:
          return 'Connection Succeeded';
        case TOKEN_CANCELLED:
          return 'Connection Cancelled';
        default:
          return 'Confirm Package Connection';
      }
    },
    isWaitingInput() {
      return this.tokenStatus === TOKEN_WAITING;
    },
    activationCancelled() {
      return this.tokenStatus === TOKEN_CANCELLED;
    },
    activationFailed() {
      return this.tokenStatus === TOKEN_FAILED;
    },
    tokenActivated() {
      return this.tokenStatus === TOKEN_ACTIVATED;
    },
    publishingDocs() {
      return docsPath('user/publishing-overview');
    },
    connectingDocs() {
      return docsPath('user/connecting');
    },
  },
  methods: {
    cancelToken() {
      this.tokenStatus = TOKEN_CANCELLED;
    },
    activateToken(executeRestrictedApi) {
      executeRestrictedApi(connectUserToken(this.token))
        .then(() => {
          this.tokenStatus = TOKEN_ACTIVATED;
          return TOKEN_ACTIVATED;
        })
        .catch(err => {
          if (!(err instanceof ReauthenticationInProgressError)) {
            this.tokenStatus = TOKEN_FAILED;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.token-actions {
  padding: 1rem 0;
  text-align: right;

  & > * {
    margin: 0 0.5rem;
  }
}
</style>
