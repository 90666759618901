<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<template>
  <table
    :data-automation="dataAutomation"
    :aria-label="tableName"
    class="rs-table"
  >
    <thead
      v-if="hasHeaders"
      class="rs-table__head"
    >
      <tr class="rs-align--left">
        <th
          v-for="(columnHeader, index) in columns"
          :key="'column_' + index"
          :class="getHeadingClass(index)"
          :colspan="columnHeader.size || 1"
          :width="columnHeader.width || 'auto'"
          :role="columnHeader.sortable ? 'button' : 'columnheader'"
          :tabindex="columnHeader.sortable ? '0' : ''"
          :data-automation="`${dataAutomation}-${columnHeader.label}`"
          @click="triggerSorting(index)"
          @keydown.enter="triggerSorting(index)"
          @keydown.space="triggerSorting(index)"
        >
          {{ columnHeader.label || columnHeader }}
        </th>
      </tr>
    </thead>
    <tbody>
      <slot />
    </tbody>
  </table>
</template>

<script>
export const sortOrder = {
  ascending: 'asc',
  descending: 'desc',
};

const headingClass = {
  base: 'rs-table__heading',
  clickable: 'rs-table__heading--clickable',
  ascending: 'rs-table__heading--ascending',
  descending: 'rs-table__heading--descending',
};

export default {
  name: 'RSTable',
  props: {
    dataAutomation: {
      type: String,
      default: null,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    tableName: {
      type: String,
      default: null,
    }
  },
  emits: ['sort'],
  computed: {
    hasHeaders() {
      return Boolean(this.columns.length);
    },
  },
  methods: {
    triggerSorting(colIndex) {
      const header = this.columns[colIndex];
      if (header.sortable) {
        let direction = null;
        if (header.direction === sortOrder.descending) {
          direction = sortOrder.ascending;
        }
        if (header.direction === sortOrder.ascending) {
          direction = sortOrder.descending;
        }

        this.$emit('sort', {
          index: colIndex,
          direction,
        });
      }
    },
    getHeadingClass(colIndex) {
      const columnClass = (this.columns[colIndex].class || '').split(' ');
      const classMap = [headingClass.base, ...columnClass];
      const header = this.columns[colIndex];

      if (header.sortable) {
        classMap.push(headingClass.clickable);

        if (header.direction === sortOrder.descending) {
          classMap.push(headingClass.descending);
        } else if (header.direction === sortOrder.ascending) {
          classMap.push(headingClass.ascending);
        }
      }

      return classMap;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';
@import 'Styles/shared/_mixins';

.rs-table {
  width: 100%;
  border-collapse: collapse;
  * {
    @include control-visible-focus();
  }

  &__head {
    background-color: $color-light-grey;
    border: 1px solid $color-light-grey-2;
    color: $color-heading;
  }

  &__heading {
    @include cell-padding;
    font-size: $rs-font-size-smaller;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;

    &--clickable {
      @include clickable-table-cell(darken($color-light-grey, 3%), 250ms);
    }

    &--descending,
    &--ascending {
      background-size: $rs-icon-size $rs-icon-size;
      background-repeat: no-repeat;
      background-position: right 5px center;

      & a:hover {
        color: $color-link-hover-cell;
      }
    }

    &--descending {
      background-image: url(/images/elements/sortDescending.svg);
    }

    &--ascending {
      background-image: url(/images/elements/sortAscending.svg);
    }
  }
}
</style>
