<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    id="app"
    :class="cssClass"
    tabindex="-1"
  >
    <SkipToMainContent />
    <div v-if="loaded">
      <main
        id="main"
        :class="mainPageClass"
      >
        <RouterView name="header" />

        <UserView v-if="userPage">
          <RouterView name="userContainer" />
        </UserView>
        <PeopleView v-else-if="peoplePage">
          <RouterView name="peopleContainer" />
        </PeopleView>

        <AdminView v-if="adminPage">
          <RouterView name="adminContainer" />
        </AdminView>

        <RouterView />
        <RouterView name="footer" />
      </main>
      <NavPanel v-if="showNavPanel" />
      <CurrentUserMenu v-if="showUserMenu" />
    </div>
    <div
      v-else
      class="spinner-center-holder"
    >
      <Spinner />
    </div>

    <!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
    <!-- eslint-disable vuejs-accessibility/no-static-element-interactions -->
    <!--
      Disabling this linting rules for the following element because those key event expectations
      are met within the indivicual panels handling the "esc" key.
      This one is to maintain backwards funtionality that panels close when clicking outside.
    -->
    <div
      id="mainCover"
      data-automation="main-cover"
      @click="hideSidePanels"
    />
  </div>
</template>

<script>
import NavPanel from '@/components/NavPanel/NavPanel';
import CurrentUserMenu from '@/components/CurrentUserMenu/CurrentUserMenu.vue';
import SkipToMainContent from '@/components/SkipToMainContent.vue';
import Spinner from '@/components/Spinner';
import { CURRENT_USER_LOAD } from '@/store/modules/currentUser';
import { LOAD_CONNECT_FEED } from '@/store/modules/whatsNew';
import { SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import {
  SHOW_NAV_PANEL,
  SHOW_USER_MENU
} from '@/store/modules/navigation';
import { SERVER_SETTINGS_LOAD } from '@/store/modules/server';
import AdminView from '@/views/admin/AdminView/AdminView';
import PeopleView from '@/views/users/PeopleView.vue';
import UserView from '@/views/users/UserView.vue';
import { RouterView } from 'vue-router';
import { mapActions, mapMutations, mapState } from 'vuex';

const FIVE_MINUTES = 300 * 1_000;

export default {
  name: 'App',
  components: {
    AdminView,
    NavPanel,
    CurrentUserMenu,
    PeopleView,
    RouterView,
    SkipToMainContent,
    Spinner,
    UserView,
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
      serverSettingsLoaded: state => state.server.loaded,
      currentUserLoaded: state => state.currentUser.loaded,
      isAuthenticated: state => state.currentUser.isAuthenticated,
      lastKnownSession: state => state.currentUser.lastKnownSession,
      showUserMenu: state => state.navigation.showUserMenu,
      showNavPanel: state => state.navigation.showNavPanel,
    }),
    loaded() {
      return this.serverSettingsLoaded && this.currentUserLoaded;
    },
    adminPage() {
      return this.$route.name?.includes('admin');
    },
    peoplePage() {
      return this.$route.name?.includes('people');
    },
    userPage() {
      return this.$route.name?.includes('people') &&
        !!this.$route.params.guid;
    },
    mainPageClass() {
      const className = [];
      if (this.$route.name?.includes('apps')) {
        className.push('contentView');
      };
      return className;
    },
    cssClass() {
      const className = [];
      if (this.showNavPanel) {
        className.push('showNavPanel');
      } else if (this.showUserMenu) {
        className.push('showUserMenu');
      }
      return className;
    },
  },
  async created() {
    this.checkServerAndWarnings()
      .then(this.loadUser)
      .then(this.loadFeed)
      .finally(this.periodicSessionCheck());
  },
  methods: {
    ...mapActions({
      statusErrorMessage: SHOW_ERROR_MESSAGE,
      loadServerSettings: SERVER_SETTINGS_LOAD,
      loadCurrentUser: CURRENT_USER_LOAD,
      loadConnectFeed: LOAD_CONNECT_FEED,
    }),
    ...mapMutations({
      showingUserMenu: SHOW_USER_MENU,
      showingNavPanel: SHOW_NAV_PANEL
    }),
    hideSidePanels() {
      this.showingNavPanel(false);
      this.showingUserMenu(false);
    },
    async checkServerAndWarnings() {
      if (!this.serverSettingsLoaded) {
        await this.loadServerSettings();
      }
      if (this.serverSettings?.httpWarning && window.location.protocol !== 'https') {
        // This is a warning, not an error, but we show the message as an error
        // To have more predominant color schemes for this kind of message.
        this.statusErrorMessage({
          message: `Warning: You are accessing ${this.serverSettings.systemDisplayName} over an insecure HTTP connection.`,
        });
      }
    },
    periodicSessionCheck() {
      // Session expired check to run every 5 mins.
      // If there was an existing session and expires,
      // user is redirected to login again with a ?url to get back
      // to where it was.
      const checkFrequency = FIVE_MINUTES;
      const sessionCheck = async() => {
        // Do not do anything if there was not a previous session.
        if (!this.lastKnownSession) {
          return;
        }

        const lastKnownUser = this.currentUser.guid;

        await this.loadCurrentUser(false);
        // If user lost the session, redirect to login
        if (!this.isAuthenticated) {
          this.$router.push({
            name: 'login_view',
            query: {
              url: window.location.href,
            },
          });
        } else if (lastKnownUser !== this.currentUser.guid) {
          // Now we are a different user, reload the page.
          window.location.reload();
        }
      };

      // We were elsewhere, check session when getting back
      window.onfocus = sessionCheck;

      // Start the periodic check
      setInterval(sessionCheck, checkFrequency);
    },
    async loadUser() {
      if (!this.currentUserLoaded) {
        await this.loadCurrentUser();
      }
    },
    async loadFeed() {
      if (this.serverSettings?.whatsNewEnabled && this.isAuthenticated) {
        await this.loadConnectFeed();
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.spinner-center-holder {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  & > * {
    height: 10rem;
    width: 10rem;
  }
}
</style>
