// Copyright (C) 2024 by Posit Software, PBC.

// ContentPackage reflects the backend struct `packageDTO` in the content packages API
export class ContentPackage {
  /* eslint-disable no-shadow */
  constructor({
    language,
    name,
    version,
    hash
  } = {}) {
    this.language = language;
    this.name = name;
    this.version = version;
    this.hash = hash;
  }

  toJSON() {
    return {
      language: this.language,
      name: this.name,
      version: this.version,
      hash: this.hash,
    };
  }
}
