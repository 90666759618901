<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <RSModal
    data-automation="delete-group"
    :active="true"
    :subject="`Delete Group: ${group.name}`"
    @close="$emit('close')"
  >
    <template #content>
      <div
        class="details rsc-text"
        data-automation="content-remove-message"
      >
        <p class="rsc-alert warning">
          Removing this group will disable access to all content related to this group.
          Please consider that users belonging to <strong>{{ group.name }}</strong> will stop seeing that content (if any).
        </p>
        <p>
          Are you sure you want to remove the group named: <strong>{{ group.name }}</strong>?
        </p>
      </div>
    </template>
    <template #controls>
      <RSButton
        label="No"
        type="secondary"
        class="delete-group__cancel-button"
        @click="$emit('close')"
      />
      <RSButton
        id="dgd-submit"
        label="Yes"
        data-automation="delete-group__ok-button"
        @click="deleteGroup"
      />
    </template>
  </RSModal>
</template>

<script>
import { deleteGroup } from '@/api/groups';
import RSButton from '@/elements/RSButton.vue';
import RSModal from '@/elements/RSModal.vue';
import {
  CLEAR_STATUS_MESSAGE,
  SET_ERROR_MESSAGE_FROM_API,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'DeleteGroupDialog',
  components: { RSButton, RSModal },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  mounted() {
    this.$el.querySelector('#dgd-submit').focus();
  },
  methods: {
    ...mapMutations({
      clearStatusMessage: CLEAR_STATUS_MESSAGE,
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    ...mapActions({
      setInfoMessage: SHOW_INFO_MESSAGE,
    }),
    deleteGroup() {
      this.clearStatusMessage();
      return deleteGroup(this.group.guid)
        .then(() => {
          this.setInfoMessage({ message: `Group ${this.group.name} successfully deleted.` });
          this.$emit('close', this.group.guid);
        })
        .catch(e => {
          this.setErrorMessageFromAPI(e);
          this.$emit('close');
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.delete-group__cancel-button {
  margin-right: 1rem;
}
.rsc-text p {
  margin-bottom: 1rem;
}
</style>
