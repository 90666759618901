<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!--
  AuditLogsView
  Renders the routable Audit Logs view container
-->
<template>
  <div>
    <div class="rsc-audit-logs__header">
      <div
        class="flex"
        data-automation="audit-logs"
      >
        <h1 class="sectionTitle focusedTitle">
          Audit Logs
        </h1>
      </div>
      <div>
        {{ `All times are displayed in local time (${offset})` }}
      </div>
    </div>
    <div
      v-if="displayTable"
      class="tableContainer"
    >
      <table class="contentListing">
        <thead>
          <tr>
            <th
              class="auditTimeColumn"
              data-automation="audit-time-column"
            >
              Time
            </th>
            <th
              class="auditUserColumn"
              data-automation="audit-user-column"
            >
              User
            </th>
            <th
              class="auditEventColumn"
              data-automation="audit-event-column"
            >
              Event
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(log, i) in auditLogs"
            :key="i"
          >
            <td>
              {{ log.time }}
            </td>
            <td>
              {{ log.userDescription }}
            </td>
            <td>
              {{ log.eventDescription }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <RSPager
      v-if="displayTable"
      :disable-left-actions="disablePagingLeft"
      :disable-right-actions="disablePagingRight"
      :labels="pagerLabels"
      @first-page="getLogs"
      @previous-page="getLogs"
      @next-page="getLogs"
      @last-page="getLogs"
    />
  </div>
</template>

<script>
import { getAuditLogs } from '@/api/auditLogs';
import RSPager from '@/elements/RSPager.vue';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { apiV1Path } from '@/utils/paths';
import { localOffset } from '@/utils/timezone';
import { mapMutations } from 'vuex';

const firstPagePath = apiV1Path('audit_logs?ascOrder=false');

export default {
  name: 'AuditLogsView',
  components: { RSPager },
  data() {
    return {
      auditLogs: [],
      paging: {},
      disablePagingLeft: true,
      disablePagingRight: true,
      offset: localOffset(),
    };
  },
  computed: {
    displayTable() {
      return this.auditLogs.length > 0;
    },
    pagerLabels: () => ({
      first: 'Newest',
      previous: 'Newer',
      next: 'Older',
      last: 'Oldest',
    }),
  },
  created() {
    this.getLogs();
  },
  methods: {
    ...mapMutations({
      setErrorMessageFromAPI: SET_ERROR_MESSAGE_FROM_API,
    }),
    getLogs(action) {
      const path = this.paging[action] || firstPagePath;
      return getAuditLogs(path)
        .then(res => {
          this.auditLogs = (res || {}).auditLogs || [];
          this.paging = (res || {}).paging || {};
          const { previous, next } = this.paging.cursors;
          this.updatePagingActiveButtons(previous, next);
        })
        .catch(err => {
          this.setErrorMessageFromAPI(err);
          this.auditLogs = [];
          this.paging = {};
        });
    },
    updatePagingActiveButtons(left, right) {
      this.disablePagingLeft = !left;
      this.disablePagingRight = !right;
    },
  },
};
</script>

<style lang="scss" scoped>
.tableContainer {
  overflow-x: auto;
}
.rsc-audit-logs__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
</style>
