<!-- Copyright (C) 2025 by Posit Software, PBC. -->

<script setup>
import { getFeatureUsage } from '@/api/featureUsage';
import { getAvailableIntegrations } from '@/api/oauth';
import { keysToSnake } from '@/api/transform';
import RSButton from '@/elements/RSButton.vue';
import RSModal from '@/elements/RSModal.vue';
import RSTable from '@/elements/RSTable.vue';
import RSTableRow from '@/elements/RSTableRow.vue';
import RSTableCell from '@/elements/RSTableCell.vue';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { copyToClipboard } from '@/utils/clipboard';
import { docsPath } from '@/utils/paths';
import dayjs from 'dayjs';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import startCase from 'lodash/startCase';

const store = useStore();
const showNotifyCopyDialog = ref(false);
const usageMetrics = ref({});
const generalMetrics = ref([]);

const scorecardFields = [
  { name: 'custom_email', type: 'general', link: docsPath('user/scheduling/#send-email') },
  { name: 'scheduled', type: 'general', link: docsPath('user/scheduling/') },
  { name: 'vanity_url', type: 'general', link: docsPath('user/content-settings/#custom-url') },
  { name: 'git-backed_content', type: 'general', link: docsPath('user/git-backed/') },
  { name: 'oauth_integrations', type: 'general', link: docsPath('user/oauth-integrations/') },

  { name: 'interactive_quarto', type: 'content', link: null },
  { name: 'interactive_rmarkdown', type: 'content', link: docsPath('user/interactive-documents/#shiny-r-markdown') },
  { name: 'jupyter_notebook', type: 'content', link: docsPath('user/jupyter-notebook/') },
  { name: 'jupyter_voila', type: 'content', link: docsPath('user/interactive-documents/#voila') },
  { name: 'parameterized_rmarkdown', type: 'content', link: docsPath('user/param-rmarkdown/') },
  { name: 'parameterized_rmarkdown_variants', type: 'content', link: docsPath('user/param-rmarkdown/#param-rmarkdown-variants') },
  { name: 'plumber', type: 'content', link: docsPath('user/plumber/') },
  { name: 'python_api', type: 'content', link: docsPath('user/flask/') },
  { name: 'python_bokeh', type: 'content', link: docsPath('user/bokeh/') },
  { name: 'python_dash', type: 'content', link: docsPath('user/dash/') },
  { name: 'python_gradio', type: 'content', link: docsPath('user/gradio/') },
  { name: 'python_shiny', type: 'content', link: docsPath('user/shiny-python/') },
  { name: 'python_streamlit', type: 'content', link: docsPath('user/streamlit/') },
  { name: 'quarto', type: 'content', link: docsPath('user/quarto/') },
  { name: 'rmarkdown', type: 'content', link: docsPath('user/rmarkdown/') },
  { name: 'python_fastapi', type: 'content', link: docsPath('user/fastapi/') },
  { name: 'shiny', type: 'content', link: docsPath('user/shiny/') },
];

const serverSettings = computed(() => store.state.server.settings);
const hasData = computed(() => Object.keys(usageMetrics.value).length !== 0);

const contentTypeFields = computed(() => {
  const fields = scorecardFields.filter(metric => metric.type === 'content');
  fields.forEach(field => {
    field.enabled = usageMetrics.value[field.name];
    field.name = startCase(field.name);

    // special case handling
    field.name = field.name.replace('Api', 'API');
    field.name = field.name.replace('Rmarkdown', 'RMarkdown');
  });

  return fields.filter(field => field.enabled !== undefined);
});

onMounted(async() => {
  try {
    const data = await getFeatureUsage();
    usageMetrics.value = data.reduce((map, obj) => {
      map[obj.name] = obj.used;
      return map;
    }, {});
    await generalFields();
  }
  catch{(e => store.commit(SET_ERROR_MESSAGE_FROM_API, e)); };
});

const generalFields = async() => {
  const fields = scorecardFields.filter(metric => metric.type === 'general');
  fields.forEach(field => {
    field.enabled = usageMetrics.value[field.name];
    field.name = startCase(field.name);

    // special case handling
    field.name = field.name.replace('Oauth', 'OAuth');
    field.name = field.name.replace('Url', 'URL');
  });

  // get git info? TODO
  if (serverSettings.value.gitAvailable && serverSettings.value.gitEnabled) {
    fields.find(metric => metric.name === 'Git Backed Content').enabled = undefined;
  };

  // see if there are any integrations set up
  if (serverSettings.value.oauthIntegrationsEnabled) {
    const integrations = await getAvailableIntegrations();
    if (integrations.length === 0) {
      fields.find(metric => metric.name === 'OAuth Integrations').enabled = false;
    } else {
      fields.find(metric => metric.name === 'OAuth Integrations').enabled = true;
    }
  };

  generalMetrics.value = fields.filter(field => field.enabled !== undefined);
};

const closeNotifyCopyDialog = () => {
  showNotifyCopyDialog.value = false;
};

const copyReport = async() => {
  // Adds metadata and converts {foo: 'bar', baz: 'xyzzy'} into 'foo: bar\nbaz: xyzzy'
  const metadata = keysToSnake({
    connectVersion: serverSettings.value.version,
    dateTime: dayjs().format('YYYY/MM/DD HH:mm:ss.SSS'),
  });

  const copyFields = { ...metadata, ...usageMetrics.value };
  const fullMetrics = Object.entries(copyFields)
    .map(([k, v]) => {
      return `${k}: ${v}`;
    })
    .join('\n');

  await copyToClipboard({ value: fullMetrics });
  showNotifyCopyDialog.value = true;
}; 
</script>

<template>
  <div data-automation="metrics-feature-usage">
    <div class="header">
      <h2 class="sectionTitle">
        Usage Scorecard
      </h2>
      <RSButton
        v-show="hasData"
        :use-label-width="true"
        label="Copy"
        type="secondary"
        class="copy-button"
        @click="copyReport"
      />
    </div>
    <div class="info">
      <p>
        The usage scorecard indicates what top-line Posit Connect features
        you have used.
      </p>
      <p>
        To help get the most from your Connect instance, you can share this information 
        with your Customer Success representative.
      </p>
    </div>
    <h3
      v-if="!hasData"
      data-automation="no-data-message"
    >
      Unable to load usage data.
    </h3>
    <div
      v-else
      class="usage-scorecard"
    >
      <h3 class="usage-scorecard__title">
        General
      </h3>
      <RSTable>
        <RSTableRow
          v-for="(field, i) in generalMetrics"
          :key="i"
          :class="i % 2 === 0 && 'stripe'"
        >
          <RSTableCell class="usage-scorecard__name">
            <a
              v-if="field.link"
              :href="field.link"
              target="_blank"
            >
              {{ field.name }}
            </a>
            <div v-else>
              {{ field.name }}
            </div>
          </RSTableCell>
          <RSTableCell
            class="usage-scorecard__status"
          >
            {{ field.enabled ? '🎉 Yes!' : '👀 Not yet' }}
          </RSTableCell>
        </RSTableRow>
      </RSTable>
      <h3 class="usage-scorecard__title">
        Content Types
      </h3>
      <RSTable data-automation="metrics-table">
        <RSTableRow
          v-for="(field, i) in contentTypeFields"
          :key="i"
          :class="i % 2 === 0 && 'stripe'"
        >
          <RSTableCell class="usage-scorecard__name">
            <a
              v-if="field.link"
              :href="field.link"
              target="_blank"
            >
              {{ field.name }}
            </a>
            <div v-else>
              {{ field.name }}
            </div>
          </RSTableCell>
          <RSTableCell
            class="usage-scorecard__status"
          >
            {{ field.enabled ? '🎉 Yes!' : '👀 Not yet' }}
          </RSTableCell>
        </RSTableRow>
      </RSTable>
    </div>

    <RSModal
      v-if="showNotifyCopyDialog"
      subject="Usage Scorecard Copied"
      close-button-label="Close Modal"
      :active="showNotifyCopyDialog"
      @close="closeNotifyCopyDialog"
    >
      <template #content>
        <div class="usage-scorecard__copy-info">
          <p>
            The usage scorecard data has been copied. Please share it with your
            Customer Success representative to learn more about Connect features you 
            may be missing out on!
          </p>
          <p>
            Thank you for helping us make Posit Connect better!
          </p>
        </div>
      </template>
      <template #controls>
        <RSButton
          label="OK"
          @click="closeNotifyCopyDialog"
        />
      </template>
    </RSModal>
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  .sectionTitle {
    align-self: center;
    margin: 0;
  }
  .copy-button {
    padding: 0.4rem 1.5rem;
  }
}
.info {
  margin-bottom: 1rem;
}
.usage-scorecard {
  &__title {
    font-size: 1.2rem;
    font-weight: 400;
    margin: 2rem 0 1rem;
  }
  &__name {
    font-weight: 700;
    font-size: 0.92rem;
    a {
      padding-right: 1.2rem;
      background-image: url('/images/external-link.svg');
      background-position: right center;
      background-size: 1.0rem;
      background-repeat: no-repeat;
    }
  }
  &__status {
    width: 8rem;
  }
  &__copy-info p {
    margin-bottom: 0.5rem;
  }
}
.stripe {
  background-color: color-mix(in srgb, #d1dbe5 20%, #fff);
}
</style>
