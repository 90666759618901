<!-- Copyright (C) 2024 by Posit Software, PBC. -->
<script setup>
import { getPublishedContentForUser } from '@/api/content';
import { safeAPIErrorMessage } from '@/api/error';
import EmbeddedStatusMessage from '@/components/EmbeddedStatusMessage';
import Paginator from '@/components/Paginator.vue';
import RSTable from '@/elements/RSTable.vue';
import RSTableCell from '@/elements/RSTableCell.vue';
import RSTableRow from '@/elements/RSTableRow.vue';
import { activeTime } from '@/utils/activeTime.filter';
import { contentDescriptions } from '@/utils/contentList';
import { computed, onBeforeMount, reactive } from 'vue';
import { RouterLink } from 'vue-router';

const props = defineProps({
  userName: {
    type: String,
    required: true,
  },
});

const pageSize = 10;

const localState = reactive({
  status: {
    message: null,
    type: null,
  },
  api: {
    applications: [],
    pageNumber: 1,
    total: 0,
  }
});

const tableColumns = [
  {
    label: 'Name',
    size: 1,
    width: '100%',
  },
  { label: 'Type' },
  { label: 'Last Deployed' },
];

const showPager = computed(() => localState.api.total > pageSize);

onBeforeMount(() => {
  fetchData();
});

const fetchData = async() => {
  const timeoutId = setTimeout(() => {
    setStatus('activity', `Loading User's Published Content...`);
  }, 300);

  try {
    const { total, results } = await getPublishedContentForUser({
      userName: props.userName,
      page: localState.api.pageNumber,
      perPage: pageSize,
    });

    localState.api.applications = results;
    localState.api.total = total;
    hideStatus();
  } catch (err) {
    setStatus('error', safeAPIErrorMessage(err));
  }
  clearTimeout(timeoutId);
};

const setStatus = (type, message) => {
  localState.status.type = type;
  localState.status.message = message;
};
const hideStatus = () => {
  setStatus(null, null);
};
const lastDeployedTime = app => activeTime(app.lastDeployedTime);

const gotoPage = (pageNumber) => {
  localState.api.pageNumber = pageNumber;
  fetchData();
};
</script>

<template>
  <div>
    <h2 class="sectionTitle">
      Published Content
    </h2>
    <div
      v-if="localState.status.message"
      class="rs-field"
    >
      <EmbeddedStatusMessage
        :type="localState.status.type"
        :message="localState.status.message"
        :show-close="false"
      />
    </div>
    <div
      v-if="!localState.api.applications.length"
      class="emptyListMessage"
    >
      This user has not published any content.
    </div>
    <RSTable
      v-if="localState.api.applications.length"
      :columns="tableColumns"
      table-name="Published Content"
    >
      <RSTableRow
        v-for="app in localState.api.applications"
        :key="app.guid"
      >
        <RSTableCell
          :cell-id="`app-name-${app.guid}`"
          :has-icon="true"
          :fill="true"
          data-automation="pcl-content-name"
        >
          <RouterLink :to="{ name: 'apps', params: { guid: app.guid } }">
            <div class="content-table__content-name">
              <div>
                <i
                  :class="app.iconClass()"
                  class="rs-icon rs-icon--large"
                />
              </div>
              <div class="content-table__display-name">
                {{ app.displayName || 'Untitled' }}
              </div>
            </div>
          </RouterLink>
        </RSTableCell>
        <RSTableCell data-automation="pcl-content-type">
          {{ contentDescriptions[app.contentType()] }}
        </RSTableCell>
        <RSTableCell data-automation="pcl-content-deployed-time">
          {{ lastDeployedTime(app) }}
        </RSTableCell>
      </RSTableRow>
    </RSTable>

    <Paginator
      v-if="showPager"
      :page="localState.api.pageNumber"
      :per-page="pageSize"
      :total="localState.api.total"
      @change="gotoPage"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';

.content-table {
  &__content-title {
    display: block;
  }

  &__content-name {
    display: flex;
    align-items: center;
  }

  &__display-name {
    margin-left: 0.5em;
    white-space: normal;
    line-height: 1.2rem;
    word-wrap: break-word;
    word-break: break-word;
    flex: 1;
  }
}

.emptyListMessage {
  font-style: italic;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  color: $color-dark-grey-2;
  padding: 20px;
  background-color: $color-light-grey;
}
</style>
