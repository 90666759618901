<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import AppRoles from '@/api/dto/appRole';
import { sendEmail } from '@/api/email';
import RSButton from '@/elements/RSButton.vue';
import RSModalForm from '@/elements/RSModal.vue';
import RSRadioGroup from '@/elements/RSRadioGroup.vue';
import {
  SHOW_ERROR_MESSAGE,
  SHOW_INFO_MESSAGE,
} from '@/store/modules/messages';
import { computed, onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex';

const emit = defineEmits(['hide']);

const store = useStore();

const recipients = ref('me');

const app = computed(() => store.state.contentView.app);
const variant = computed(() => store.state.parameterization.currentVariant);
const temporalId = computed(
  () => store.state.parameterization.currentVariant.temporalId
);
const isDirtyReportOutput = computed(() => store.state.legacyParams.form.dirty);
const currentUser = computed(() => store.state.currentUser.user);
const serverSettings = computed(() => store.state.server.settings);
const variantHistoryDisplayedId = computed(
  () => store.state.contentView.renderingHistory.displayedId
);

const loaded = computed(
  () => serverSettings.value?.mailConfigured && app.value?.isRenderable
);
const listPrincipals = computed(() =>
  app.value?.groups.concat(app.value?.users));
const isCollaborator = computed(() =>
  currentUser.value?.isAppEditor(app.value));
const hasCollaborators = computed(() =>
  listPrincipals.value.find(p => AppRoles.isCollaborator(p.appRole)));
const hasViewers = computed(() =>
  listPrincipals.value.find(p => AppRoles.isViewer(p.appRole)));
const mailCollaborators = computed(
  () =>
    isCollaborator.value && hasCollaborators.value && !isDirtyReportOutput.value
);
const mailCollaboratorsTitle = computed(() => {
  if (!isCollaborator.value) {
    return 'You must be a collaborator to send email to other users';
  } else if (isDirtyReportOutput.value) {
    return 'You may only email ad-hoc report output to yourself';
  } else if (!hasCollaborators.value) {
    return 'No collaborators to email';
  }
  return '';
});
const mailViewers = computed(
  () => isCollaborator.value && hasViewers.value && !isDirtyReportOutput.value
);
const mailViewersTitle = computed(() => {
  if (!isCollaborator.value) {
    return 'You must be a collaborator to send email to other users';
  } else if (isDirtyReportOutput.value) {
    return 'You may only email ad-hoc report output to yourself';
  } else if (!hasViewers.value) {
    return 'No viewers to email';
  }
  return '';
});
const mailAllTitle = computed(() => {
  if (!isCollaborator.value) {
    return 'You must be a collaborator to send email to other users';
  } else if (isDirtyReportOutput.value) {
    return 'You may only email ad-hoc report output to yourself';
  }
  return '';
});

const radioOptions = computed(() => {
  let options = [
    {
      label: 'just me',
      value: 'me',
      disabled: false,
    },
    {
      label: 'collaborators',
      title: mailCollaboratorsTitle.value,
      value: 'collaborators',
      disabled: !mailCollaborators.value,
    },
    {
      label: 'collaborators & viewers',
      title: mailViewersTitle.value,
      value: 'collaborators_viewers',
      disabled: !mailViewers.value,
    },
  ];
  if (serverSettings.value.mailAll) {
    options = [
      ...options,
      {
        label: 'everyone on the server',
        title: mailAllTitle.value,
        value: 'all',
        disabled: !serverSettings.value.mailAll,
      },
    ];
  }
  return options;
});

onBeforeMount(async() => {
  if (!serverSettings.value.mailConfigured) {
    if (currentUser.value?.isAdmin()) {
      setErrorMessage({
        message:
          'Email delivery has not been configured. See the Admin Guide for more information.',
      });
    } else {
      setErrorMessage({
        message: `Email delivery has not been configured. Ask your ${serverSettings.value.systemDisplayName}
          administrator for assistance.`,
      });
    }
  }
});

const setErrorMessage = msg => {
  store.dispatch(SHOW_ERROR_MESSAGE, msg);
};

const setInfoMessage = msg => {
  store.dispatch(SHOW_INFO_MESSAGE, msg);
};

const submit = async() => {
  const mailOptions = {
    variantId: isDirtyReportOutput.value ? temporalId.value : variant.value.id,
    renderingId: variantHistoryDisplayedId.value,
    email: recipients.value,
  };
  await sendEmail(mailOptions);
  emit('hide');
  setInfoMessage({ message: 'Your email request has been submitted.' });
};
</script>

<template>
  <RSModalForm
    v-if="loaded"
    :active="true"
    subject="Send Report"
    data-automation="email-report-modal"
    @close="$emit('hide')"
  >
    <template #content>
      <div class="indented">
        <RSRadioGroup
          v-model="recipients"
          :title="
            app.isSite()
              ? 'Email a completion notification to:'
              : 'Email this version of the report to:'
          "
          name="sendReport"
          :options="radioOptions"
        />
      </div>
    </template>
    <template #controls>
      <div class="actions">
        <RSButton
          type="primary"
          label="Send"
          @click="submit"
        />
      </div>
    </template>
  </RSModalForm>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';

.indented {
  text-align: left;
  :deep(.rs-radio__label):has(input:disabled) {
    color: $color-dark-grey;
    cursor: default;
    input:disabled {
      box-shadow: 0 0 0 2px $color-medium-grey-2;
      cursor: default;
    }
  }
}
</style>
