<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
const props = defineProps({
  availableIntegrations: { type: Array, required: true },
  active: { type: Array, required: true },
});

const emit = defineEmits(['close', 'integrationChange']);

import { computed, onMounted, reactive } from 'vue';
import RSModal from '@/elements/RSModal.vue';
import RSTable from '@/elements/RSTable.vue';
import RSTableRow from '@/elements/RSTableRow.vue';
import RSTableCell from '@/elements/RSTableCell.vue';
import RSButton from '@/elements/RSButton.vue';
import EmptyAuthIntegrationsList from './EmptyAuthIntegrationsList.vue';
import isEmpty from 'lodash/isEmpty';

const localState = reactive({
  integrationsList: [],
  selectedIntegration: {},
});

const activeIntegrationGuid = computed(() => props.active[0]?.guid);
const hasIntegrations = computed(() => !isEmpty(props.availableIntegrations));
const isDisabled = computed(() => isEmpty(localState.selectedIntegration));
const modalTitle = computed(() => hasIntegrations.value ? 'Select an integration' : 'No integrations are configured');

const isSelected = (guid) => guid === localState.selectedIntegration.guid;

const onSelect = (guid) => {
  const newIntegrationSelection = props.availableIntegrations.find(inte => inte.guid === guid);
  localState.selectedIntegration = newIntegrationSelection;
};

const confirmSelect = () => {
  emit('integrationChange', [{
    label: localState.selectedIntegration.name,
    guid: localState.selectedIntegration.guid,
    sub: localState.selectedIntegration.description,
    template: localState.selectedIntegration.template
  }]);

  emit('close');
  localState.selectedIntegration = {};
};

onMounted(() => {
  const activeIndex = props.availableIntegrations
    .findIndex(inte => inte.guid === activeIntegrationGuid.value);

  if (activeIndex === -1) {
    localState.integrationsList = props.availableIntegrations;
  } else {
    localState.selectedIntegration = props.active[0];
    localState.integrationsList = [props.availableIntegrations[activeIndex]]
      .concat(
        props.availableIntegrations.slice(0, activeIndex),
        props.availableIntegrations.slice(activeIndex + 1)
      );
  }
});
</script>

<template>
  <RSModal
    :active="true"
    class="auth-integrations-list-dialog"
    :subject="modalTitle"
    width="medium"
    data-automation="auth-integrations-list-dialog"
    name="auth-integrations-list-dialog"
    @close="() => $emit('close')"
  >
    <template #content>
      <RSTable
        v-if="hasIntegrations"
        class="auth-integrations-table"
        data-automation="integrations-table"
        table-name="OAuth Integrations List"
      >
        <RSTableRow
          v-for="integration of localState.integrationsList"
          :key="integration.guid"
          :data-automation="`integrations-table-row-${integration.guid}`"
          class="auth-integrations-table-row"
          :class="{ selected: isSelected(integration.guid) }"
          :row-id="integration.guid"
          :row-label="integration.name"
          :clickable="true"
          :aria-selected="isSelected(integration.guid)"
          @click="onSelect"  
        >
          <RSTableCell
            class="auth-integration-info"
          >
            <img
              :src="`images/oauthintegrations/${integration.template}.png`"
              :alt="`${integration.template} integration`"
              class="auth-integration-image"
            >
            <div
              class="auth-integration-details"
              data-automation="auth-integration-details"
            >
              <p
                class="auth-integration-name"
                data-automation="auth-integration-name"
              >
                {{ integration.name }}
              </p>
              <p
                class="auth-integration-description"
                data-automation="auth-integration-description"
              >
                {{ integration.description }}
              </p>
            </div>
          </RSTableCell>
          <RSTableCell
            class="auth-integration-type-cell"
          >
            <div
              class="auth-integration-type"
              data-automation="auth-integration-type"
            >
              <p
                class="auth-integration-type-label"
              >
                Authentication type:
              </p>
              <p>
                {{ integration.authType }}
              </p>
            </div>
            <div
              v-if="isSelected(integration.guid)"
              class="auth-integration-activated"
            >
              <span
                class="auth-integration-activated-checkmark"
                role="presentation"
              />
            </div>
          </RSTableCell>
        </RSTableRow>
      </RSTable>

      <EmptyAuthIntegrationsList
        v-if="!hasIntegrations"
      />
    </template>

    <template
      v-if="hasIntegrations"
      #controls
    >
      <RSButton
        data-automation="auth-integrations-select-button"
        label="Select"
        :disabled="isDisabled"
        @click="confirmSelect"
      />
    </template>
  </RSModal>
</template>

<style lang="scss">
@import 'Styles/shared/_colors';

.auth-integrations-list-dialog {
  .rs-modal__dialog {
    max-width: 920px;
  }

  .rs-modal__title {
    font-size: 1.125rem;
    line-height: 0.75rem;

    &::before {
      content: '';
      background-image: url(/images/oauthintegrations/integration_icon.svg);
      height: 24px;
      width: 24px;
      background-size: 24px 24px;
      display: inline-block;
      margin-right: 0.5rem;
      top: 4px;
      position: relative;
    }
  }

  .rs-modal__content {
    max-height: 500px;
    overflow-y: auto;
  }
}

.auth-integrations-table-row {
  display: flex;

  &.rs-tablerow {
    border: 1px solid $color-light-grey-3;

    &:last-child:not(:only-child) {
      border-top: 0;
    }
  }

  &.selected {
    background-color: $color-options-focus-background;
    border-color: $color-posit-blue;
    border-width: 1px;

    .auth-integration-activated-checkmark {
      background-color: $color-posit-blue;
      background-size: auto 100%;
      height: 40px;
      display: block;
      mask-image: url("/images/elements/actionChecked.svg");
      mask-repeat: no-repeat;
      mask-size: cover;
      width: 40px;
    }
  }

  &:hover {
    .auth-integration-name {
      color: $color-dark-grey-3;
    }
  }
}

.auth-integration-info {
  align-items: center;
  display: flex;
  flex-basis: 55%;
}

.auth-integration-type-cell {
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-basis: 35%;
}

.auth-integration-image {
  height: 40px;
  width: 40px;
  margin-right: 1.5rem;
  display: block;
}

.auth-integration-name {
  font-weight: bold;
  text-wrap: wrap;
}

.auth-integration-description {
  color: $color-dark-grey-2;
  max-height: 2.5rem;
  position: relative;
  overflow: hidden;
  text-wrap: wrap;
}

.auth-integration-type {
  color: $color-dark-grey-2;
}

.auth-integration-type-label {
  font-weight: bold;
}
</style>
