<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed } from 'vue';

const props = defineProps({
  page: { type: Number, required: true },
  perPage: { type: Number, required: true },
  total: { type: Number, required: true },
});

const emit = defineEmits(['change']);

const itemStart = computed(() => (props.page - 1) * props.perPage + 1);
const itemEnd = computed(() => Math.min(props.page * props.perPage, props.total));
const previousEnabled = computed(() => props.total > 0 && props.page > 1);
const nextEnabled = computed(() => props.total > 0 && itemEnd.value < props.total);

const pages = computed(() => {
  const result = [];
  const count = Math.ceil(props.total / props.perPage);

  // tl;dr If there are 10 or fewer pages, show all of them. Otherwise, always
  // show the first 2 and last 2 pages, and the current page +/-2. If the
  // current page is near the beginning or end, show the first or last 7 pages.
  switch (true) {
    case count <= 10:
      for (let i = 1; i <= count; i++) {
        result.push(i);
      }
      break;
    case props.page <= 5:
      for (let i = 1; i <= 7; i++) {
        result.push(i);
      }
      result.push(-1);
      result.push(count - 1);
      result.push(count);
      break;
    case props.page >= count - 4:
      result.push(1);
      result.push(2);
      result.push(-1);
      for (let i = count - 6; i <= count; i++) {
        result.push(i);
      }
      break;
    default:
      result.push(1);
      result.push(2);
      result.push(-1);
      for (let i = props.page - 2; i <= props.page + 2; i++) {
        result.push(i);
      }
      result.push(-1);
      result.push(count - 1);
      result.push(count);
  }

  return result;
});

const isActive = n => n === props.page;
const isDisabled = n => n === -1;
const next = () => changePage(props.page + 1);
const previous = () => changePage(props.page - 1);
const changePage = n => emit('change', n);
</script>

<template>
  <div
    class="paginator__container"
    data-automation="paginator"
  >
    <div
      v-if="props.total > 0"
      data-automation="paginator__counts"
      class="paginator__counts"
    >
      <p>Showing {{ itemStart }} to {{ itemEnd }} of <span class="paginator__total">{{ total }}</span></p>
    </div>
    <div
      v-else
      data-automation="paginator__counts--no-results"
      class="paginator__counts"
    >
      <p><span class="paginator__total">0</span> results</p>
    </div>
    <div
      v-if="previousEnabled || nextEnabled"
    >
      <nav
        class="paginator__nav"
        aria-label="Pagination"
      >
        <button
          data-automation="paginator__button--previous"
          class="paginator__button paginator__button--previous"
          :disabled="!previousEnabled"
          @click="previous"
        >
          <span>←</span>
          Previous
        </button>

        <button
          v-for="n in pages"
          :key="n"
          class="paginator__button paginator__button--page"
          data-automation="paginator__button--page"
          :disabled="isDisabled(n)"
          :class="{ active: isActive(n) }"
          @click="changePage(n)"
        >
          {{ n > 0 ? n : '⋯' }}
        </button>

        <button
          data-automation="paginator__button--next"
          class="paginator__button paginator__button--next"
          :disabled="!nextEnabled"
          @click="next"
        >
          Next
          <span>→</span>
        </button>
      </nav>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.paginator__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $color-light-grey-2;
  padding: 0.75rem 1rem;
}

.paginator__total {
  font-weight: bold;
}

.paginator__nav {
  display: flex;
  align-items: center;
  justify-content: end;
}

  .paginator__nav > * {
    margin-left: 0.5rem;
  }

.paginator__button {
  display: flex;
  background-color: inherit;
  border: 1px solid $color-rounded-button-border;
  border-radius: 1rem;
  color: $color-primary;
  font-size: 0.83rem;
  padding: 0.5rem 0.83rem;
  text-decoration: none;
  transition: all 0.25s ease;
  align-items: center;
  justify-content: center;

  & > * {
    margin: 0 0.2rem;
  }

  &:hover:not([disabled], .active) {
    background: $color-light-grey-2;
    color: $color-secondary-inverse;
  }

  &[disabled] {
    color: black;
    opacity: 0.6;
  }

  &.active {
    pointer-events: none;
    background: $color-primary;
    color: white;
    font-weight: bold;
    border: 1px solid $color-primary;
  }

  &:focus-visible {
    @include control-focus;
    border-radius: 1rem;
  }

}

@media screen and (max-width: 1023px) {
  .paginator__button--page {
    display: none;
  }
}
</style>
